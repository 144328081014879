/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTimestamp: any;
  AWSURL: any;
};










export type ApiResult = {
  successful: Scalars['Boolean'];
};








export type Affiliate = {
  __typename?: 'Affiliate';
  id: Scalars['String'];
  logo_url?: Maybe<Scalars['AWSURL']>;
  name?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['String']>;
};

export type AlgoliaConfig = {
  __typename?: 'AlgoliaConfig';
  index?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type AlgoliaIndex = {
  __typename?: 'AlgoliaIndex';
  releaseIndex?: Maybe<Scalars['String']>;
  workoutIndex?: Maybe<Scalars['String']>;
};

export type AppleIapReceiptInput = {
  originalTransactionDateIOS: Scalars['String'];
  originalTransactionIdentifierIOS: Scalars['String'];
  productId: Scalars['String'];
  transactionDate: Scalars['String'];
  transactionId: Scalars['String'];
  transactionReceipt: Scalars['String'];
};

export type AppleIapReceiptResponse = {
  __typename?: 'AppleIAPReceiptResponse';
  errorMessage?: Maybe<Scalars['String']>;
  originalTransactionId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  subscriptionExpiry?: Maybe<Scalars['String']>;
  subscriptionStateCode?: Maybe<Scalars['String']>;
  subscriptionStateName?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type Asset = {
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type AssetConnection = {
  __typename?: 'AssetConnection';
  items?: Maybe<Array<Maybe<Asset>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type AssetData = Asset & {
  __typename?: 'AssetData';
  duration?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type Audio = Asset & {
  __typename?: 'Audio';
  audience?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  sourceFile?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export enum AxiiCustomerType {
  Aff = 'AFF',
  D2C = 'D2C',
  Res = 'RES'
}

export type AxiiUserInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressStateCode?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  cartAffiliateId?: Maybe<Scalars['String']>;
  cartExpiryDate?: Maybe<Scalars['Int']>;
  cartOfferHandle?: Maybe<Scalars['String']>;
  cartOfferId?: Maybe<Scalars['String']>;
  /**   Axii cart info */
  cartProductHandle?: Maybe<Scalars['String']>;
  cartTransactionId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  /**   Axii reseller */
  hasOtherActiveSub?: Maybe<Scalars['Boolean']>;
  subComms?: Maybe<Scalars['Boolean']>;
  subCommsConfirmed?: Maybe<Scalars['Boolean']>;
};

export type BillingAddressInput = {
  addressCountry: Scalars['String'];
  addressLocality: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressRegion: Scalars['String'];
  addressStateCode?: Maybe<Scalars['String']>;
  addressStreet: Scalars['String'];
  addressStreet2?: Maybe<Scalars['String']>;
};

export type BraintreeToken = {
  __typename?: 'BraintreeToken';
  clientToken?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  URL?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CancelResellerSubscriptionResponse = {
  __typename?: 'CancelResellerSubscriptionResponse';
  succeed: Scalars['Boolean'];
};

export type CaptchaSignatureInput = {
  auth: Scalars['String'];
  nonce: Scalars['String'];
};

/**   Lmod Challenge Engagement */
export type ChallengeEngagement = {
  __typename?: 'ChallengeEngagement';
  acceptedAt: Scalars['AWSTimestamp'];
  challengeId: Scalars['String'];
  leftAt?: Maybe<Scalars['AWSTimestamp']>;
  startDate: Scalars['AWSTimestamp'];
  switchedFrom?: Maybe<Scalars['AWSTimestamp']>;
};

export type ChallengeEngagements = {
  __typename?: 'ChallengeEngagements';
  activeChallengeEngagement?: Maybe<ChallengeEngagement>;
  areChallengeEmailsSubscribed?: Maybe<Scalars['Boolean']>;
  pastChallengeEngagements?: Maybe<Array<Maybe<ChallengeEngagement>>>;
};

export type Chapter = {
  __typename?: 'Chapter';
  chapterNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Int']>;
  track?: Maybe<Track>;
  trackName?: Maybe<Scalars['String']>;
};

export type ChargifyPaymentProfile = {
  __typename?: 'ChargifyPaymentProfile';
  cardType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ChargifyToken = {
  __typename?: 'ChargifyToken';
  publicKey?: Maybe<Scalars['String']>;
  securityToken?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
};

export type CheckCustomerFlagged = {
  __typename?: 'CheckCustomerFlagged';
  status: Scalars['Boolean'];
};

export type CheckCustomerFlaggedResponse = CheckCustomerFlagged | DeleteCustomerError;

export type CognitoUserAttribute = {
  Automation?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Value: Scalars['String'];
};

/**  # ContentManifest */
export type ContentManifest = {
  __typename?: 'ContentManifest';
  contentManifestId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  liveReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
  packagedPrograms?: Maybe<ProgramConnection>;
  state?: Maybe<Scalars['String']>;
  targetReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
};


/**  # ContentManifest */
export type ContentManifestPackagedProgramsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CreateAnonSurveyQuestionResponseInput = {
  /**
   *  unique identifier of the survey
   * id of the question, it enables to overwrite an answer if a user answers the same question again
   */
  questionId: Scalars['String'];
  selectedAnswers?: Maybe<Array<Maybe<SurveyAnswerAttributeInput>>>;
  /**   these will be removed as soon as FE implements the new contract */
  surveyId?: Maybe<Scalars['String']>;
  surveyVersion?: Maybe<Scalars['String']>;
};

export type CreateAudioInput = {
  audience?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  language: Scalars['String'];
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider: Scalars['String'];
  sourceFile?: Maybe<Scalars['String']>;
  unsigned_url: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type CreateChapterInput = {
  chapterNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  startTime: Scalars['Int'];
  trackName: Scalars['String'];
};

export type CreateManifestInput = {
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider: Scalars['String'];
  unsigned_url: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type CreateMusicInput = {
  musicCoverArtist?: Maybe<Scalars['String']>;
  musicCoverType?: Maybe<EnumMusicCoverType>;
  musicOriginalArtist?: Maybe<Scalars['String']>;
  musicPnote?: Maybe<Scalars['String']>;
  musicTitle?: Maybe<Scalars['String']>;
  musicWrittenBy?: Maybe<Scalars['String']>;
};

export type CreatePlanDetailsInput = {
  completed?: Maybe<Scalars['Boolean']>;
  finishDate?: Maybe<Scalars['AWSTimestamp']>;
  name?: Maybe<Scalars['String']>;
  schedule: Array<Maybe<CreatePlanWorkoutsInput>>;
  startDate?: Maybe<Scalars['AWSTimestamp']>;
  version?: Maybe<Scalars['Int']>;
};

export type CreatePlanInput = {
  benefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  description?: Maybe<Scalars['String']>;
  durationsContent?: Maybe<Scalars['AWSJSON']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  equipmentContent?: Maybe<Scalars['AWSJSON']>;
  fitLevelContent?: Maybe<Scalars['AWSJSON']>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['AWSURL']>;
  length?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  planDetails: CreatePlanDetailsInput;
  programsContent?: Maybe<Scalars['AWSJSON']>;
  totalWorkouts?: Maybe<Scalars['Int']>;
  version: Scalars['Int'];
};

export type CreatePlanWorkoutsInput = {
  alternateName?: Maybe<Scalars['String']>;
  day: Scalars['Int'];
  program: Scalars['String'];
  scheduled?: Maybe<Scalars['String']>;
  vimeoID: Scalars['String'];
  vimeoSessionID?: Maybe<Scalars['String']>;
  workoutName: Scalars['String'];
};

export type CreateProgramInput = {
  alternateName?: Maybe<Scalars['String']>;
  areaRequired?: Maybe<Scalars['String']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  calories: Scalars['Int'];
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  colour?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoAvail?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoUnavail?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['AWSURL']>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  intensity?: Maybe<Scalars['Int']>;
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  product?: Maybe<Array<Maybe<EnumProduct>>>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDesc?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreatePromotionInput = {
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type CreateReleaseInput = {
  asset_gif?: Maybe<CreatePromotionInput>;
  asset_trailer?: Maybe<CreatePromotionInput>;
  description?: Maybe<Scalars['String']>;
  filmingDate?: Maybe<Scalars['String']>;
  filmingLocation?: Maybe<Scalars['String']>;
  programName: Scalars['String'];
  releaseNumber: Scalars['Int'];
  unsigned_gif?: Maybe<Scalars['String']>;
  unsigned_trailer?: Maybe<Scalars['String']>;
};

export type CreateResellerResponse = ErrorResponse | ResellerResponse;

export type CreateResponse = ErrorResponse | Form;

export type CreateSubtitleInput = {
  audience?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workoutName?: Maybe<Scalars['String']>;
};

export type CreateSurveyQuestionResponseInput = {
  /**
   *  unique identifier of the survey
   * id of the question, it enables to overwrite an answer if a user answers the same question again
   */
  questionId: Scalars['String'];
  selectedAnswers?: Maybe<Array<Maybe<SurveyAnswerAttributeInput>>>;
  /**   these will be removed as soon as FE implements the new contract */
  surveyId?: Maybe<Scalars['String']>;
  surveyVersion?: Maybe<Scalars['String']>;
};

export type CreateTrackInput = {
  calories?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  impactLevel?: Maybe<Scalars['String']>;
  keyMovements?: Maybe<Array<Maybe<Scalars['String']>>>;
  music?: Maybe<Array<Maybe<CreateMusicInput>>>;
  name?: Maybe<Scalars['String']>;
  originalProgramName?: Maybe<Scalars['String']>;
  originalReleaseNumber?: Maybe<Scalars['Int']>;
  presenterName?: Maybe<Array<Maybe<Scalars['String']>>>;
  programName?: Maybe<Scalars['String']>;
  releaseNumber?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Int']>;
  targetMuscle?: Maybe<Array<Maybe<Scalars['String']>>>;
  trackIntensity?: Maybe<Scalars['Int']>;
  trackNumber: Scalars['String'];
  trackType?: Maybe<Scalars['String']>;
  workoutName: Scalars['String'];
};

export type CreateUserInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['AWSDate']>;
  categoryFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubId?: Maybe<Scalars['String']>;
  clubMemberId?: Maybe<Scalars['String']>;
  currentPlan?: Maybe<CreatePlanDetailsInput>;
  email?: Maybe<Scalars['AWSEmail']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  familyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  lmodEntitlement?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  planHistory?: Maybe<Array<Maybe<CreatePlanDetailsInput>>>;
  programFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  skipPersonal?: Maybe<Scalars['Boolean']>;
  tutorials?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<EnumUnitType>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
  userId: Scalars['String'];
  usualWorkoutDuration?: Maybe<Scalars['Int']>;
  vimeoUserId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  workoutFavorites?: Maybe<Array<Maybe<WorkoutFavoritesInput>>>;
};

export type CreateVideoInput = {
  bitrate?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  framerate?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider: Scalars['String'];
  sourceFile?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type CreateWorkoutFeedbackInput = {
  customerExperience?: Maybe<Scalars['Int']>;
  customerFeedback?: Maybe<Scalars['Boolean']>;
  customerFeedbackNote?: Maybe<Scalars['String']>;
  customerFeeling?: Maybe<Scalars['Int']>;
  vimeoSessionId: Scalars['String'];
  vimeoVideoId: Scalars['String'];
};

export type CreateWorkoutInput = {
  areaRequired?: Maybe<Scalars['String']>;
  chapters?: Maybe<Array<Maybe<CreateChapterInput>>>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  gif?: Maybe<Scalars['String']>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  instructorTeam?: Maybe<Array<Maybe<Scalars['String']>>>;
  product: Scalars['String'];
  programName: Scalars['String'];
  publishDate: Scalars['AWSTimestamp'];
  releaseNumber: Scalars['Int'];
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  subCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalViews?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type CustomerioApiStatus = {
  __typename?: 'CustomerioAPIStatus';
  statusCode?: Maybe<Scalars['String']>;
};

export type DeleteCustomer = {
  __typename?: 'DeleteCustomer';
  success: Scalars['Boolean'];
};

export type DeleteCustomerError = {
  __typename?: 'DeleteCustomerError';
  description: Scalars['String'];
};

/**
 *  End schema for LMM Configuration
 * Start schema for delete customer
 */
export type DeleteCustomerResponse = DeleteCustomer | DeleteCustomerError;

export type DeleteResponse = ErrorResponse | Form;

export type EnableMagentoCustomerError = ErrorInterface & {
  __typename?: 'EnableMagentoCustomerError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type EnableMagentoCustomerResponse = EnableMagentoCustomerError | EnableMagentoCustomerResult;

export type EnableMagentoCustomerResult = ApiResult & {
  __typename?: 'EnableMagentoCustomerResult';
  successful: Scalars['Boolean'];
};

export enum EnumCustomerType {
  Apartment = 'apartment',
  Consumer = 'consumer',
  Corporate = 'corporate',
  Gym = 'gym',
  Hotel = 'hotel'
}

export enum EnumFeedbackToolStatus {
  Answered = 'answered',
  Showed = 'showed'
}

export enum EnumMobilePlatform {
  Android = 'Android',
  IOs = 'iOS'
}

export enum EnumModeForm {
  E = 'E',
  N = 'N'
}

export enum EnumMusicCoverType {
  Customised = 'customised',
  Lmo = 'lmo',
  LmoGd = 'lmo_gd',
  Original = 'original'
}

export enum EnumProduct {
  Bike = 'Bike',
  Lmod = 'LMOD',
  Releases = 'Releases',
  Virtual = 'Virtual'
}

export enum EnumState {
  Draft = 'Draft',
  Live = 'Live',
  Retired = 'Retired',
  Test = 'Test'
}

export enum EnumSubscriptionStatus {
  Active = 'active',
  Expired = 'expired',
  Inactive = 'inactive',
  Suspended = 'suspended'
}

export enum EnumUnitType {
  Imperial = 'Imperial',
  Metric = 'Metric'
}

export type EquipmentConsole = {
  __typename?: 'EquipmentConsole';
  activeSubscription?: Maybe<EquipmentSubscription>;
  appBuildType?: Maybe<Scalars['String']>;
  appVersionCode?: Maybe<Scalars['String']>;
  appVersionName?: Maybe<Scalars['String']>;
  consoleId?: Maybe<Scalars['String']>;
  consoleManufacturer?: Maybe<Scalars['String']>;
  consoleModel?: Maybe<Scalars['String']>;
  contentLastUpdated?: Maybe<Scalars['String']>;
  contentManifestId?: Maybe<Scalars['String']>;
  defaultAudioLanguage?: Maybe<Scalars['String']>;
  equipmentGroup?: Maybe<EquipmentGroup>;
  error?: Maybe<Error>;
  friendlyName?: Maybe<Scalars['String']>;
  lastCheckIn?: Maybe<Scalars['AWSTimestamp']>;
  osVersion?: Maybe<Scalars['String']>;
  pendingContent?: Maybe<ContentManifest>;
  subscriptions?: Maybe<EquipmentSubscriptionConnection>;
  uiLanguage?: Maybe<Scalars['String']>;
};


export type EquipmentConsoleSubscriptionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type EquipmentGroup = {
  __typename?: 'EquipmentGroup';
  assignedContentManifest?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EquipmentSubscription = {
  __typename?: 'EquipmentSubscription';
  activationDate?: Maybe<Scalars['AWSTimestamp']>;
  console?: Maybe<EquipmentConsole>;
  consoleId?: Maybe<Scalars['String']>;
  customer?: Maybe<ExternalCustomer>;
  customerId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  expiryDate?: Maybe<Scalars['AWSTimestamp']>;
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<Scalars['String']>;
  status?: Maybe<EnumSubscriptionStatus>;
  term?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type EquipmentSubscriptionConnection = {
  __typename?: 'EquipmentSubscriptionConnection';
  items?: Maybe<Array<Maybe<EquipmentSubscription>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Error = {
  __typename?: 'Error';
  helpText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ErrorInterface = {
  message: Scalars['String'];
  name: Scalars['String'];
};

export type ErrorResponse = ErrorInterface & {
  __typename?: 'ErrorResponse';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type ExternalCustomer = {
  __typename?: 'ExternalCustomer';
  addressPhysicalCity?: Maybe<Scalars['String']>;
  addressPhysicalCountry?: Maybe<Scalars['String']>;
  addressPhysicalPostalCode?: Maybe<Scalars['String']>;
  addressPhysicalState?: Maybe<Scalars['String']>;
  addressPhysicalStreet?: Maybe<Scalars['String']>;
  chainName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  equipmentGroupName?: Maybe<Scalars['String']>;
  equipmentSubscriptions?: Maybe<EquipmentSubscriptionConnection>;
  lmiClubId?: Maybe<Scalars['String']>;
  lmiUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  reporting?: Maybe<Scalars['String']>;
  subscriptionsCount?: Maybe<Scalars['Int']>;
  supportContact?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['AWSEmail']>;
  supportPhone?: Maybe<Scalars['AWSPhone']>;
  type?: Maybe<EnumCustomerType>;
};


export type ExternalCustomerEquipmentSubscriptionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ExternalCustomerConnection = {
  __typename?: 'ExternalCustomerConnection';
  items?: Maybe<Array<Maybe<ExternalCustomer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Field = {
  __typename?: 'Field';
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  name: Scalars['String'];
};

export type FieldInput = {
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<ItemInput>>;
  name: Scalars['String'];
};

/**   Update schema for LMM Configuration */
export type Form = {
  __typename?: 'Form';
  current?: Maybe<Field>;
  data?: Maybe<Array<Maybe<Field>>>;
  formParameter?: Maybe<FormParameter>;
  mode: EnumModeForm;
  partitionKey: Field;
  sortKey?: Maybe<Field>;
};

export type FormInput = {
  current?: Maybe<FieldInput>;
  data?: Maybe<Array<Maybe<FieldInput>>>;
  formParameter?: Maybe<FormParameterInput>;
  mode?: Maybe<EnumModeForm>;
  partitionKey?: Maybe<FieldInput>;
  sortKey?: Maybe<FieldInput>;
};

/**   Start schema for LMM Configuration */
export type FormParameter = {
  __typename?: 'FormParameter';
  id: Scalars['String'];
  long_description?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  short_description: Scalars['String'];
  title: Scalars['String'];
};

export type FormParameterInput = {
  id?: Maybe<Scalars['String']>;
  long_description?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RoleInput>>>;
  short_description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FormResponse = ErrorResponse | Form;

export type GeneralResponse = {
  __typename?: 'GeneralResponse';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
};

export type GenerateActivationCodesInput = {
  activationUrl?: Maybe<Scalars['AWSURL']>;
  expiryInDays?: Maybe<Scalars['Int']>;
  partnerId: Scalars['String'];
  productHandle: Scalars['String'];
  total: Scalars['Int'];
};

export type GenerateActivationCodesResponse = {
  __typename?: 'GenerateActivationCodesResponse';
  activationCodesGenerated: Scalars['String'];
  s3?: Maybe<GenerateActivationCodesS3Response>;
  status: Scalars['String'];
};

export type GenerateActivationCodesS3Response = {
  __typename?: 'GenerateActivationCodesS3Response';
  bucket: Scalars['String'];
  fileContent: Scalars['String'];
  filePath: Scalars['String'];
};

export type GetResellerResponse = ErrorResponse | ResellerResponse;

export type Headers = {
  __typename?: 'Headers';
  countryCode?: Maybe<Scalars['String']>;
};

export type IapProductToTierMappingResponse = {
  __typename?: 'IapProductToTierMappingResponse';
  productId: Scalars['ID'];
  tierId: Scalars['String'];
};

export type IapUserSignupInput = {
  addressPostalCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  password: Scalars['String'];
  subComms?: Maybe<Scalars['Boolean']>;
};

export type ImageHandler = {
  __typename?: 'ImageHandler';
  url?: Maybe<Scalars['String']>;
};

export type InputConsoleRegister = {
  appBuildType?: Maybe<Scalars['String']>;
  appVersionCode?: Maybe<Scalars['String']>;
  appVersionName?: Maybe<Scalars['String']>;
  consoleId: Scalars['String'];
  consoleManufacturer?: Maybe<Scalars['String']>;
  consoleModel?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  friendlyName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  product: Scalars['String'];
};

export type InputConsoleUpdate = {
  appBuildType?: Maybe<Scalars['String']>;
  appVersionCode?: Maybe<Scalars['String']>;
  appVersionName?: Maybe<Scalars['String']>;
  consoleId: Scalars['String'];
  defaultAudioLanguage?: Maybe<Scalars['String']>;
  equipmentGroupName?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  uiLanguage?: Maybe<Scalars['String']>;
};

export type InputContentManifestCreate = {
  contentManifestId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  liveReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
  manifestContent: Array<Maybe<InputWorkoutTypeCreate>>;
  state: EnumState;
  targetReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
};

export type InputContentManifestUpdate = {
  contentManifestId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  liveReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
  manifestContent?: Maybe<Array<Maybe<InputWorkoutTypeCreate>>>;
  state?: Maybe<EnumState>;
  targetReleaseDate?: Maybe<Scalars['AWSTimestamp']>;
};

export type InputEquipmentGroupCreate = {
  assignedContentManifest: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
};

export type InputEquipmentGroupUpdate = {
  assignedContentManifest?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type InputEquipmentSubscriptionAdd = {
  customerId: Scalars['String'];
  product: Scalars['String'];
  term: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

export type InputEquipmentSubscriptionUpdate = {
  consoleId?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['AWSTimestamp']>;
  id: Scalars['ID'];
  status?: Maybe<EnumSubscriptionStatus>;
  term?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type InputExternalCustomerCreate = {
  addressPhysicalCity?: Maybe<Scalars['String']>;
  addressPhysicalCountry?: Maybe<Scalars['String']>;
  addressPhysicalPostalCode?: Maybe<Scalars['String']>;
  addressPhysicalState?: Maybe<Scalars['String']>;
  addressPhysicalStreet?: Maybe<Scalars['String']>;
  chainName?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  equipmentGroupName?: Maybe<Scalars['String']>;
  lmiClubId?: Maybe<Scalars['String']>;
  lmiUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  partner: Scalars['String'];
  reporting?: Maybe<Scalars['String']>;
  supportContact?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['AWSEmail']>;
  supportPhone?: Maybe<Scalars['AWSPhone']>;
  type: EnumCustomerType;
};

export type InputExternalCustomerUpdate = {
  addressPhysicalCity?: Maybe<Scalars['String']>;
  addressPhysicalCountry?: Maybe<Scalars['String']>;
  addressPhysicalPostalCode?: Maybe<Scalars['String']>;
  addressPhysicalState?: Maybe<Scalars['String']>;
  addressPhysicalStreet?: Maybe<Scalars['String']>;
  chainName?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  equipmentGroupName?: Maybe<Scalars['String']>;
  lmiClubId?: Maybe<Scalars['String']>;
  lmiUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  reporting?: Maybe<Scalars['String']>;
  supportContact?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['AWSEmail']>;
  supportPhone?: Maybe<Scalars['AWSPhone']>;
  type?: Maybe<EnumCustomerType>;
};

export type InputWorkoutTypeCreate = {
  product: Scalars['String'];
  programName: Scalars['String'];
  releaseNumber: Scalars['Int'];
  workoutName: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  currency?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  discount_amount?: Maybe<Scalars['String']>;
  due_amount?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  issue_date?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  paid_amount?: Maybe<Scalars['String']>;
  paid_date?: Maybe<Scalars['String']>;
  period_range_end?: Maybe<Scalars['String']>;
  period_range_start?: Maybe<Scalars['String']>;
  product_family_name?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  public_url?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  subtotal_amount?: Maybe<Scalars['String']>;
  tax_amount?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type InvoicesInput = {
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type ItemInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/**   Lmod Customer */
export type LmodCustomer = {
  __typename?: 'LmodCustomer';
  active?: Maybe<Scalars['Boolean']>;
  club_name?: Maybe<Scalars['String']>;
  email: Scalars['AWSEmail'];
  end_at?: Maybe<Scalars['AWSDate']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  start_at?: Maybe<Scalars['AWSDate']>;
  sub_id: Scalars['String'];
  subscribe_to_newsletter: Scalars['Int'];
  subscription_info?: Maybe<Scalars['String']>;
  toc_agree: Scalars['Int'];
  vimeo_id?: Maybe<Scalars['String']>;
  website_id: Scalars['String'];
};

/**   Lmod Customer Subscription Information */
export type LmodCustomerSubscription = {
  __typename?: 'LmodCustomerSubscription';
  active?: Maybe<Scalars['Boolean']>;
  email: Scalars['AWSEmail'];
  end_at?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['String']>;
  subscription_info?: Maybe<Scalars['String']>;
  vimeo_id?: Maybe<Scalars['String']>;
};

/**   Lmod Newsletter Subscription */
export type LmodNewsletterSubscription = {
  __typename?: 'LmodNewsletterSubscription';
  active?: Maybe<Scalars['Boolean']>;
  email: Scalars['AWSEmail'];
  subscription_info?: Maybe<Scalars['String']>;
  vimeo_id?: Maybe<Scalars['String']>;
};

/**   Lmod Subscription Terms and Conditions */
export type LmodTermsAndConditions = {
  __typename?: 'LmodTermsAndConditions';
  content?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MagentoCustomer = {
  email: Scalars['String'];
  magentoId: Scalars['String'];
};

export type Manifest = Asset & {
  __typename?: 'Manifest';
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type MembershipInput = {
  gymLocation: Scalars['String'];
  membershipNo: Scalars['String'];
};

export type Music = {
  __typename?: 'Music';
  musicCoverArtist?: Maybe<Scalars['String']>;
  musicCoverType?: Maybe<EnumMusicCoverType>;
  musicOriginalArtist?: Maybe<Scalars['String']>;
  musicPnote?: Maybe<Scalars['String']>;
  musicTitle?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**   Lmod challenges mutations */
  acceptChallenge?: Maybe<Scalars['Boolean']>;
  /**   Lmod ritual mutations */
  acceptRitual?: Maybe<Scalars['Boolean']>;
  /**
   *   Mutation for Activate User Subscription on Lmod, create user in Cognito
   * @deprecated The API is no longer supported
   */
  activateLmodCustomerSubscription?: Maybe<LmodCustomer>;
  addEquipmentSubscription?: Maybe<EquipmentSubscription>;
  calculateRecs?: Maybe<Scalars['Boolean']>;
  cancelResellerSubscription?: Maybe<CancelResellerSubscriptionResponse>;
  cancelResellerSubscriptionForUser?: Maybe<Scalars['Boolean']>;
  cancelSubscription?: Maybe<Subscription>;
  changeSubscription?: Maybe<Subscription>;
  changeSubscriptionChargifyJs?: Maybe<Subscription>;
  /**   Apple IAP subscription mutations */
  claimAppleEntitlement?: Maybe<AppleIapReceiptResponse>;
  /**   complete Plan */
  completedPlan?: Maybe<Scalars['Boolean']>;
  confirmSignupUser?: Maybe<User>;
  copyPromotionForCountries?: Maybe<GeneralResponse>;
  createAffiliateSubscription?: Maybe<Subscription>;
  createAffiliateSubscriptionByChargifyToken?: Maybe<Subscription>;
  createAffiliateSubscriptionByChargifyTokenV2?: Maybe<Subscription>;
  /**     Create a single survey question response anonymously */
  createAnonSurveyQuestionResponse?: Maybe<SurveyQuestionResponse>;
  /**     Create a single Audio Asset */
  createAudioAsset?: Maybe<Audio>;
  /**   Content Manifest */
  createContentManifest?: Maybe<ContentManifest>;
  createEquipmentGroup?: Maybe<EquipmentGroup>;
  createExternalCustomer?: Maybe<ExternalCustomer>;
  /**   Parameter form */
  createForm?: Maybe<CreateResponse>;
  /**     Create a single Manifest Asset */
  createManifestAsset?: Maybe<Manifest>;
  /**   create Plan */
  createPlan?: Maybe<Plan>;
  createProduct?: Maybe<CreateResponse>;
  /**     Create a single Program */
  createProgram?: Maybe<Program>;
  /**   Promotion form */
  createPromotion?: Maybe<CreateResponse>;
  /**     Create a single Release */
  createRelease?: Maybe<Release>;
  /**   Reseller Admin */
  createReseller?: Maybe<CreateResellerResponse>;
  /**
   *  Axii reseller system API
   * access only with app client 'LMOD-SystemAPIAccess-AppClient'
   */
  createResellerUserAndSubscription?: Maybe<Scalars['Boolean']>;
  /**     Create a single Subtitle Asset */
  createSubtitleAsset?: Maybe<Subtitle>;
  /**     Create a single survey question response */
  createSurveyQuestionResponse?: Maybe<SurveyQuestionResponse>;
  /**     Create a single Track */
  createTrack?: Maybe<Track>;
  /**   create a user */
  createUser?: Maybe<User>;
  /**     Create a single Video Asset */
  createVideoAsset?: Maybe<Video>;
  /**     Create a single Workout */
  createWorkout?: Maybe<Workout>;
  /**   user gives feedback to a workout */
  createWorkoutFeedback?: Maybe<WorkoutHistory>;
  /**   Mutation for Deactivate User Subscription on Lmod, unlink user from Identity Provider in Cognito */
  deactivateLmodCustomerSubscription?: Maybe<LmodCustomer>;
  /**   delete customer api */
  deleteCustomer?: Maybe<DeleteCustomerResponse>;
  deleteEquipmentGroup?: Maybe<EquipmentGroup>;
  /**   delete Plan */
  deletePlan?: Maybe<Plan>;
  deleteProduct?: Maybe<DeleteResponse>;
  deleteSubscription?: Maybe<Scalars['Boolean']>;
  /**   delete a user */
  deleteUser?: Maybe<User>;
  enableMagentoCustomer: EnableMagentoCustomerResponse;
  /**   end Plan */
  endPlan?: Maybe<Scalars['Boolean']>;
  iapSignupUser?: Maybe<SignupResponse>;
  immediateCancelSubscription?: Maybe<Scalars['Boolean']>;
  /**   Create Initial Load Package */
  initialLoadPage?: Maybe<Scalars['Boolean']>;
  leaveChallenge?: Maybe<Scalars['Boolean']>;
  leaveRitual?: Maybe<Scalars['Boolean']>;
  /**   Axii subscription mutations */
  newSubscription?: Maybe<Subscription>;
  newSubscriptionByChargifyToken?: Maybe<Subscription>;
  newSubscriptionByChargifyTokenV2?: Maybe<Subscription>;
  /**  New subscription for promotion partner */
  newSubscriptionWithPromotion?: Maybe<Subscription>;
  registerConsole?: Maybe<EquipmentConsole>;
  removeContentManifest?: Maybe<ContentManifest>;
  /**   Request Join Email */
  requestJoinEmail?: Maybe<GeneralResponse>;
  restartSubscription?: Maybe<Subscription>;
  /**   revoke a device token */
  revokeDeviceToken?: Maybe<CustomerioApiStatus>;
  /**   Email handler */
  rideDataEmail?: Maybe<RideDataEmail>;
  setPassword: SetPasswordResponse;
  /**   Create user workout attribute */
  setUserWorkoutAttributes?: Maybe<UserWorkoutAttributes>;
  signupFederatedUserWithSubscription?: Maybe<SignupResellerUserResponse>;
  /**
   *  Reseller
   * Errors from this api tax_include
   * errorType: "signed_link_validation_error"
   *   errorMessage: "invalid_signature" or "unknown_access_id" or "link_expired" or "link_redeemed"
   * errorType: "signup_reseller_user_error"
   *   errorMessage: "user_existed" or "general_error"
   */
  signupResellerUserWithSubscription?: Maybe<SignupResellerUserResponse>;
  signupUser?: Maybe<BraintreeToken>;
  /**   Promotion */
  signupUserWithPromotion?: Maybe<SignedLinkPromotionValidationResponse>;
  /**   user skipped getting started series. Home page 2.0 */
  skipGettingStartedSeries?: Maybe<Scalars['Boolean']>;
  /**   start Plan */
  startPlan?: Maybe<Scalars['Boolean']>;
  /**   start Plan V2 */
  startPlanV2?: Maybe<StartPlanV2Payload>;
  /**   Tesco */
  startTescoUserSignup?: Maybe<Scalars['String']>;
  /**   Mutation for subscribing lmod customer newsletter */
  subscribeLmodNewsletter?: Maybe<LmodNewsletterSubscription>;
  switchChallenge?: Maybe<Scalars['Boolean']>;
  switchRitual?: Maybe<Scalars['Boolean']>;
  syncVimeoTicket: GeneralResponse;
  tieredChargifyProductRelationship?: Maybe<TieredProductRelationshipResponse>;
  tieredChargifyProductRelationshipDisable?: Maybe<TieredProductRelationshipResponse>;
  tieredChargifyProductRelationships?: Maybe<Array<Maybe<TieredProductRelationshipResponse>>>;
  /**   Tiering mutations */
  tieredProduct?: Maybe<TieredProductResponse>;
  tieredVimeoProductRelationship?: Maybe<TieredVimeoProductRelationshipResponse>;
  unsubscribeChallengeEmails?: Maybe<Scalars['Boolean']>;
  /**   Mutation for unsubscribing lmod customer newsletter */
  unsubscribeLmodNewsletter?: Maybe<LmodNewsletterSubscription>;
  updateAxiiUser?: Maybe<User>;
  updateConsole?: Maybe<EquipmentConsole>;
  updateContentManifest?: Maybe<ContentManifest>;
  /**   add a new device token */
  updateDeviceToken?: Maybe<CustomerioApiStatus>;
  updateEquipmentGroup?: Maybe<EquipmentGroup>;
  updateEquipmentSubscription?: Maybe<EquipmentSubscription>;
  updateExternalCustomer?: Maybe<ExternalCustomer>;
  updateForm?: Maybe<UpdateResponse>;
  /**   add more schema for LMM Configuration */
  updateFormParameter?: Maybe<FormParameter>;
  updateMagentoUserPassword?: Maybe<Scalars['Boolean']>;
  updateMembership?: Maybe<User>;
  updatePaymentChargify?: Maybe<Scalars['Boolean']>;
  updatePaymentChargifyV2?: Maybe<Scalars['Boolean']>;
  updatePaymentDetail?: Maybe<Scalars['Boolean']>;
  updateProduct?: Maybe<UpdateResponse>;
  /**     Update a Program */
  updateProgram?: Maybe<Program>;
  updatePromotion?: Maybe<UpdateResponse>;
  /**     Update a Release */
  updateRelease?: Maybe<Release>;
  updateReseller?: Maybe<UpdateResellerResponse>;
  updateSubscription?: Maybe<Subscription>;
  updateSubscriptionPreference?: Maybe<Subscription>;
  /**     Update a Track */
  updateTrack?: Maybe<Track>;
  /**   update a user */
  updateUser?: Maybe<User>;
  /**   update user workout attributes */
  updateUserWorkoutAttributes?: Maybe<UserWorkoutAttributes>;
  /**     Update a Workout */
  updateWorkout?: Maybe<Workout>;
  /**   modify favourite workout */
  updateWorkoutFavorites?: Maybe<Scalars['Boolean']>;
  updateWorkoutFavoritesFromVimeo?: Maybe<Scalars['Boolean']>;
  /**   Add and update note for activity */
  updateWorkoutHistory?: Maybe<WorkoutHistory>;
  /**   user Complete Workout / user mark workout as completed */
  userCompleteWorkout?: Maybe<Scalars['Boolean']>;
  /**   user Completed Workout */
  userFinishedWorkout?: Maybe<Scalars['Boolean']>;
  /**   user Started Workout */
  userStartedWorkout?: Maybe<Scalars['Boolean']>;
  /**   user Update Workout */
  userUpdateWorkout?: Maybe<Scalars['Boolean']>;
};


export type MutationAcceptChallengeArgs = {
  challengeId: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: Maybe<Scalars['String']>;
};


export type MutationAcceptRitualArgs = {
  id: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: Maybe<Scalars['String']>;
};


export type MutationActivateLmodCustomerSubscriptionArgs = {
  club_name: Scalars['String'];
  email: Scalars['AWSEmail'];
  end_at?: Maybe<Scalars['AWSDate']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  start_at?: Maybe<Scalars['AWSDate']>;
  sub_id: Scalars['String'];
  subscribe_to_newsletter: Scalars['Int'];
  toc_agree: Scalars['Int'];
  website_id: Scalars['String'];
};


export type MutationAddEquipmentSubscriptionArgs = {
  input: InputEquipmentSubscriptionAdd;
};


export type MutationCancelResellerSubscriptionArgs = {
  signedLink: SignedLink;
  version?: Maybe<Scalars['Int']>;
};


export type MutationCancelResellerSubscriptionForUserArgs = {
  email: Scalars['AWSEmail'];
};


export type MutationChangeSubscriptionArgs = {
  payment_method_nonce: Scalars['String'];
  product_handle: Scalars['String'];
  skip_3ds?: Maybe<Scalars['Boolean']>;
};


export type MutationChangeSubscriptionChargifyJsArgs = {
  product_handle: Scalars['String'];
};


export type MutationClaimAppleEntitlementArgs = {
  receipt?: Maybe<AppleIapReceiptInput>;
};


export type MutationConfirmSignupUserArgs = {
  cioTicket: Scalars['String'];
};


export type MutationCopyPromotionForCountriesArgs = {
  datas?: Maybe<Array<Maybe<PromotionForCountryInput>>>;
  promotionId: Scalars['String'];
};


export type MutationCreateAffiliateSubscriptionArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  chargify_offer_handle?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  payment_method_nonce: Scalars['String'];
  skip_3ds?: Maybe<Scalars['Boolean']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationCreateAffiliateSubscriptionByChargifyTokenArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  chargify_offer_handle?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationCreateAffiliateSubscriptionByChargifyTokenV2Args = {
  affiliate_id?: Maybe<Scalars['String']>;
  captcha_signature?: Maybe<CaptchaSignatureInput>;
  chargify_offer_handle?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  recaptcha_token: Scalars['String'];
  recaptcha_token_V3?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationCreateAnonSurveyQuestionResponseArgs = {
  input: CreateAnonSurveyQuestionResponseInput;
};


export type MutationCreateAudioAssetArgs = {
  input: CreateAudioInput;
};


export type MutationCreateContentManifestArgs = {
  input: InputContentManifestCreate;
};


export type MutationCreateEquipmentGroupArgs = {
  input: InputEquipmentGroupCreate;
};


export type MutationCreateExternalCustomerArgs = {
  input: InputExternalCustomerCreate;
};


export type MutationCreateFormArgs = {
  form: FormInput;
};


export type MutationCreateManifestAssetArgs = {
  input: CreateManifestInput;
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateProductArgs = {
  form: FormInput;
};


export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};


export type MutationCreatePromotionArgs = {
  form: FormInput;
};


export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};


export type MutationCreateResellerArgs = {
  input: ResellerAdminInput;
};


export type MutationCreateResellerUserAndSubscriptionArgs = {
  data?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  input: ResellerUserSignupInput;
  isFederated?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  reseller: ResellerInput;
  signedLinkId?: Maybe<Scalars['String']>;
};


export type MutationCreateSubtitleAssetArgs = {
  input: CreateSubtitleInput;
};


export type MutationCreateSurveyQuestionResponseArgs = {
  input: CreateSurveyQuestionResponseInput;
};


export type MutationCreateTrackArgs = {
  input: CreateTrackInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVideoAssetArgs = {
  input: CreateVideoInput;
};


export type MutationCreateWorkoutArgs = {
  input: CreateWorkoutInput;
};


export type MutationCreateWorkoutFeedbackArgs = {
  input: CreateWorkoutFeedbackInput;
};


export type MutationDeactivateLmodCustomerSubscriptionArgs = {
  email: Scalars['AWSEmail'];
  sub_id: Scalars['String'];
};


export type MutationDeleteCustomerArgs = {
  email: Scalars['AWSEmail'];
};


export type MutationDeleteEquipmentGroupArgs = {
  equipmentGroupName: Scalars['String'];
};


export type MutationDeletePlanArgs = {
  name: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  partitionKey: FieldInput;
  sortKey: FieldInput;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationEnableMagentoCustomerArgs = {
  customer: MagentoCustomer;
};


export type MutationIapSignupUserArgs = {
  input: IapUserSignupInput;
};


export type MutationLeaveChallengeArgs = {
  challengeId: Scalars['String'];
};


export type MutationLeaveRitualArgs = {
  id: Scalars['String'];
};


export type MutationNewSubscriptionArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  payment_method_nonce: Scalars['String'];
  product_handle: Scalars['String'];
  skip_3ds?: Maybe<Scalars['Boolean']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationNewSubscriptionByChargifyTokenArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  product_handle: Scalars['String'];
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationNewSubscriptionByChargifyTokenV2Args = {
  affiliate_id?: Maybe<Scalars['String']>;
  captcha_signature?: Maybe<CaptchaSignatureInput>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  price_point_id?: Maybe<Scalars['String']>;
  product_handle: Scalars['String'];
  recaptcha_token: Scalars['String'];
  recaptcha_token_V3?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationNewSubscriptionWithPromotionArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  captcha_signature?: Maybe<CaptchaSignatureInput>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  offer_id?: Maybe<Scalars['String']>;
  price_point_id?: Maybe<Scalars['String']>;
  product_handle: Scalars['String'];
  promotion_signed_link_id?: Maybe<Scalars['String']>;
  recaptcha_token: Scalars['String'];
  recaptcha_token_V3?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};


export type MutationRegisterConsoleArgs = {
  input: InputConsoleRegister;
};


export type MutationRemoveContentManifestArgs = {
  contentManifestId: Scalars['String'];
};


export type MutationRequestJoinEmailArgs = {
  input: RequestJoinEmailInput;
};


export type MutationRevokeDeviceTokenArgs = {
  token: Scalars['String'];
};


export type MutationRideDataEmailArgs = {
  input: RideDataEmailInput;
};


export type MutationSetPasswordArgs = {
  login: LoginInput;
};


export type MutationSetUserWorkoutAttributesArgs = {
  input: UserWorkoutAttributesInput;
};


export type MutationSignupFederatedUserWithSubscriptionArgs = {
  accessId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  input: ResellerUserSignupInput;
};


export type MutationSignupResellerUserWithSubscriptionArgs = {
  input: ResellerUserSignupInput;
  signedLink: SignedLink;
  version?: Maybe<Scalars['Int']>;
};


export type MutationSignupUserArgs = {
  input: UserSignupInput;
};


export type MutationSignupUserWithPromotionArgs = {
  input: UserSignupInput;
  signedLinkPromotion: SignedLinkPromotion;
};


export type MutationSkipGettingStartedSeriesArgs = {
  skip: Scalars['Boolean'];
};


export type MutationStartPlanArgs = {
  name: Scalars['String'];
  startDate?: Maybe<Scalars['AWSTimestamp']>;
  timeZone?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};


export type MutationStartPlanV2Args = {
  name: Scalars['String'];
  startDate?: Maybe<Scalars['AWSTimestamp']>;
  timeZone?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};


export type MutationStartTescoUserSignupArgs = {
  auth: ResellerAuth;
  input: ResellerUserSignupInput;
};


export type MutationSubscribeLmodNewsletterArgs = {
  email: Scalars['AWSEmail'];
};


export type MutationSwitchChallengeArgs = {
  newChallengeId: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: Maybe<Scalars['String']>;
};


export type MutationSwitchRitualArgs = {
  id: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: Maybe<Scalars['String']>;
};


export type MutationSyncVimeoTicketArgs = {
  email: Scalars['String'];
};


export type MutationTieredChargifyProductRelationshipArgs = {
  input: TieredChargifyProductRelationshipInput;
};


export type MutationTieredChargifyProductRelationshipDisableArgs = {
  externalEntityId: Scalars['ID'];
  tierId: Scalars['ID'];
};


export type MutationTieredChargifyProductRelationshipsArgs = {
  input: Array<Maybe<TieredChargifyProductRelationshipInput>>;
};


export type MutationTieredProductArgs = {
  input: TieredProductInput;
};


export type MutationTieredVimeoProductRelationshipArgs = {
  input: TieredVimeoProductRelationshipInput;
};


export type MutationUnsubscribeChallengeEmailsArgs = {
  unsubscribeToken: Scalars['String'];
};


export type MutationUnsubscribeLmodNewsletterArgs = {
  email: Scalars['AWSEmail'];
};


export type MutationUpdateAxiiUserArgs = {
  input: AxiiUserInput;
};


export type MutationUpdateConsoleArgs = {
  input: InputConsoleUpdate;
};


export type MutationUpdateContentManifestArgs = {
  input: InputContentManifestUpdate;
};


export type MutationUpdateDeviceTokenArgs = {
  platform: EnumMobilePlatform;
  token: Scalars['String'];
};


export type MutationUpdateEquipmentGroupArgs = {
  input: InputEquipmentGroupUpdate;
};


export type MutationUpdateEquipmentSubscriptionArgs = {
  input: InputEquipmentSubscriptionUpdate;
};


export type MutationUpdateExternalCustomerArgs = {
  input: InputExternalCustomerUpdate;
};


export type MutationUpdateFormArgs = {
  form: FormInput;
};


export type MutationUpdateFormParameterArgs = {
  input?: Maybe<UpdateFormParameterInput>;
};


export type MutationUpdateMagentoUserPasswordArgs = {
  password: Scalars['String'];
};


export type MutationUpdateMembershipArgs = {
  input: MembershipInput;
};


export type MutationUpdatePaymentChargifyArgs = {
  billing_address: BillingAddressInput;
  chargify_token: Scalars['String'];
};


export type MutationUpdatePaymentChargifyV2Args = {
  billing_address: BillingAddressInput;
  captcha_signature?: Maybe<CaptchaSignatureInput>;
  chargify_token: Scalars['String'];
  recaptcha_token: Scalars['String'];
  recaptcha_token_V3?: Maybe<Scalars['String']>;
};


export type MutationUpdatePaymentDetailArgs = {
  billing_address: BillingAddressInput;
  payment_method_nonce: Scalars['String'];
  skip_3ds?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateProductArgs = {
  form: FormInput;
};


export type MutationUpdateProgramArgs = {
  input: UpdateProgramInput;
};


export type MutationUpdatePromotionArgs = {
  form: FormInput;
};


export type MutationUpdateReleaseArgs = {
  input: UpdateReleaseInput;
};


export type MutationUpdateResellerArgs = {
  input: ResellerAdminInput;
};


export type MutationUpdateSubscriptionArgs = {
  reactive?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateSubscriptionPreferenceArgs = {
  default_to_monthly: Scalars['Boolean'];
};


export type MutationUpdateTrackArgs = {
  input: UpdateTrackInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserWorkoutAttributesArgs = {
  input: UserWorkoutAttributesInput;
};


export type MutationUpdateWorkoutArgs = {
  input: UpdateWorkoutInput;
};


export type MutationUpdateWorkoutFavoritesArgs = {
  input: WorkoutFavoritesInput;
};


export type MutationUpdateWorkoutFavoritesFromVimeoArgs = {
  input?: Maybe<WorkoutFavoritesFromVimeoInput>;
};


export type MutationUpdateWorkoutHistoryArgs = {
  input: WorkoutHistoryInput;
};


export type MutationUserCompleteWorkoutArgs = {
  planWorkoutId?: Maybe<Scalars['String']>;
  timespent?: Maybe<Scalars['Int']>;
  timestamp: Scalars['AWSTimestamp'];
  vimeoSessionId: Scalars['String'];
  vimeoVideoId: Scalars['String'];
};


export type MutationUserFinishedWorkoutArgs = {
  planWorkoutId?: Maybe<Scalars['String']>;
  timestamp: Scalars['AWSTimestamp'];
  vimeoSessionId: Scalars['String'];
  vimeoUserId?: Maybe<Scalars['String']>;
  vimeoVideoId: Scalars['String'];
};


export type MutationUserStartedWorkoutArgs = {
  planWorkoutId?: Maybe<Scalars['String']>;
  timestamp: Scalars['AWSTimestamp'];
  vimeoSessionId: Scalars['String'];
  vimeoUserId?: Maybe<Scalars['String']>;
  vimeoVideoId: Scalars['String'];
};


export type MutationUserUpdateWorkoutArgs = {
  duration: Scalars['Int'];
  planWorkoutId?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  timestamp: Scalars['AWSTimestamp'];
  vimeoSessionId: Scalars['String'];
  vimeoUserId?: Maybe<Scalars['String']>;
  vimeoVideoId: Scalars['String'];
};

export type Offer = {
  __typename?: 'Offer';
  affiliate?: Maybe<Affiliate>;
  archived_at?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  is_influencer?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  offer_handle?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['String']>;
  product_handle?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  product_price_point?: Maybe<PricePoint>;
  recurring?: Maybe<Scalars['Boolean']>;
  sort_order?: Maybe<Scalars['Int']>;
  tune_affiliate_id?: Maybe<Scalars['String']>;
  tune_goal_id?: Maybe<Scalars['String']>;
  tune_offer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

type LMOD = {
  __typename?: 'LMODEntitlement';
  vimeoUserTicket?: Maybe<Scalars['String']>;
}

export type UserEntitlements = {
  __typename?: "UserEntitlements";
  LMOD?: Maybe<LMOD>;
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  bin?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  countryOfIssuance?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerLocation?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum PaymentType {
  CreditCard = 'CreditCard',
  PayPalAccount = 'PayPalAccount'
}

export type Place = {
  __typename?: 'Place';
  description?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  benefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  description?: Maybe<Scalars['String']>;
  durationsContent?: Maybe<Scalars['AWSJSON']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  equipmentContent?: Maybe<Scalars['AWSJSON']>;
  fitLevelContent?: Maybe<Scalars['AWSJSON']>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['AWSURL']>;
  length?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planDetails?: Maybe<PlanDetails>;
  position?: Maybe<Scalars['Int']>;
  programsContent?: Maybe<Scalars['AWSJSON']>;
  totalWorkouts?: Maybe<Scalars['Int']>;
  tvImage?: Maybe<Scalars['AWSURL']>;
  version?: Maybe<Scalars['Int']>;
};

export type PlanConnection = {
  __typename?: 'PlanConnection';
  items?: Maybe<Array<Maybe<Plan>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PlanDetails = {
  __typename?: 'PlanDetails';
  completed?: Maybe<Scalars['Boolean']>;
  finishDate?: Maybe<Scalars['AWSTimestamp']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Array<Maybe<PlanWorkouts>>>;
  startDate?: Maybe<Scalars['AWSTimestamp']>;
  version?: Maybe<Scalars['Int']>;
};

export type PlanWorkouts = {
  __typename?: 'PlanWorkouts';
  alternateName?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['Int']>;
  program?: Maybe<Scalars['String']>;
  scheduled?: Maybe<Scalars['String']>;
  vimeoID?: Maybe<Scalars['String']>;
  vimeoSessionID?: Maybe<Scalars['String']>;
  workoutName: Scalars['String'];
};

export type Presenter = {
  __typename?: 'Presenter';
  /**   A square profile picture */
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PricePoint = {
  __typename?: 'PricePoint';
  currency?: Maybe<Scalars['String']>;
  final_price_in_cents?: Maybe<Scalars['Int']>;
  final_tax_in_cents?: Maybe<Scalars['Int']>;
  initial_charge_after_trial?: Maybe<Scalars['Boolean']>;
  initial_charge_in_cents?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  interval_unit?: Maybe<Scalars['String']>;
  price_in_cents?: Maybe<Scalars['Int']>;
  price_point_id?: Maybe<Scalars['Int']>;
  product_handle?: Maybe<Scalars['String']>;
  tax_in_cents?: Maybe<Scalars['Int']>;
  tax_included?: Maybe<Scalars['Boolean']>;
  trial_interval?: Maybe<Scalars['Int']>;
  trial_interval_unit?: Maybe<Scalars['String']>;
  trial_price_in_cents?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  archived_at?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product_handle?: Maybe<Scalars['String']>;
  product_price_point?: Maybe<PricePoint>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customer_type?: Maybe<AxiiCustomerType>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price_point_id?: Maybe<Scalars['Int']>;
  product_handle?: Maybe<Scalars['String']>;
  product_price_point?: Maybe<PricePoint>;
  rollover_product_handle?: Maybe<Scalars['String']>;
  tier?: Maybe<TierProductRelationship>;
  updated_at?: Maybe<Scalars['String']>;
};

export type PreviewRenewal = {
  __typename?: 'PreviewRenewal';
  totalInCentsTaxInclusive: Maybe<Scalars['Int']>;
  totalInCentsTaxExclusive: Maybe<Scalars['Int']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  countryCode?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  products: Array<ProductCategory>;
};

export type ProductPaginated = {
  __typename?: 'ProductPaginated';
  countryCode?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  products: Array<Product>;
};

/**   Axii subscription types - end */
export type Program = {
  __typename?: 'Program';
  alternateName?: Maybe<Scalars['String']>;
  areaRequired?: Maybe<Scalars['String']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  calories: Scalars['Int'];
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  colour?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoAvail?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoUnavail?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['AWSURL']>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  intensity?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['String']>;
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  packagedReleases?: Maybe<ReleaseConnection>;
  product?: Maybe<Array<Maybe<EnumProduct>>>;
  promoVideoId?: Maybe<Scalars['String']>;
  /**   metadata for program's promo videos */
  promoVideoMetadata?: Maybe<PromoVideoMetadata>;
  releases?: Maybe<ReleaseConnection>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDesc?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
  tiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  tvImageWide?: Maybe<Scalars['AWSURL']>;
  workouts?: Maybe<WorkoutConnection>;
  workoutsByCountry?: Maybe<WorkoutConnection>;
};


/**   Axii subscription types - end */
export type ProgramPackagedReleasesArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/**   Axii subscription types - end */
export type ProgramReleasesArgs = {
  filter?: Maybe<TableReleaseFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


/**   Axii subscription types - end */
export type ProgramWorkoutsArgs = {
  filter?: Maybe<TableWorkoutFilterInput>;
};


/**   Axii subscription types - end */
export type ProgramWorkoutsByCountryArgs = {
  product: Scalars['String'];
  publishDate: Scalars['Int'];
};

export type ProgramConnection = {
  __typename?: 'ProgramConnection';
  items?: Maybe<Array<Maybe<Program>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PromoVideoMetadata = {
  __typename?: 'PromoVideoMetadata';
  thumbnail?: Maybe<Scalars['String']>;
  url_720p?: Maybe<Scalars['String']>;
  url_1080p?: Maybe<Scalars['String']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type PromotionDetail = {
  __typename?: 'PromotionDetail';
  currency?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurring?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  trialDays?: Maybe<Scalars['Int']>;
};

/**   Using for table price detail in promotion form 2 */
export type PromotionForCountry = {
  __typename?: 'PromotionForCountry';
  bauPriceInCents?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  entity?: Maybe<Scalars['String']>;
  promotionPriceInCents?: Maybe<Scalars['Int']>;
  taxIncluded?: Maybe<Scalars['Boolean']>;
  trialDays?: Maybe<Scalars['Int']>;
};

export type PromotionForCountryInput = {
  bauPriceInCents?: Maybe<Scalars['Int']>;
  countryCode: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  entity?: Maybe<Scalars['String']>;
  promotionPriceInCents?: Maybe<Scalars['Int']>;
  taxIncluded?: Maybe<Scalars['Boolean']>;
  trialDays?: Maybe<Scalars['Int']>;
};

export type PromotionForCountryList = {
  __typename?: 'PromotionForCountryList';
  datas?: Maybe<Array<Maybe<PromotionForCountry>>>;
};

export type PromotionForCountryResponse = ErrorResponse | PromotionForCountryList;

export type PromotionPricingPlan = {
  __typename?: 'PromotionPricingPlan';
  brand?: Maybe<Brand>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  entity?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  finalPrice?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  interval: Scalars['Int'];
  intervalUnit: Scalars['String'];
  isRecurring?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offerHandle?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  pricePointId?: Maybe<Scalars['String']>;
  productHandle?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  tier?: Maybe<TierProductRelationship>;
  trialDays?: Maybe<Scalars['Int']>;
  trialPrice?: Maybe<Scalars['Int']>;
  voucher?: Maybe<Voucher>;
};

export type PublicApiKeyResponse = {
  __typename?: 'PublicAPIKeyResponse';
  keyName: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /**   Get Asset Data by External ID */
  assetByExternalId?: Maybe<AssetData>;
  doesUserExist?: Maybe<Scalars['Boolean']>;
  /**   Parameter form */
  form?: Maybe<FormResponse>;
  formProduct?: Maybe<FormResponse>;
  /**   Promotion form */
  formPromotion?: Maybe<FormResponse>;
  generateActivationCodes?: Maybe<GenerateActivationCodesResponse>;
  getAffiliateOfferByOfferId?: Maybe<Offer>;
  getAffiliateOffers?: Maybe<Array<Maybe<Offer>>>;
  /**
   * deprecated for LM Plus
   * Query to get Algolia Configuration for LM Plus apps
   */
  getAlgoliaConfig?: Maybe<AlgoliaConfig>;
  /**  deprecated for LM Plus */
  getAlgoliaIndex?: Maybe<AlgoliaIndex>;
  getChargifySecurityToken?: Maybe<ChargifyToken>;
  getClientToken?: Maybe<BraintreeToken>;
  getConsole?: Maybe<EquipmentConsole>;
  getContentManifest?: Maybe<ContentManifest>;
  /**
   *  The following Algolia query are deprecated for LM Plus usage
   * Query to get the current algolia index
   */
  getCurrentAlgoliaIndex?: Maybe<Scalars['String']>;
  getExternalCustomer?: Maybe<ExternalCustomer>;
  getHeaders?: Maybe<Headers>;
  /**   IAP related queries */
  getIapProductToTierMapping?: Maybe<Array<Maybe<IapProductToTierMappingResponse>>>;
  getInvoices?: Maybe<Array<Maybe<Invoice>>>;
  getLmnzOffer?: Maybe<Offer>;
  getLmnzOfferV2?: Maybe<Array<Maybe<Offer>>>;
  /**   Query to Check Lmod Customer's Subscription */
  getLmodCustomerSubscription?: Maybe<LmodCustomerSubscription>;
  /**   Query to Check Lmod Newsletter Subscription */
  getLmodNewsletterSubscription?: Maybe<LmodNewsletterSubscription>;
  /**   Query to Get Lmod Terms and Conditions */
  getLmodTermsAndConditions?: Maybe<LmodTermsAndConditions>;
  getLocation?: Maybe<Location>;
  getOfferByHandle?: Maybe<Offer>;
  getOfferByHandleV2?: Maybe<Array<Maybe<Offer>>>;
  getPaymentDetail?: Maybe<PaymentMethod>;
  getPlaceAutocomplete?: Maybe<Array<Maybe<Place>>>;
  getPlaceDetails?: Maybe<Location>;
  /**   get Plan */
  getPlan?: Maybe<Plan>;
  /**   Get list plans */
  getPlans?: Maybe<Array<Maybe<Plan>>>;
  getProduct?: Maybe<ProductCategory>;
  getPromotionPriceDetail?: Maybe<PromotionForCountryResponse>;
  /**   Public API Key service, accessible via public endpoint e.g. https://graphql.dev.lesmills.com/public */
  getPublicAPIKey: PublicApiKeyResponse;
  /**   Query for Recommend workouts */
  getRecommendedPlans?: Maybe<Array<Maybe<PlanRecs>>>;
  /**   Query for Recommend workouts */
  getRecommendedWorkouts?: Maybe<Array<Maybe<WorkoutRecs>>>;
  /**   Query for Recommend workouts with paging */
  getRecommendedWorkoutsPagination?: Maybe<WorkoutRecsPagination>;
  /**   Refer a Friend (RaF) (LMOD_Services_ReferAFriend_Repo) */
  getReferralLink?: Maybe<Scalars['String']>;
  /**   Reseller Admin */
  getReseller?: Maybe<GetResellerResponse>;
  /**   get Ritual */
  getRitual?: Maybe<Ritual>;
  /**   Get Rituals for a User */
  getRitualEngagement?: Maybe<RitualEngagement>;
  /**   Get list Rituals */
  getRituals?: Maybe<Array<Maybe<Ritual>>>;
  /**   ActivationCode */
  getSignedUrlFromActivationCode?: Maybe<Scalars['String']>;
  /**   query used by user to get their details */
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<UserResponse>;
  /**   query used by the backend(s) to query any user details using user id (cognito) */
  getUserById?: Maybe<User>;
  /**   query used by the backend(s) to query any user details using user id in vimeo */
  getUserByVimeoUserId?: Maybe<User>;
  /**   Get workout note */
  getUserWorkoutAttributes?: Maybe<UserWorkoutAttributes>;
  getUserWorkoutHistoryByVimeoId?: Maybe<WorkoutHistoryConnection>;
  getValidProducts: ProductConnection;
  getValidProductsAllRegions: ProductConnection;
  /**   Vimeo Wrapper: Query to get vimeo collection */
  getVimeoCollection?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to get vimeo collection items */
  getVimeoCollectionItems?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to get vimeo product */
  getVimeoProduct?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to get vimeo user */
  getVimeoUser?: Maybe<Scalars['AWSJSON']>;
  /**  Query to get vimeo access token for existing user */
  getVimeoUserToken?: Maybe<VimeoUserToken>;
  /**   Vimeo Wrapper: Query to get vimeo files */
  getVimeoVideoFiles?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to get vimeo product */
  getVimeoVideoMetadata?: Maybe<VimeoVideoMetadata>;
  /**   Vimeo Wrapper: Query to get vimeo video url */
  getVimeoVideoUrl?: Maybe<Scalars['AWSJSON']>;
  /**   get WO by ID */
  getWorkouts?: Maybe<Array<Maybe<WorkoutHistory>>>;
   /**  Axii config queries */
  getRolloverCountries: RolloverCountries;
  /**   Delete customer */
  isCustomerFlagged?: Maybe<CheckCustomerFlaggedResponse>;
  /**   Vimeo Wrapper: Query to get vimeo list browse items */
  listBrowseItems?: Maybe<Scalars['AWSJSON']>;
  listEquipmentSubscriptions?: Maybe<EquipmentSubscriptionConnection>;
  listExternalCustomers?: Maybe<ExternalCustomerConnection>;
  /**   Add more schema for LMM Configuration */
  listFormNames?: Maybe<Array<Maybe<FormParameter>>>;
  /**   list all Plans */
  listPlans?: Maybe<PlanConnection>;
  /**   Axii subscription queries */
  listProductsByViewerCountry: ProductPaginated;
  /**     List all Programs */
  listPrograms?: Maybe<ProgramConnection>;
  /**   list all Rituals */
  listRituals?: Maybe<RitualConnection>;
  listRoles?: Maybe<Array<Maybe<Role>>>;
  /**   list all users */
  listUsers?: Maybe<UserConnection>;
  /**   Vimeo Wrapper: Query to list vimeo collections */
  listVimeoCollections?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to list vimeo products */
  listVimeoProducts?: Maybe<Scalars['AWSJSON']>;
  /**   Vimeo Wrapper: Query to list vimeo videos. */
  listVimeoVideos?: Maybe<Scalars['AWSJSON']>;
  /**   list Workout history */
  listWorkoutHistory?: Maybe<WorkoutHistoryConnection>;
  /**   Query for Personalize workouts */
  personalizeWorkout?: Maybe<Array<Maybe<Workout>>>;
  /**   Query for Personalize workouts by vimeoUserId */
  personalizeWorkoutByVimeoUserId?: Maybe<Array<Maybe<Workout>>>;
  pollSignupStatus?: Maybe<SignupStatus>;
  /**     Get a single Program by primary key. */
  program?: Maybe<Program>;
  promotionPricingPlans?: Maybe<Array<PromotionPricingPlan>>;
  queryReseller?: Maybe<QueryResellerByAccessIdResponse>;
  /**     getRecommended Programs */
  recommendedPrograms?: Maybe<Array<Maybe<Program>>>;
  /**     Get a single Release by primary key */
  release?: Maybe<Release>;
  /**     Get all releases for a particular program */
  releasesForProgram?: Maybe<Array<Maybe<Release>>>;
  searchResellerByAccessId?: Maybe<SearchResellerResponse>;
  searchResellerByName?: Maybe<SearchResellerResponse>;
  selectionItemList?: Maybe<SelectionItemListResponse>;
  selectionItemListProduct?: Maybe<SelectionItemListResponse>;
  selectionItemListPromotion?: Maybe<SelectionItemListResponse>;
  /**     Get a survey by id */
  survey?: Maybe<Survey>;
  tieredChargifyProductRelationships: Array<Maybe<TieredProductRelationshipResponse>>;
  /**   Tiering service Queries */
  tieredProduct?: Maybe<TieredProductResponse>;
  tieredProductList: Array<Maybe<TieredProductResponse>>;
  tieredVimeoProductRelationships: Array<Maybe<TieredVimeoProductRelationshipResponse>>;
  /**     Get a single Track */
  track?: Maybe<Track>;
  /**   Reseller */
  validateSignedLink?: Maybe<SignedLinkValidationResponse>;
  /**   AB2B-528 API to validate promotion signed URLs */
  validateSignedLinkPromotion?: Maybe<SignedLinkPromotionValidationResponse>;
  /**   Tesco */
  validateTescoVoucher?: Maybe<TescoVoucherType>;
  validateVimeoAccount?: Maybe<Scalars['String']>;
  verifyCaptchaSignature?: Maybe<Scalars['Boolean']>;
  verifyReCaptcha?: Maybe<ReCaptchaResponse>;
  /**   Check LMF+ version */
  versionCheck?: Maybe<VersionCheck>;
  /**     Get a single Workout by primary key */
  workout?: Maybe<Workout>;
  /**   Allow get workout by Combo workouts */
  workoutByName?: Maybe<WorkoutConnection>;
  /**   Allow get workout by Name only */
  workoutByNameOnly?: Maybe<Workout>;
  /**   Get list new workout order by publishDate */
  workoutNew?: Maybe<WorkoutConnection>;
  /**   Get list new workout for a specfic country and order by publishDate */
  workoutNewCountry?: Maybe<WorkoutConnection>;
  /**   Get list workout order by totalViews */
  workoutPopular?: Maybe<WorkoutConnection>;
  /**   Get list workout order by totalViews and user locale */
  workoutPopularCountry?: Maybe<WorkoutConnection>;
  /**     Get workouts in batch */
  workouts?: Maybe<Array<Maybe<Workout>>>;
  /**   Allow get workout by Combo workouts */
  workoutsByVimeoCollection?: Maybe<Array<Maybe<VimeoCollection>>>;
};


export type QueryAssetByExternalIdArgs = {
  externalId: Scalars['String'];
};


export type QueryDoesUserExistArgs = {
  captchaSignature?: Maybe<CaptchaSignatureInput>;
  email: Scalars['AWSEmail'];
  validationData: CognitoUserAttribute;
};


export type QueryFormArgs = {
  partitionKey: FieldInput;
  sortKey?: Maybe<FieldInput>;
};


export type QueryFormProductArgs = {
  partitionKey: FieldInput;
  sortKey: FieldInput;
};


export type QueryFormPromotionArgs = {
  partitionKey: FieldInput;
};


export type QueryGenerateActivationCodesArgs = {
  input: GenerateActivationCodesInput;
};


export type QueryGetAffiliateOfferByOfferIdArgs = {
  affiliate_id?: Maybe<Scalars['String']>;
  offer_id: Scalars['String'];
};


export type QueryGetAffiliateOffersArgs = {
  tune_affiliate_id?: Maybe<Scalars['String']>;
  tune_offer_id: Scalars['String'];
};


export type QueryGetChargifySecurityTokenArgs = {
  countryCode?: Maybe<Scalars['String']>;
};


export type QueryGetConsoleArgs = {
  consoleId: Scalars['String'];
};


export type QueryGetContentManifestArgs = {
  contentManifestId: Scalars['String'];
};


export type QueryGetExternalCustomerArgs = {
  customerId: Scalars['String'];
};


export type QueryGetInvoicesArgs = {
  input?: Maybe<InvoicesInput>;
};


export type QueryGetLmodCustomerSubscriptionArgs = {
  email: Scalars['AWSEmail'];
};


export type QueryGetLmodNewsletterSubscriptionArgs = {
  email: Scalars['AWSEmail'];
};


export type QueryGetLocationArgs = {
  addressStreet?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


export type QueryGetOfferByHandleArgs = {
  chargify_offer_handle: Scalars['String'];
};


export type QueryGetOfferByHandleV2Args = {
  chargify_offer_handle: Scalars['String'];
};


export type QueryGetPlaceAutocompleteArgs = {
  components: Scalars['String'];
  input: Scalars['String'];
  language?: Maybe<Scalars['String']>;
};


export type QueryGetPlaceDetailsArgs = {
  language?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
};


export type QueryGetPlanArgs = {
  name: Scalars['String'];
  version: Scalars['Int'];
};


export type QueryGetPlansArgs = {
  names: Array<Maybe<Scalars['String']>>;
  versions: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetProductArgs = {
  country_code?: Maybe<Scalars['String']>;
  product_handle: Scalars['String'];
};


export type QueryGetPromotionPriceDetailArgs = {
  countryCodes?: Maybe<Array<Scalars['String']>>;
  promotionId: Scalars['String'];
};


export type QueryGetPublicApiKeyArgs = {
  keyName: Scalars['String'];
};


export type QueryGetRecommendedWorkoutsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetReferralLinkArgs = {
  campaignId: Scalars['String'];
  email: Scalars['AWSEmail'];
};


export type QueryGetResellerArgs = {
  id: Scalars['String'];
};


export type QueryGetRitualArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type QueryGetRitualEngagementArgs = {
  userId: Scalars['String'];
};


export type QueryGetRitualsArgs = {
  ids: Array<Maybe<Scalars['String']>>;
  versions: Array<Maybe<Scalars['Int']>>;
};


export type QueryGetSignedUrlFromActivationCodeArgs = {
  activationCode: Scalars['String'];
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserByVimeoUserIdArgs = {
  vimeoUserId: Scalars['String'];
};


export type QueryGetUserWorkoutAttributesArgs = {
  programName: Scalars['String'];
  workoutName: Scalars['String'];
};


export type QueryGetUserWorkoutHistoryByVimeoIdArgs = {
  filter?: Maybe<TableWorkoutHistoryFilterInput>;
  inCMDS?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  vimeoVideoId: Scalars['String'];
};


export type QueryGetValidProductsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetValidProductsAllRegionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetVimeoCollectionArgs = {
  collection: Scalars['Int'];
  locale: Scalars['String'];
  product?: Maybe<Scalars['Int']>;
};


export type QueryGetVimeoCollectionItemsArgs = {
  collection: Scalars['Int'];
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  sort?: Maybe<VimeoSort>;
};


export type QueryGetVimeoProductArgs = {
  locale: Scalars['String'];
  product: Scalars['Int'];
};


export type QueryGetVimeoUserArgs = {
  customer: Scalars['Int'];
};


export type QueryGetVimeoUserTokenArgs = {
  refresh_token?: Maybe<Scalars['String']>;
};


export type QueryGetVimeoVideoFilesArgs = {
  customer: Scalars['Int'];
  locale: Scalars['String'];
  video: Scalars['Int'];
};


export type QueryGetVimeoVideoMetadataArgs = {
  vimeoVideoId: Scalars['Int'];
};


export type QueryGetVimeoVideoUrlArgs = {
  customer: Scalars['Int'];
  locale: Scalars['String'];
  video: Scalars['Int'];
};


export type QueryGetWorkoutsArgs = {
  vimeoSessionId?: Maybe<Array<Scalars['String']>>;
};


export type QueryIsCustomerFlaggedArgs = {
  email: Scalars['AWSEmail'];
};


export type QueryListBrowseItemsArgs = {
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  product: Scalars['Int'];
};


export type QueryListEquipmentSubscriptionsArgs = {
  filter?: Maybe<TableSubscriptionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListExternalCustomersArgs = {
  filter?: Maybe<TableExternalCustomerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListFormNamesArgs = {
  title?: Maybe<Scalars['String']>;
};


export type QueryListPlansArgs = {
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListProductsByViewerCountryArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListProgramsArgs = {
  filter?: Maybe<TableProgramFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRitualsArgs = {
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListRolesArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryListVimeoCollectionsArgs = {
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  product?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<VimeoSort>;
};


export type QueryListVimeoProductsArgs = {
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<VimeoSort>;
};


export type QueryListVimeoVideosArgs = {
  locale: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sort?: Maybe<VimeoSort>;
};


export type QueryListWorkoutHistoryArgs = {
  filter?: Maybe<TableWorkoutHistoryFilterInput>;
  inCMDS?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryPersonalizeWorkoutByVimeoUserIdArgs = {
  vimeoUserId: Scalars['String'];
};


export type QueryPollSignupStatusArgs = {
  requestId: Scalars['String'];
};


export type QueryProgramArgs = {
  locale?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type QueryPromotionPricingPlansArgs = {
  languageCode?: Maybe<Scalars['String']>;
  promotionCode: Scalars['String'];
};


export type QueryQueryResellerArgs = {
  accessId?: Maybe<Scalars['String']>;
};


export type QueryRecommendedProgramsArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type QueryReleaseArgs = {
  locale?: Maybe<Scalars['String']>;
  programName: Scalars['String'];
  releaseNumber?: Maybe<Scalars['Int']>;
};


export type QueryReleasesForProgramArgs = {
  programName: Scalars['String'];
};


export type QuerySearchResellerByAccessIdArgs = {
  accessId?: Maybe<Scalars['String']>;
  lastEvaluatedKey?: Maybe<Scalars['String']>;
};


export type QuerySearchResellerByNameArgs = {
  lastEvaluatedKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QuerySelectionItemListArgs = {
  form: FormInput;
};


export type QuerySelectionItemListProductArgs = {
  form: FormInput;
};


export type QuerySelectionItemListPromotionArgs = {
  form: FormInput;
};


export type QuerySurveyArgs = {
  id: Scalars['String'];
};


export type QueryTieredProductArgs = {
  tierId: Scalars['ID'];
};


export type QueryTrackArgs = {
  programName?: Maybe<Scalars['String']>;
  trackNumber: Scalars['String'];
  workoutName: Scalars['String'];
};


export type QueryValidateSignedLinkArgs = {
  signedLink: SignedLink;
  version?: Maybe<Scalars['Int']>;
};


export type QueryValidateSignedLinkPromotionArgs = {
  signedLinkPromotion: SignedLinkPromotion;
};


export type QueryValidateTescoVoucherArgs = {
  reCaptcha: ReCaptchaInput;
  voucherCode: Scalars['String'];
};


export type QueryVerifyCaptchaSignatureArgs = {
  input: CaptchaSignatureInput;
};


export type QueryVerifyReCaptchaArgs = {
  recaptchaToken: Scalars['String'];
};


export type QueryVersionCheckArgs = {
  platform: EnumMobilePlatform;
  version: Scalars['String'];
};


export type QueryWorkoutArgs = {
  locale?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  program: Scalars['String'];
};


export type QueryWorkoutByNameArgs = {
  product?: Maybe<Scalars['String']>;
  workoutName: Scalars['String'];
};


export type QueryWorkoutByNameOnlyArgs = {
  workoutName?: Maybe<Scalars['String']>;
};


export type QueryWorkoutNewArgs = {
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  product: Scalars['String'];
};


export type QueryWorkoutNewCountryArgs = {
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  product: Scalars['String'];
};


export type QueryWorkoutPopularArgs = {
  filter?: Maybe<TableWorkoutFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  product: Scalars['String'];
};


export type QueryWorkoutPopularCountryArgs = {
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
  product: Scalars['String'];
};


export type QueryWorkoutsArgs = {
  locale?: Maybe<Scalars['String']>;
  names: Array<Maybe<Scalars['String']>>;
  programs: Array<Maybe<Scalars['String']>>;
};

export type QueryResellerByAccessIdResponse = ErrorResponse | ResellerAdminList;

export type ReCaptchaResponse = {
  __typename?: 'ReCaptchaResponse';
  score?: Maybe<Scalars['Float']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Release = {
  __typename?: 'Release';
  asset_gif?: Maybe<Promotion>;
  asset_image_url?: Maybe<Scalars['String']>;
  asset_trailer?: Maybe<Promotion>;
  description?: Maybe<Scalars['String']>;
  filmingDate?: Maybe<Scalars['String']>;
  filmingLocation?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  /**
   *  unsigned_trailer: String
   * unsigned_gif: String
   */
  packagedWorkouts?: Maybe<WorkoutConnection>;
  /**   trailer: String */
  product?: Maybe<Array<Maybe<EnumProduct>>>;
  /**   gif: String */
  program?: Maybe<Program>;
  releaseName?: Maybe<Scalars['String']>;
  releaseNumber?: Maybe<Scalars['Int']>;
  tiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  workouts?: Maybe<WorkoutConnection>;
};


export type ReleasePackagedWorkoutsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type ReleaseWorkoutsArgs = {
  filter?: Maybe<TableReleaseFilterInput>;
};

export type ReleaseConnection = {
  __typename?: 'ReleaseConnection';
  items?: Maybe<Array<Maybe<Release>>>;
  nextToken?: Maybe<Scalars['String']>;
};

/**   Request Join Email email and accessId is required. Leaving accessId as optional for backward compatibility */
export type RequestJoinEmailInput = {
  accessId?: Maybe<Scalars['String']>;
  captchaSignature?: Maybe<CaptchaSignatureInput>;
  email: Scalars['AWSEmail'];
  validationData?: Maybe<CognitoUserAttribute>;
};

/**
 *  End schema for delete customer
 * Reseller Admin
 */
export type Reseller = {
  __typename?: 'Reseller';
  accessId: Scalars['String'];
  accumulationPricePointId?: Maybe<Scalars['String']>;
  authorizationEndpoint?: Maybe<Scalars['String']>;
  canRequestJoinEmail?: Maybe<Scalars['Boolean']>;
  ccJoinEmail?: Maybe<Scalars['String']>;
  chargifyUserId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiryDays?: Maybe<Scalars['Int']>;
  hasConfigurationCompleted?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isEmailEditable?: Maybe<Scalars['Boolean']>;
  isMigration?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /**   accumulation price */
  priceInCents?: Maybe<Scalars['Int']>;
  productFamilyId?: Maybe<Scalars['Int']>;
  productHandle?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productPriceId?: Maybe<Scalars['Int']>;
  secret?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionInterval?: Maybe<Scalars['Int']>;
  tokenEndpoint?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type ResellerAdminInput = {
  accessId: Scalars['String'];
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressStateCode?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  canRequestJoinEmail?: Maybe<Scalars['Boolean']>;
  ccJoinEmail?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailDomain?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isEmailEditable: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceInCents: Scalars['Int'];
  secret: Scalars['String'];
  subscriptionInterval: Scalars['Int'];
};

export type ResellerAdminList = {
  __typename?: 'ResellerAdminList';
  data?: Maybe<Array<Maybe<Reseller>>>;
  lastEvaluatedKey?: Maybe<Scalars['String']>;
};

export type ResellerAuth = {
  issuer: Scalars['String'];
  signedLink?: Maybe<SignedLink>;
  voucherCode?: Maybe<Scalars['String']>;
};

export type ResellerInput = {
  accessId: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  expiryDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCancellationAllow?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pricePointId?: Maybe<Scalars['ID']>;
  productHandle?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  resellerId?: Maybe<Scalars['String']>;
  signedUrlVersion?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type ResellerResponse = {
  __typename?: 'ResellerResponse';
  reseller?: Maybe<Reseller>;
};

export type ResellerUserSignupInput = {
  captchaSignature?: Maybe<CaptchaSignatureInput>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  sponsorDisplayName?: Maybe<Scalars['String']>;
  sponsorId?: Maybe<Scalars['String']>;
  sponsorLogo?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subComms?: Maybe<Scalars['Boolean']>;
  validationData?: Maybe<CognitoUserAttribute>;
  zipCode?: Maybe<Scalars['String']>;
};

export type RideDataEmail = {
  __typename?: 'RideDataEmail';
  createdAt?: Maybe<Scalars['AWSTimestamp']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['AWSEmail']>;
  hrAvg?: Maybe<Scalars['Int']>;
  hrMax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageHR?: Maybe<S3Object>;
  imagePower?: Maybe<S3Object>;
  imageRPM?: Maybe<S3Object>;
  json?: Maybe<S3Object>;
  powerAvg?: Maybe<Scalars['Int']>;
  powerMax?: Maybe<Scalars['Int']>;
  rpmAvg?: Maybe<Scalars['Int']>;
  rpmMax?: Maybe<Scalars['Int']>;
  tcx?: Maybe<S3Object>;
  workoutName?: Maybe<Scalars['String']>;
};

/**   Email handler */
export type RideDataEmailInput = {
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['AWSEmail']>;
  hrAvg?: Maybe<Scalars['Int']>;
  hrMax?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageHR?: Maybe<S3ObjectInput>;
  imagePower?: Maybe<S3ObjectInput>;
  imageRPM?: Maybe<S3ObjectInput>;
  json?: Maybe<S3ObjectInput>;
  powerAvg?: Maybe<Scalars['Int']>;
  powerMax?: Maybe<Scalars['Int']>;
  rpmAvg?: Maybe<Scalars['Int']>;
  rpmMax?: Maybe<Scalars['Int']>;
  tcx?: Maybe<S3ObjectInput>;
  workoutName?: Maybe<Scalars['String']>;
};

/**   Consolidated type which will hold plans & challenges */
export type Ritual = {
  __typename?: 'Ritual';
  createdAt: Scalars['AWSTimestamp'];
  /**
   *  A Video/workout like a movie trailer
   * preview: String!
   * coverImage:String!
   * thumbnail:String!
   */
  description?: Maybe<Scalars['String']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  /**   Length in number of weeks */
  length: Scalars['Int'];
  /**   Useful for things like Bootcamps where people sign up in advance and everyone starts at the same time */
  minStartDate?: Maybe<Scalars['AWSTimestamp']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  /**   presenters and tags will be mocked till we update the publishing pipeline */
  presenters: Array<Maybe<Presenter>>;
  ritualDetails?: Maybe<RitualDetails>;
  ritualType: RitualType;
  tags: Array<Maybe<Scalars['String']>>;
  version?: Maybe<Scalars['Int']>;
};

export type RitualConnection = {
  __typename?: 'RitualConnection';
  items?: Maybe<Array<Maybe<Ritual>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type RitualDetails = {
  __typename?: 'RitualDetails';
  completed?: Maybe<Scalars['Boolean']>;
  finishDate?: Maybe<Scalars['AWSTimestamp']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Array<Maybe<PlanWorkouts>>>;
  startDate?: Maybe<Scalars['AWSTimestamp']>;
  version?: Maybe<Scalars['Int']>;
};

export type RitualEngagement = {
  __typename?: 'RitualEngagement';
  activeRitualEngagement?: Maybe<ChallengeEngagement>;
  areRitualEmailsSubscribed?: Maybe<Scalars['Boolean']>;
  pastRitualEngagements?: Maybe<Array<Maybe<ChallengeEngagement>>>;
  userId?: Maybe<Scalars['String']>;
};

export enum RitualType {
  Bootcamp = 'BOOTCAMP',
  Challenge = 'CHALLENGE',
  Plan = 'PLAN',
  Reset = 'RESET'
}

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RoleInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String'];
  key: Scalars['String'];
  localUri?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  region: Scalars['String'];
};

export type S3ObjectInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
  localUri?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  region: Scalars['String'];
};

export type SearchResellerResponse = ErrorResponse | ResellerAdminList;

export type SelectionItemList = {
  __typename?: 'SelectionItemList';
  info?: Maybe<PromotionDetail>;
  items?: Maybe<Array<Maybe<Item>>>;
};

export type SelectionItemListResponse = ErrorResponse | SelectionItemList;

export type SetPasswordError = ErrorInterface & {
  __typename?: 'SetPasswordError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SetPasswordResponse = SetPasswordError | SetPasswordResult;

export type SetPasswordResult = {
  __typename?: 'SetPasswordResult';
  successful: Scalars['Boolean'];
};

/**   Reseller */
export type SignedLink = {
  accessId: Scalars['ID'];
  data?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiryDays?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  hostName: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  timestamp: Scalars['String'];
};

export enum SignedLinkError {
  InvalidCountry = 'INVALID_COUNTRY',
  InvalidCustomerType = 'INVALID_CUSTOMER_TYPE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidSignature = 'INVALID_SIGNATURE',
  LinkExpired = 'LINK_EXPIRED',
  LinkRedeemed = 'LINK_REDEEMED',
  UnknownAccessId = 'UNKNOWN_ACCESS_ID',
  UnknownPromotionCode = 'UNKNOWN_PROMOTION_CODE'
}

/**   Promotion Signed link */
export type SignedLinkPromotion = {
  expiryDays?: Maybe<Scalars['String']>;
  guid: Scalars['String'];
  hostName: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  promotionCode: Scalars['String'];
  signature: Scalars['String'];
  timestamp: Scalars['String'];
};

export type SignedLinkPromotionValidationResponse = {
  __typename?: 'SignedLinkPromotionValidationResponse';
  activeSubscription?: Maybe<Scalars['Boolean']>;
  error?: Maybe<SignedLinkError>;
  valid: Scalars['Boolean'];
};

export type SignedLinkValidationResponse = {
  __typename?: 'SignedLinkValidationResponse';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<SignedLinkError>;
  isEmailEditable?: Maybe<Scalars['Boolean']>;
  valid: Scalars['Boolean'];
};

export type SignupResellerUserResponse = {
  __typename?: 'SignupResellerUserResponse';
  success: Scalars['Boolean'];
};

export type SignupResponse = {
  __typename?: 'SignupResponse';
  addressCountry?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type SignupStatus = {
  __typename?: 'SignupStatus';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StartPlanV2Payload = {
  __typename?: 'StartPlanV2Payload';
  currentPlan?: Maybe<PlanDetails>;
};

/**   Axii subscription types */
export type Subscription = {
  __typename?: 'Subscription';
  activated_at?: Maybe<Scalars['String']>;
  balance_in_cents?: Maybe<Scalars['Int']>;
  cancel_at_end_of_period?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['String']>;
  cancellation_method?: Maybe<Scalars['String']>;
  preview_renewal?: Maybe<PreviewRenewal>
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  current_period_ends_at?: Maybe<Scalars['String']>;
  current_period_started_at?: Maybe<Scalars['String']>;
  default_to_monthly?: Maybe<Scalars['Boolean']>;
  delayed_cancel_at?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  next_product_handle?: Maybe<Scalars['String']>;
  next_product_id?: Maybe<Scalars['String']>;
  offer_handle?: Maybe<Scalars['String']>;
  /**   product_price_point_id: String */
  offer_id?: Maybe<Scalars['String']>;
  previous_state?: Maybe<Scalars['String']>;
  product?: Maybe<ProductCategory>;
  product_handle?: Maybe<Scalars['String']>;
  promotion_label?: Maybe<Scalars['String']>;
  reactive?: Maybe<Scalars['Boolean']>;
  receives_invoice_emails?: Maybe<Scalars['Boolean']>;
  rollover_date?: Maybe<Scalars['String']>;
  rollover_status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['Int']>;
  subscription_price_point?: Maybe<PricePoint>;
  subscription_price_point_id?: Maybe<Scalars['Int']>;
  subscription_type?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  total_price_in_cents?: Maybe<Scalars['Int']>;
  trial_ended_at?: Maybe<Scalars['String']>;
  trial_started_at?: Maybe<Scalars['String']>;
  tune_offer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type Subtitle = Asset & {
  __typename?: 'Subtitle';
  audience?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type Survey = {
  __typename?: 'Survey';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  /**   unique identifier of the survey */
  id: Scalars['String'];
  myAnswers?: Maybe<Array<Maybe<SurveyUserAnswer>>>;
  questions?: Maybe<Array<Maybe<SurveyQuestion>>>;
  /**   we use it for analytics to keep track of changes to the survey */
  surveyVersion: Scalars['String'];
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  attribute: SurveyAnswerAttribute;
  /**   text to be shown to the user */
  displayText: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type SurveyAnswerAttribute = {
  __typename?: 'SurveyAnswerAttribute';
  /**   the attribute that the answer will update */
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SurveyAnswerAttributeInput = {
  /**   the attribute that the answer will update */
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  answers?: Maybe<Array<Maybe<SurveyAnswer>>>;
  /**   text to be shown to the user */
  displayText: Scalars['String'];
  /**   id of the question, it enables to overwrite an answer if a user answers the same question again */
  questionId: Scalars['String'];
  /**   what is this question about independent of the text */
  questionIntent: Scalars['String'];
  questionType: SurveyQuestionType;
  displayLabel: Scalars['String'];
};

export type SurveyQuestionResponse = {
  __typename?: 'SurveyQuestionResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export enum SurveyQuestionType {
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  MultiSelectFlexible = 'MULTI_SELECT_FLEXIBLE'
}

export type TableBooleanFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
};

export type TableExternalCustomerFilterInput = {
  chainName?: Maybe<TableStringFilterInput>;
  customerId?: Maybe<TableStringFilterInput>;
  name?: Maybe<TableStringFilterInput>;
  partner?: Maybe<TableStringFilterInput>;
};

export type TableFloatFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  contains?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
};

export type TableIdFilterInput = {
  beginsWith?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contains?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
};

export type TableProgramFilterInput = {
  product?: Maybe<TableStringFilterInput>;
};

export type TableReleaseFilterInput = {
  product?: Maybe<TableStringFilterInput>;
};

export type TableStringFilterInput = {
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type TableSubscriptionFilterInput = {
  consoleId?: Maybe<TableStringFilterInput>;
};

export type TableWorkoutFilterInput = {
  product?: Maybe<TableStringFilterInput>;
  publishDate?: Maybe<TableIntFilterInput>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  subCategory?: Maybe<TableStringFilterInput>;
};

export type TableWorkoutHistoryFilterInput = {
  completed?: Maybe<TableBooleanFilterInput>;
  timefinished?: Maybe<TableIntFilterInput>;
  timespent?: Maybe<TableIntFilterInput>;
  timestarted?: Maybe<TableIntFilterInput>;
  workoutName?: Maybe<TableStringFilterInput>;
};

export enum TescoVoucherType {
  Invalid = 'INVALID',
  ThreeMonth = 'THREE_MONTH',
  TwelveMonth = 'TWELVE_MONTH'
}

export type TierProductRelationship = {
  __typename?: 'TierProductRelationship';
  external_entity_id?: Maybe<Scalars['String']>;
  internal_tier_id?: Maybe<Scalars['String']>;
  tier_id: Scalars['String'];
  tiered_entity_enabled: Scalars['Boolean'];
  tiered_entity_type?: Maybe<Scalars['String']>;
};

export type TieredChargifyProductRelationshipInput = {
  countryCode: Scalars['String'];
  externalEntityId: Scalars['String'];
  tierId: Scalars['String'];
  tieredEntityEnabled: Scalars['Boolean'];
  tieredEntityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

/**
 * End Reseller Admin
 * Queries Schema
 */
export enum TieredEntityType {
  Challenge = 'CHALLENGE',
  ChargifyProduct = 'CHARGIFY_PRODUCT',
  Tier = 'TIER',
  VimeoProduct = 'VIMEO_PRODUCT',
  Workout = 'WORKOUT'
}

export type TieredProductInput = {
  internalTierId: Scalars['String'];
  tierDescription?: Maybe<Scalars['String']>;
  tierId?: Maybe<Scalars['String']>;
  tierName: Scalars['String'];
  tieredEntityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type TieredProductRelationshipResponse = {
  __typename?: 'TieredProductRelationshipResponse';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  tierId: Scalars['ID'];
  tieredEntityEnabled?: Maybe<Scalars['Boolean']>;
  tieredEntityId: Scalars['String'];
  tieredEntityType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type TieredProductResponse = {
  __typename?: 'TieredProductResponse';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  internalTierId: Scalars['String'];
  tierDescription?: Maybe<Scalars['String']>;
  tierId: Scalars['ID'];
  tierName?: Maybe<Scalars['String']>;
  tieredEntityId: Scalars['String'];
  tieredEntityType?: Maybe<TieredEntityType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type TieredVimeoProductRelationshipInput = {
  externalEntityId: Scalars['String'];
  tierId: Scalars['String'];
  tieredEntityEnabled: Scalars['Boolean'];
  tieredEntityId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type TieredVimeoProductRelationshipResponse = {
  __typename?: 'TieredVimeoProductRelationshipResponse';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  tierId: Scalars['ID'];
  tieredEntityEnabled?: Maybe<Scalars['Boolean']>;
  tieredEntityId: Scalars['String'];
  tieredEntityType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type Track = {
  __typename?: 'Track';
  calories?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  impactLevel?: Maybe<Scalars['String']>;
  keyMovements?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastModified?: Maybe<Scalars['AWSTimestamp']>;
  music?: Maybe<Array<Maybe<Music>>>;
  name?: Maybe<Scalars['String']>;
  presenterName?: Maybe<Array<Maybe<Scalars['String']>>>;
  programName?: Maybe<Scalars['String']>;
  releaseNumber?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Int']>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
  trackIntensity?: Maybe<Scalars['Int']>;
  trackNumber?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
};

export type TrackConnection = {
  __typename?: 'TrackConnection';
  items?: Maybe<Array<Maybe<Track>>>;
};

export type UpdateChapterInput = {
  chapterNumber?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Int']>;
  trackName?: Maybe<Scalars['String']>;
};

export type UpdateFormParameterInput = {
  id: Scalars['String'];
  roles?: Maybe<Array<Maybe<RoleInput>>>;
};

export type UpdateMusicInput = {
  musicCoverArtist?: Maybe<Scalars['String']>;
  musicCoverType?: Maybe<EnumMusicCoverType>;
  musicOriginalArtist?: Maybe<Scalars['String']>;
  musicPnote?: Maybe<Scalars['String']>;
  musicTitle?: Maybe<Scalars['String']>;
};

export type UpdateProgramInput = {
  alternateName?: Maybe<Scalars['String']>;
  areaRequired?: Maybe<Scalars['String']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  colour?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoAvail?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoUnavail?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['AWSURL']>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  intensity?: Maybe<Scalars['Int']>;
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  product?: Maybe<Array<Maybe<EnumProduct>>>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDesc?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SurveyUserAnswer = {	
  __typename?: 'SurveyUserAnswer';	
  answers?: Maybe<Array<Maybe<SurveyAnswer>>>;	
  displayText: Scalars['String'];	
  key: Scalars['String'];	
  questionId: Scalars['String'];	
  questionIntent: Scalars['String'];	
  questionType: SurveyQuestionType;	
  displayLabel: Scalars['String'];
};	

export type UpdatePromotionInput = {
  fileName?: Maybe<Scalars['String']>;
  md5checksum?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type UpdateReleaseInput = {
  asset_gif?: Maybe<UpdatePromotionInput>;
  asset_trailer?: Maybe<UpdatePromotionInput>;
  description?: Maybe<Scalars['String']>;
  filmingDate?: Maybe<Scalars['String']>;
  filmingLocation?: Maybe<Scalars['String']>;
  gif?: Maybe<Scalars['String']>;
  programName: Scalars['String'];
  releaseNumber: Scalars['Int'];
  trailer?: Maybe<Scalars['String']>;
};

export type UpdateResellerResponse = ErrorResponse | ResellerResponse;

export type UpdateResponse = ErrorResponse | Form;

export type UpdateTrackInput = {
  calories?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  impactLevel?: Maybe<Scalars['String']>;
  keyMovements?: Maybe<Array<Maybe<Scalars['String']>>>;
  music?: Maybe<Array<Maybe<UpdateMusicInput>>>;
  name?: Maybe<Scalars['String']>;
  presenterName?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Int']>;
  targetMuscle?: Maybe<Array<Maybe<Scalars['String']>>>;
  trackIntensity?: Maybe<Scalars['Int']>;
  trackNumber: Scalars['String'];
  workoutName: Scalars['String'];
};

export type UpdateUserInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressStateCode?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['AWSDate']>;
  categoryFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedWorkoutPreferenceAt?: Maybe<Array<Maybe<Scalars['AWSTimestamp']>>>;
  clubId?: Maybe<Scalars['String']>;
  clubMemberId?: Maybe<Scalars['String']>;
  currentPlan?: Maybe<CreatePlanDetailsInput>;
  email?: Maybe<Scalars['AWSEmail']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  familyName?: Maybe<Scalars['String']>;
  feedbackToolStatus?: Maybe<EnumFeedbackToolStatus>;
  firstName?: Maybe<Scalars['String']>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Maybe<Scalars['String']>>>;
  height?: Maybe<Scalars['Int']>;
  lmodEntitlement?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  planHistory?: Maybe<Array<Maybe<CreatePlanDetailsInput>>>;
  programFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  skipPersonal?: Maybe<Scalars['Boolean']>;
  tutorials?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<EnumUnitType>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
  usualWorkoutDuration?: Maybe<Scalars['Int']>;
  vimeoUserId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  workoutFavorites?: Maybe<Array<Maybe<WorkoutFavoritesInput>>>;
};

export type UpdateWorkoutInput = {
  areaRequired?: Maybe<Scalars['String']>;
  chapters?: Maybe<Array<Maybe<UpdateChapterInput>>>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  gif?: Maybe<Scalars['String']>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  instructorTeam?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  product?: Maybe<Scalars['String']>;
  programName: Scalars['String'];
  publishDate?: Maybe<Scalars['AWSTimestamp']>;
  releaseNumber?: Maybe<Scalars['Int']>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  subCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalViews?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressStateCode?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['AWSDate']>;
  braintreeUserId?: Maybe<Scalars['String']>;
  cartAffiliateId?: Maybe<Scalars['String']>;
  cartExpiryDate?: Maybe<Scalars['Int']>;
  cartOfferHandle?: Maybe<Scalars['String']>;
  cartOfferId?: Maybe<Scalars['String']>;
  /**   Axii cart info */
  cartProductHandle?: Maybe<Scalars['String']>;
  cartTransactionId?: Maybe<Scalars['String']>;
  categoryFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  challengeEngagements?: Maybe<ChallengeEngagements>;
  /**   Axii payment profile */
  chargifyPaymentProfile?: Maybe<ChargifyPaymentProfile>;
  chargifyUserId?: Maybe<Scalars['Int']>;
  closedWorkoutPreferenceAt?: Maybe<Array<Maybe<Scalars['AWSTimestamp']>>>;
  clubId?: Maybe<Scalars['String']>;
  clubMemberId?: Maybe<Scalars['String']>;
  currentPlan?: Maybe<PlanDetails>;
  email?: Maybe<Scalars['AWSEmail']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  familyName?: Maybe<Scalars['String']>;
  feedbackToolStatus?: Maybe<EnumFeedbackToolStatus>;
  firstName?: Maybe<Scalars['String']>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Maybe<Scalars['String']>>>;
  gymLocation?: Maybe<Scalars['String']>;
  /**   Axii reseller customer */
  hasOtherActiveSub?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  isAxii?: Maybe<Scalars['Boolean']>;
  /**   Axii force update address */
  isForceUpdateAddress?: Maybe<Scalars['Boolean']>;
  /**   Home page 2.0 user attributes. If user is on the platform for more than a month then false else true */
  isNewUser?: Maybe<Scalars['Boolean']>;
  /**   Just only support response for Reseller */
  isReseller?: Maybe<Scalars['Boolean']>;
  lmodEntitlement?: Maybe<Scalars['Boolean']>;
  lmodSubscription?: Maybe<Subscription>;
  locale?: Maybe<Scalars['String']>;
  membershipNo?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  planHistory?: Maybe<Array<Maybe<PlanDetails>>>;
  planRecs?: Maybe<Array<Maybe<PlanRecs>>>;
  programFavorites?: Maybe<Array<Maybe<Scalars['String']>>>;
  recs?: Maybe<Array<Maybe<WorkoutRecs>>>;
  /**   Show source of subscription */
  signupChannel?: Maybe<Scalars['String']>;
  skipPersonal?: Maybe<Scalars['Boolean']>;
  skippedGettingStartedSeries?: Maybe<Scalars['Boolean']>;
  /**   Axii user attributes */
  subComms?: Maybe<Scalars['Boolean']>;
  subCommsConfirmed?: Maybe<Scalars['Boolean']>;
  tuneAffiliateId?: Maybe<Scalars['String']>;
  tutorials?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  unitType?: Maybe<EnumUnitType>;
  updatedAt?: Maybe<Scalars['AWSTimestamp']>;
  userId: Scalars['String'];
  usualWorkoutDuration?: Maybe<Scalars['Int']>;
  vimeoUserId?: Maybe<Scalars['String']>;
  vimeoVideoURL?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  workoutFavorites?: Maybe<Array<Maybe<WorkoutFavorites>>>;
  workoutHistories?: Maybe<WorkoutHistoryConnection>;
  workoutStats?: Maybe<WorkoutStats>;
};


export type UserWorkoutHistoriesArgs = {
  filter?: Maybe<TableWorkoutHistoryFilterInput>;
  inCMDS?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserItemResponse = {
  __typename?: 'UserItemResponse';
  user?: Maybe<User>;
};

export type UserResponse = ErrorResponse | UserItemResponse;

export type UserSignupInput = {
  captchaSignature?: Maybe<CaptchaSignatureInput>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  password: Scalars['String'];
  referrerData?: Maybe<ReferrerDataInput>;
  subComms: Scalars['Boolean'];
  validationData?: Maybe<Array<Maybe<CognitoUserAttribute>>>;
};

export type UserWorkoutAttributes = {
  __typename?: 'UserWorkoutAttributes';
  programName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
  workoutNote?: Maybe<Scalars['String']>;
};

export type UserWorkoutAttributesInput = {
  programName?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
  workoutNote?: Maybe<Scalars['String']>;
};

export type VersionCheck = {
  __typename?: 'VersionCheck';
  platform?: Maybe<Scalars['String']>;
  upgrade?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Video = Asset & {
  __typename?: 'Video';
  bitrate?: Maybe<Scalars['Int']>;
  completedTimes?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  framerate?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  md5checksum?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  sourceFile?: Maybe<Scalars['String']>;
  unsigned_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  workoutName: Scalars['String'];
};

export type VideoPlaybackMetadata = {
  __typename?: 'VideoPlaybackMetadata';
  codec?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  playbackUrl?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
  size?: Maybe<VideoPlaybackMetadataSize>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type VideoPlaybackMetadataSize = {
  __typename?: 'VideoPlaybackMetadataSize';
  bytes?: Maybe<Scalars['Int']>;
  formatted?: Maybe<Scalars['String']>;
};

export type VimeoCollection = {
  __typename?: 'VimeoCollection';
  collectionId?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  workout?: Maybe<Workout>;
};

export enum VimeoSort {
  Alphabetical = 'alphabetical',
  Duration = 'duration',
  Finishes = 'finishes',
  Latest = 'latest',
  Newest = 'newest',
  Oldest = 'oldest',
  Plays = 'plays',
  Position = 'position'
}

/**   Vimeo User Access Token and Refresh Token */
export type VimeoUserToken = {
  __typename?: 'VimeoUserToken';
  access_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

/**   Vimeo video metadata */
export type VimeoVideoMetadata = {
  __typename?: 'VimeoVideoMetadata';
  duration?: Maybe<VimeoVideoMetadataDuration>;
  finishesCount?: Maybe<Scalars['Int']>;
  playsCount?: Maybe<Scalars['Int']>;
  videoPlaybackMetadatas?: Maybe<Array<Maybe<VideoPlaybackMetadata>>>;
};

export type VimeoVideoMetadataDuration = {
  __typename?: 'VimeoVideoMetadataDuration';
  formatted?: Maybe<Scalars['String']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type VimeoWorkoutData = {
  __typename?: 'VimeoWorkoutData';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  embedUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Voucher = {
  __typename?: 'Voucher';
  amount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Workout = {
  __typename?: 'Workout';
  areaRequired?: Maybe<Scalars['String']>;
  assetType?: Maybe<Scalars['String']>;
  assets?: Maybe<AssetConnection>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  chapters?: Maybe<Array<Maybe<Chapter>>>;
  completedTimes?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  fitnessLevel?: Maybe<Scalars['Int']>;
  geoAvail?: Maybe<Array<Maybe<Scalars['String']>>>;
  geoUnavail?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageSquare?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['AWSURL']>;
  instructorTeam?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastModified?: Maybe<Scalars['String']>;
  packagedAssets?: Maybe<AssetConnection>;
  product?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  programName?: Maybe<Scalars['String']>;
  publishDate?: Maybe<Scalars['AWSTimestamp']>;
  release?: Maybe<Release>;
  releaseNumber?: Maybe<Scalars['Int']>;
  searchTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  subCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetMuscles?: Maybe<Array<Maybe<Scalars['String']>>>;
  tiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalViews?: Maybe<Scalars['Int']>;
  tracks?: Maybe<TrackConnection>;
  vimeoWebPageUrl?: Maybe<Scalars['AWSURL']>;
  vimeoWorkoutData?: Maybe<VimeoWorkoutData>;
  workoutName?: Maybe<Scalars['String']>;
};


export type WorkoutPackagedAssetsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type WorkoutConnection = {
  __typename?: 'WorkoutConnection';
  items?: Maybe<Array<Maybe<Workout>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type WorkoutHistory = {
  __typename?: 'WorkoutHistory';
  caloriesPerMin?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  customerExperience?: Maybe<Scalars['Int']>;
  customerFeedback?: Maybe<Scalars['Boolean']>;
  customerFeedbackNote?: Maybe<Scalars['String']>;
  customerFeeling?: Maybe<Scalars['Int']>;
  product?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  timefinished?: Maybe<Scalars['AWSTimestamp']>;
  timespent?: Maybe<Scalars['Int']>;
  timestarted?: Maybe<Scalars['AWSTimestamp']>;
  userId?: Maybe<Scalars['String']>;
  vimeoSessionId?: Maybe<Scalars['String']>;
  vimeoUserId?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
  workoutNote?: Maybe<Scalars['String']>;
};

export type WorkoutHistoryConnection = {
  __typename?: 'WorkoutHistoryConnection';
  items?: Maybe<Array<Maybe<WorkoutHistory>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type WorkoutHistoryInput = {
  vimeoSessionId: Scalars['String'];
  vimeoVideoId: Scalars['String'];
  workoutNote?: Maybe<Scalars['String']>;
};

export type WorkoutStats = {
  __typename?: 'WorkoutStats';
  activeDays?: Maybe<Scalars['Int']>;
  averageDuration?: Maybe<Scalars['Int']>;
  completedWorkouts?: Maybe<Scalars['Int']>;
  topProgram?: Maybe<Scalars['String']>;
};

export type PaginationMeta = {
  __typename?: 'paginationMeta';
  page?: Maybe<Scalars['Int']>;
};

export type PlanRecs = {
  __typename?: 'planRecs';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type ReCaptchaInput = {
  auth?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  version: Scalars['Int'];
};

export type ReferrerDataInput = {
  campaignId?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
};

export type RolloverCountries = {
	threeMonth: Maybe<Scalars['String']>;
	twelveMonth: Maybe<Scalars['String']>;
}

export type WorkoutFavorites = {
  __typename?: 'workoutFavorites';
  createAt?: Maybe<Scalars['AWSTimestamp']>;
  program?: Maybe<Scalars['String']>;
  workoutName?: Maybe<Scalars['String']>;
};

export type WorkoutFavoritesFromVimeoInput = {
  condition: Scalars['Boolean'];
  externalId: Scalars['String'];
  vimeoUserId: Scalars['String'];
};

export type WorkoutFavoritesInput = {
  condition: Scalars['Boolean'];
  program: Scalars['String'];
  workoutName: Scalars['String'];
};

export type WorkoutRecs = {
  __typename?: 'workoutRecs';
  programName?: Maybe<Scalars['String']>;
  totalViews?: Maybe<Scalars['Int']>;
  workoutName?: Maybe<Scalars['String']>;
};

export type WorkoutRecsPagination = {
  __typename?: 'workoutRecsPagination';
  items?: Maybe<Array<Maybe<WorkoutRecs>>>;
  meta?: Maybe<PaginationMeta>;
};

export type AcceptRitualMutationVariables = Exact<{
  id: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: InputMaybe<Scalars['String']>;
}>;


export type AcceptRitualMutation = { __typename?: 'Mutation', acceptRitual?: boolean | null };

export type AssetByExternalIdQueryVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type AssetByExternalIdQuery = { __typename?: 'Query', assetByExternalId?: { __typename?: 'AssetData', product: string, workoutName: string } | null };

export type CreateSurveyQuestionResponseMutationVariables = Exact<{
  input: CreateSurveyQuestionResponseInput;
}>;


export type CreateSurveyQuestionResponseMutation = { __typename?: 'Mutation', createSurveyQuestionResponse?: { __typename?: 'SurveyQuestionResponse', status: string, message?: string | null } | null };

export type GetRitualEngagementQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetSurveyUserAnswerQuery = {__typename?: 'Query', survey?: {__typename?: 'Survey', id?: string, surveyVersion?: string, myAnswers?: SurveyUserAnswer[]}};
export type GetSurveyUserAnswerQueryVariables = Exact<{ id: Scalars['String']; }>;


export type GetRitualEngagementQuery = { __typename?: 'Query', getRitualEngagement?: { __typename?: 'RitualEngagement', activeRitualEngagement?: { __typename?: 'ChallengeEngagement', challengeId: string } | null } | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', userId: string, chargifyUserId?: string | null, type?: string | null, signupChannel?: boolean | null, isNewUser?: boolean | null, vimeoUserId?: string | null, givenName?: string | null, familyName?: string | null, email?: string | null, subComms?: boolean | null, subCommsConfirmed?: boolean | null, lmodEntitlement?: boolean | null, locale?: string | null, addressStreet?: string | null, addressStreet2?: string | null, addressCountry?: string | null, addressPostalCode?: string | null, addressRegion?: string | null, addressStateCode?: string | null, addressLocality?: string | null, lmodSubscription?: { __typename?: 'Subscription', product_handle?: string | null, delayed_cancel_at?: string | null } | null, cancel_at_end_of_period?: boolean | null, userEntitlements?: { __typename?: 'UserEntitlements', LMOD?: { __typename?: 'LMODEntitlement', vimeoUserTicket?: string | null }}, chargifyPaymentProfile?: { __typename?: 'ChargifyPaymentProfile', last4?: string | null, cardType?: string | null, email?: string | null, type?: string | null } | null, paymentMethod?: { __typename?: 'PaymentMethod', last4?: string | null, cardType?: string | null, email?: string | null, type?: string | null } | null } | null };

export type WorkoutPopularQueryVariables = Exact<{
  limit: Scalars['Int'];
  product: Scalars['String'];
  filter?: InputMaybe<TableWorkoutFilterInput>;
}>;


export type WorkoutPopularQuery = { __typename?: 'Query', workoutPopular?: { __typename?: 'WorkoutConnection', nextToken?: string | null, items?: Array<{ __typename?: 'Workout', workoutName?: string | null, programName?: string | null, imageWide?: any | null, equipment?: Array<string | null> | null, fitnessLevel?: number | null, categories?: Array<string | null> | null, vimeoWebPageUrl?: any | null, program?: { __typename?: 'Program', name: string, alternateName?: string | null } | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', duration?: number | null } | null> | null } | null } | null> | null } | null };

export type ListWorkoutHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkoutHistoryQuery = { __typename?: 'Query', listWorkoutHistory?: { __typename?: 'WorkoutHistoryConnection', items?: Array<{ __typename?: 'WorkoutHistory', workoutName?: string | null, programName?: string | null, timespent?: number | null } | null> | null } | null };

export type PersonalizeWorkoutQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalizeWorkoutQuery = { __typename?: 'Query', personalizeWorkout?: Array<{ __typename?: 'Workout', workoutName?: string | null, programName?: string | null, imageWide?: any | null, equipment?: Array<string | null> | null, fitnessLevel?: number | null, categories?: Array<string | null> | null, vimeoWebPageUrl?: any | null, program?: { __typename?: 'Program', name: string, alternateName?: string | null } | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', duration?: number | null } | null> | null } | null } | null> | null };

export type PersonalizedWorkoutQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalizedWorkoutQuery = { __typename?: 'Query', personalizeWorkout?: Array<{ __typename?: 'Workout', workoutName?: string | null, vimeoWebPageUrl?: any | null, imageWide?: any | null, equipment?: Array<string | null> | null, instructorTeam?: Array<string | null> | null, categories?: Array<string | null> | null, searchTags?: Array<string | null> | null, releaseNumber?: number | null, fitnessLevel?: number | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', externalId?: string | null, duration?: number | null } | null> | null } | null, tracks?: { __typename?: 'TrackConnection', items?: Array<{ __typename?: 'Track', music?: Array<{ __typename?: 'Music', musicCoverArtist?: string | null, musicCoverType?: EnumMusicCoverType | null, musicOriginalArtist?: string | null, musicTitle?: string | null } | null> | null } | null> | null } | null } | null> | null };

export type SwitchRitualMutationVariables = Exact<{
  id: Scalars['String'];
  startDate: Scalars['AWSTimestamp'];
  timeZone?: InputMaybe<Scalars['String']>;
}>;


export type SwitchRitualMutation = { __typename?: 'Mutation', switchRitual?: boolean | null };

export type WithWorkoutFragment = { __typename?: 'Workout', workoutName?: string | null, vimeoWebPageUrl?: any | null, imageWide?: any | null, equipment?: Array<string | null> | null, instructorTeam?: Array<string | null> | null, categories?: Array<string | null> | null, searchTags?: Array<string | null> | null, releaseNumber?: number | null, fitnessLevel?: number | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', externalId?: string | null, duration?: number | null } | null> | null } | null, tracks?: { __typename?: 'TrackConnection', items?: Array<{ __typename?: 'Track', music?: Array<{ __typename?: 'Music', musicCoverArtist?: string | null, musicCoverType?: EnumMusicCoverType | null, musicOriginalArtist?: string | null, musicTitle?: string | null } | null> | null } | null> | null } | null };

export type WorkoutByNameQueryVariables = Exact<{
  workoutName: Scalars['String'];
  product: Scalars['String'];
}>;

export type ProgramDetailQueryVariables = Exact<{
  programName: Scalars['String'];
}>;

export type ProgramDetailQuery = { __typename?: 'Query', workoutByNameOnly?: { __typename?: 'Workout', workoutName?: string | null, vimeoWorkoutData?: { __typename?: 'VimeoWorkoutData', embedUrl?: string | null, description?: string | null, title?: string | null, tiers?: Array<string> | null, equipment?: Array<string> | null, instructorTeam?: Array<string> | null } | null } | null };



export type WorkoutByNameQuery = { __typename?: 'Query', workoutByName?: { __typename?: 'WorkoutConnection', items?: Array<{ __typename?: 'Workout', workoutName?: string | null, vimeoWebPageUrl?: any | null, imageWide?: any | null, equipment?: Array<string | null> | null, instructorTeam?: Array<string | null> | null, categories?: Array<string | null> | null, searchTags?: Array<string | null> | null, releaseNumber?: number | null, fitnessLevel?: number | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', externalId?: string | null, duration?: number | null } | null> | null } | null, tracks?: { __typename?: 'TrackConnection', items?: Array<{ __typename?: 'Track', music?: Array<{ __typename?: 'Music', musicCoverArtist?: string | null, musicCoverType?: EnumMusicCoverType | null, musicOriginalArtist?: string | null, musicTitle?: string | null } | null> | null } | null> | null } | null } | null> | null } | null };

export type GetWorkoutByNameOnlyQueryVariables = Exact<{
  workoutName?: InputMaybe<Scalars['String']>;
}>;


export type GetWorkoutByNameOnlyQuery = { __typename?: 'Query', workoutByNameOnly?: { __typename?: 'Workout', workoutName?: string | null, vimeoWorkoutData?: { __typename?: 'VimeoWorkoutData', embedUrl?: string | null, description?: string | null, title?: string | null, tiers?: Array<string> | null, equipment?: Array<string> | null, instructorTeam?: Array<string> | null } | null } | null };

export type WorkoutsQueryVariables = Exact<{
  programs: Array<Scalars['String']> | Scalars['String'];
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type WorkoutsQuery = { __typename?: 'Query', workouts?: Array<{ __typename?: 'Workout', workoutName?: string | null, programName?: string | null, imageWide?: any | null, equipment?: Array<string | null> | null, fitnessLevel?: number | null, categories?: Array<string | null> | null, vimeoWebPageUrl?: any | null, program?: { __typename?: 'Program', name: string, alternateName?: string | null } | null, assets?: { __typename?: 'AssetConnection', items?: Array<{ __typename?: 'AssetData' } | { __typename?: 'Audio' } | { __typename?: 'Manifest' } | { __typename?: 'Subtitle' } | { __typename?: 'Video', duration?: number | null } | null> | null } | null } | null> | null };

export type RecommendedProgramsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;


export type RecommendedProgramsQuery = { __typename?: 'Query', recommendedPrograms?: Array<{ __typename?: 'Program', categories?: Array<string | null> | null, sortOrder?: number | null, name: string, alternateName?: string | null, icon?: any | null, imageWide?: any | null } | null> | null };

export const WithWorkoutFragmentDoc = gql`
    fragment WithWorkout on Workout {
  workoutName
  programName
  vimeoWebPageUrl
  imageWide
  equipment
  instructorTeam
  categories
  searchTags
  imageWide
  releaseNumber
  tiers
  fitnessLevel
  assets {
    items {
      ... on Video {
        externalId
        duration
      }
    }
  }
  tracks {
    items {
      music {
        musicCoverArtist
        musicCoverType
        musicOriginalArtist
        musicTitle
      }
    }
  }
}
    `;
export const AcceptRitualDocument = gql`
    mutation AcceptRitual($id: String!, $startDate: AWSTimestamp!, $timeZone: String) {
  acceptRitual(id: $id, startDate: $startDate, timeZone: $timeZone)
}
    `;
export type AcceptRitualMutationFn = Apollo.MutationFunction<AcceptRitualMutation, AcceptRitualMutationVariables>;

/**
 * __useAcceptRitualMutation__
 *
 * To run a mutation, you first call `useAcceptRitualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRitualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRitualMutation, { data, loading, error }] = useAcceptRitualMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useAcceptRitualMutation(baseOptions?: Apollo.MutationHookOptions<AcceptRitualMutation, AcceptRitualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptRitualMutation, AcceptRitualMutationVariables>(AcceptRitualDocument, options);
      }
export type AcceptRitualMutationHookResult = ReturnType<typeof useAcceptRitualMutation>;
export type AcceptRitualMutationResult = Apollo.MutationResult<AcceptRitualMutation>;
export type AcceptRitualMutationOptions = Apollo.BaseMutationOptions<AcceptRitualMutation, AcceptRitualMutationVariables>;
export const AssetByExternalIdDocument = gql`
    query AssetByExternalId($externalId: String!) {
  assetByExternalId(externalId: $externalId) {
    product
    workoutName
  }
}
    `;

/**
 * __useAssetByExternalIdQuery__
 *
 * To run a query within a React component, call `useAssetByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetByExternalIdQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useAssetByExternalIdQuery(baseOptions: Apollo.QueryHookOptions<AssetByExternalIdQuery, AssetByExternalIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetByExternalIdQuery, AssetByExternalIdQueryVariables>(AssetByExternalIdDocument, options);
      }
export function useAssetByExternalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetByExternalIdQuery, AssetByExternalIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetByExternalIdQuery, AssetByExternalIdQueryVariables>(AssetByExternalIdDocument, options);
        }
export type AssetByExternalIdQueryHookResult = ReturnType<typeof useAssetByExternalIdQuery>;
export type AssetByExternalIdLazyQueryHookResult = ReturnType<typeof useAssetByExternalIdLazyQuery>;
export type AssetByExternalIdQueryResult = Apollo.QueryResult<AssetByExternalIdQuery, AssetByExternalIdQueryVariables>;
export const CreateSurveyQuestionResponseDocument = gql`
    mutation CreateSurveyQuestionResponse($input: CreateSurveyQuestionResponseInput!) {
  createSurveyQuestionResponse(input: $input) {
    status
    message
  }
}
    `;
export type CreateSurveyQuestionResponseMutationFn = Apollo.MutationFunction<CreateSurveyQuestionResponseMutation, CreateSurveyQuestionResponseMutationVariables>;

/**
 * __useCreateSurveyQuestionResponseMutation__
 *
 * To run a mutation, you first call `useCreateSurveyQuestionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyQuestionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyQuestionResponseMutation, { data, loading, error }] = useCreateSurveyQuestionResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyQuestionResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyQuestionResponseMutation, CreateSurveyQuestionResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyQuestionResponseMutation, CreateSurveyQuestionResponseMutationVariables>(CreateSurveyQuestionResponseDocument, options);
      }
export type CreateSurveyQuestionResponseMutationHookResult = ReturnType<typeof useCreateSurveyQuestionResponseMutation>;
export type CreateSurveyQuestionResponseMutationResult = Apollo.MutationResult<CreateSurveyQuestionResponseMutation>;
export type CreateSurveyQuestionResponseMutationOptions = Apollo.BaseMutationOptions<CreateSurveyQuestionResponseMutation, CreateSurveyQuestionResponseMutationVariables>;
export const GetRitualEngagementDocument = gql`
    query GetRitualEngagement($userId: String!) {
  getRitualEngagement(userId: $userId) {
    activeRitualEngagement {
      challengeId
    }
  }
}
    `;

/**
 * __useGetSurveyUserAnswerQuery__
 *
 * To run a query within a React component, call `useGetSurveyUserAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRitualEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyUserAnswerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
 export function useGetSurveyUserAnswerQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyUserAnswerQuery, GetSurveyUserAnswerQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetSurveyUserAnswerQuery, GetSurveyUserAnswerQueryVariables>(GetSurveyUserAnswerDocument, options);
}
export function useGetSurveyUserAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyUserAnswerQuery, GetSurveyUserAnswerQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetSurveyUserAnswerQuery, GetSurveyUserAnswerQueryVariables>(GetSurveyUserAnswerDocument, options);
  }
export type GetSurveyUserAnswerQueryHookResult = ReturnType<typeof useGetSurveyUserAnswerQuery>;
export type GetSurveyUserAnswerLazyQueryHookResult = ReturnType<typeof useGetSurveyUserAnswerLazyQuery>;
export type GetSurveyUserAnswerQueryResult = Apollo.QueryResult<GetSurveyUserAnswerQuery, GetSurveyUserAnswerQueryVariables>;
export const GetSurveyUserAnswerDocument = gql`
query Survey($id:String!){
  survey(id: $id) {
      id
      surveyVersion
      myAnswers {
        questionId
        displayText
        displayLabel
        questionIntent
        questionType
        answers {
          displayText
          selected
          attribute {
            key
            value
          }
        }
      }
    }
}
`;

/**
 * __useGetRitualEngagementQuery__
 *
 * To run a query within a React component, call `useGetRitualEngagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRitualEngagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRitualEngagementQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRitualEngagementQuery(baseOptions: Apollo.QueryHookOptions<GetRitualEngagementQuery, GetRitualEngagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRitualEngagementQuery, GetRitualEngagementQueryVariables>(GetRitualEngagementDocument, options);
      }
export function useGetRitualEngagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRitualEngagementQuery, GetRitualEngagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRitualEngagementQuery, GetRitualEngagementQueryVariables>(GetRitualEngagementDocument, options);
        }
export type GetRitualEngagementQueryHookResult = ReturnType<typeof useGetRitualEngagementQuery>;
export type GetRitualEngagementLazyQueryHookResult = ReturnType<typeof useGetRitualEngagementLazyQuery>;
export type GetRitualEngagementQueryResult = Apollo.QueryResult<GetRitualEngagementQuery, GetRitualEngagementQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  getUser {
    userId
    chargifyUserId
    currentPlan {
        finishDate
    }
    type
    signupChannel
    workoutFavorites {
      program
      workoutName
    }
    programFavorites
    isNewUser
    isEntitledToFreeTrial
    vimeoUserId
    givenName
    familyName
    email
    subComms
    subCommsConfirmed
    userEntitlements {
      LMOD {
        vimeoUserTicket
      }
    }
    lmodEntitlement
    lmodSubscription {
      currency
      product_handle
      preview_renewal {
        totalInCentsTaxInclusive
        totalInCentsTaxExclusive
      }
      default_to_monthly
      delayed_cancel_at
      cancel_at_end_of_period
      product {
        name
        tier {
          internal_tier_id
        }
        product_price_point {
          interval
          interval_unit
          price_in_cents
        }
        currency
      }
      current_period_ends_at
      state
      subdomain
      current_period_started_at
    }
    chargifyPaymentProfile {
      last4
      cardType
      email
      type
    }
    paymentMethod {
      last4
      cardType
      email
      type
    }
    locale
    addressStreet
    addressStreet2
    addressCountry
    addressPostalCode
    addressRegion
    addressStateCode
    addressLocality
    tuneAffiliateId
  }
}
    `;

const UpdateProgramDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      programFavorites
    }
  }
`;

export function useUpdateProgramFavoritesMutation(baseOptions?: Apollo.MutationHookOptions) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SwitchRitualMutation, SwitchRitualMutationVariables>(UpdateProgramDocument, options);
}

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const WorkoutPopularDocument = gql`
    query workoutPopular($limit: Int!, $product: String!, $filter: TableWorkoutFilterInput) {
  workoutPopular(limit: $limit, product: $product, filter: $filter) {
    items {
      workoutName
      programName
      imageWide
      equipment
      fitnessLevel
      categories
      vimeoWebPageUrl
      program {
        name
        alternateName
      }
      assets {
        items {
          ... on Video {
            duration
          }
        }
      }
    }
    nextToken
  }
}
    `;

/**
 * __useWorkoutPopularQuery__
 *
 * To run a query within a React component, call `useWorkoutPopularQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutPopularQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutPopularQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      product: // value for 'product'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useWorkoutPopularQuery(baseOptions: Apollo.QueryHookOptions<WorkoutPopularQuery, WorkoutPopularQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutPopularQuery, WorkoutPopularQueryVariables>(WorkoutPopularDocument, options);
      }
export function useWorkoutPopularLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutPopularQuery, WorkoutPopularQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutPopularQuery, WorkoutPopularQueryVariables>(WorkoutPopularDocument, options);
        }
export type WorkoutPopularQueryHookResult = ReturnType<typeof useWorkoutPopularQuery>;
export type WorkoutPopularLazyQueryHookResult = ReturnType<typeof useWorkoutPopularLazyQuery>;
export type WorkoutPopularQueryResult = Apollo.QueryResult<WorkoutPopularQuery, WorkoutPopularQueryVariables>;
export const ListWorkoutHistoryDocument = gql`
    query ListWorkoutHistory {
  listWorkoutHistory(filter: {completed: {eq: true}}, limit: 100) {
    items {
      workoutName
      programName
      timespent
    }
  }
}
    `;

/**
 * __useListWorkoutHistoryQuery__
 *
 * To run a query within a React component, call `useListWorkoutHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkoutHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkoutHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkoutHistoryQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkoutHistoryQuery, ListWorkoutHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkoutHistoryQuery, ListWorkoutHistoryQueryVariables>(ListWorkoutHistoryDocument, options);
      }
export function useListWorkoutHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkoutHistoryQuery, ListWorkoutHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkoutHistoryQuery, ListWorkoutHistoryQueryVariables>(ListWorkoutHistoryDocument, options);
        }
export type ListWorkoutHistoryQueryHookResult = ReturnType<typeof useListWorkoutHistoryQuery>;
export type ListWorkoutHistoryLazyQueryHookResult = ReturnType<typeof useListWorkoutHistoryLazyQuery>;
export type ListWorkoutHistoryQueryResult = Apollo.QueryResult<ListWorkoutHistoryQuery, ListWorkoutHistoryQueryVariables>;
export const PersonalizeWorkoutDocument = gql`
    query personalizeWorkout {
  personalizeWorkout {
    workoutName
    programName
    imageWide
    equipment
    fitnessLevel
    categories
    vimeoWebPageUrl
    program {
      name
      alternateName
    }
    assets {
      items {
        ... on Video {
          duration
        }
      }
    }
  }
}
    `;

/**
 * __usePersonalizeWorkoutQuery__
 *
 * To run a query within a React component, call `usePersonalizeWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizeWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizeWorkoutQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalizeWorkoutQuery(baseOptions?: Apollo.QueryHookOptions<PersonalizeWorkoutQuery, PersonalizeWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalizeWorkoutQuery, PersonalizeWorkoutQueryVariables>(PersonalizeWorkoutDocument, options);
      }
export function usePersonalizeWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalizeWorkoutQuery, PersonalizeWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalizeWorkoutQuery, PersonalizeWorkoutQueryVariables>(PersonalizeWorkoutDocument, options);
        }
export type PersonalizeWorkoutQueryHookResult = ReturnType<typeof usePersonalizeWorkoutQuery>;
export type PersonalizeWorkoutLazyQueryHookResult = ReturnType<typeof usePersonalizeWorkoutLazyQuery>;
export type PersonalizeWorkoutQueryResult = Apollo.QueryResult<PersonalizeWorkoutQuery, PersonalizeWorkoutQueryVariables>;
export const PersonalizedWorkoutDocument = gql`
    query PersonalizedWorkout {
  personalizeWorkout {
    ...WithWorkout
  }
}
    ${WithWorkoutFragmentDoc}`;

/**
 * __usePersonalizedWorkoutQuery__
 *
 * To run a query within a React component, call `usePersonalizedWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizedWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizedWorkoutQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalizedWorkoutQuery(baseOptions?: Apollo.QueryHookOptions<PersonalizedWorkoutQuery, PersonalizedWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalizedWorkoutQuery, PersonalizedWorkoutQueryVariables>(PersonalizedWorkoutDocument, options);
      }
export function usePersonalizedWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalizedWorkoutQuery, PersonalizedWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalizedWorkoutQuery, PersonalizedWorkoutQueryVariables>(PersonalizedWorkoutDocument, options);
        }
export type PersonalizedWorkoutQueryHookResult = ReturnType<typeof usePersonalizedWorkoutQuery>;
export type PersonalizedWorkoutLazyQueryHookResult = ReturnType<typeof usePersonalizedWorkoutLazyQuery>;
export type PersonalizedWorkoutQueryResult = Apollo.QueryResult<PersonalizedWorkoutQuery, PersonalizedWorkoutQueryVariables>;
export const SwitchRitualDocument = gql`
    mutation SwitchRitual($id: String!, $startDate: AWSTimestamp!, $timeZone: String) {
  switchRitual(id: $id, startDate: $startDate, timeZone: $timeZone)
}
    `;
export type SwitchRitualMutationFn = Apollo.MutationFunction<SwitchRitualMutation, SwitchRitualMutationVariables>;

/**
 * __useSwitchRitualMutation__
 *
 * To run a mutation, you first call `useSwitchRitualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchRitualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchRitualMutation, { data, loading, error }] = useSwitchRitualMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useSwitchRitualMutation(baseOptions?: Apollo.MutationHookOptions<SwitchRitualMutation, SwitchRitualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchRitualMutation, SwitchRitualMutationVariables>(SwitchRitualDocument, options);
      }
export type SwitchRitualMutationHookResult = ReturnType<typeof useSwitchRitualMutation>;
export type SwitchRitualMutationResult = Apollo.MutationResult<SwitchRitualMutation>;
export type SwitchRitualMutationOptions = Apollo.BaseMutationOptions<SwitchRitualMutation, SwitchRitualMutationVariables>;
export const WorkoutByNameDocument = gql`
    query WorkoutByName($workoutName: String!, $product: String!) {
  workoutByName(workoutName: $workoutName, product: $product) {
    items {
      ...WithWorkout
    }
  }
}
    ${WithWorkoutFragmentDoc}`;

/**
 * __useWorkoutByNameQuery__
 *
 * To run a query within a React component, call `useWorkoutByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutByNameQuery({
 *   variables: {
 *      workoutName: // value for 'workoutName'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useWorkoutByNameQuery(baseOptions: Apollo.QueryHookOptions<WorkoutByNameQuery, WorkoutByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutByNameQuery, WorkoutByNameQueryVariables>(WorkoutByNameDocument, options);
      }
export function useWorkoutByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutByNameQuery, WorkoutByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutByNameQuery, WorkoutByNameQueryVariables>(WorkoutByNameDocument, options);
        }
export type WorkoutByNameQueryHookResult = ReturnType<typeof useWorkoutByNameQuery>;
export type WorkoutByNameLazyQueryHookResult = ReturnType<typeof useWorkoutByNameLazyQuery>;
export type WorkoutByNameQueryResult = Apollo.QueryResult<WorkoutByNameQuery, WorkoutByNameQueryVariables>;
export const GetWorkoutByNameOnlyDocument = gql`
    query getWorkoutByNameOnly($workoutName: String) {
  workoutByNameOnly(workoutName: $workoutName) {
    workoutName
    programName
    tiers
    equipment
    instructorTeam
    vimeoWorkoutData {
      embedUrl
      description
      title
    }
    assets {
      items {
        ... on Video {
          duration
        }
      }
    }
    tracks {
      items {
        duration
        keyMovements
        music {
          musicCoverArtist
          musicCoverType
          musicOriginalArtist
          musicTitle
        }
        name
        presenterName
        trackNumber
        workoutName
        startTime
      }
    }
  }
}
    `;

/**
 * __useGetWorkoutByNameOnlyQuery__
 *
 * To run a query within a React component, call `useGetWorkoutByNameOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutByNameOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutByNameOnlyQuery({
 *   variables: {
 *      workoutName: // value for 'workoutName'
 *   },
 * });
 */
export function useGetWorkoutByNameOnlyQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkoutByNameOnlyQuery, GetWorkoutByNameOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkoutByNameOnlyQuery, GetWorkoutByNameOnlyQueryVariables>(GetWorkoutByNameOnlyDocument, options);
      }
export function useGetWorkoutByNameOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutByNameOnlyQuery, GetWorkoutByNameOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkoutByNameOnlyQuery, GetWorkoutByNameOnlyQueryVariables>(GetWorkoutByNameOnlyDocument, options);
        }
export type GetWorkoutByNameOnlyQueryHookResult = ReturnType<typeof useGetWorkoutByNameOnlyQuery>;
export type GetWorkoutByNameOnlyLazyQueryHookResult = ReturnType<typeof useGetWorkoutByNameOnlyLazyQuery>;
export type GetWorkoutByNameOnlyQueryResult = Apollo.QueryResult<GetWorkoutByNameOnlyQuery, GetWorkoutByNameOnlyQueryVariables>;
export const WorkoutsDocument = gql`
    query Workouts($programs: [String!]!, $names: [String!]!) {
  workouts(programs: $programs, names: $names) {
    workoutName
    programName
    imageWide
    equipment
    fitnessLevel
    categories
    vimeoWebPageUrl
    tiers
    program {
      name
      alternateName
    }
    assets {
      items {
        ... on Video {
          duration
        }
      }
    }
  }
}
    `;

/**
 * __useWorkoutsQuery__
 *
 * To run a query within a React component, call `useWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutsQuery({
 *   variables: {
 *      programs: // value for 'programs'
 *      names: // value for 'names'
 *   },
 * });
 */
export function useWorkoutsQuery(baseOptions: Apollo.QueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, options);
      }
export function useWorkoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, options);
        }
export type WorkoutsQueryHookResult = ReturnType<typeof useWorkoutsQuery>;
export type WorkoutsLazyQueryHookResult = ReturnType<typeof useWorkoutsLazyQuery>;
export type WorkoutsQueryResult = Apollo.QueryResult<WorkoutsQuery, WorkoutsQueryVariables>;
export const RecommendedProgramsDocument = gql`
    query RecommendedPrograms($locale: String) {
  recommendedPrograms(locale: $locale) {
    categories
    sortOrder
    name
    alternateName
    icon
    imageWide
  }
}
    `;

export const ProgramDetailDocument = gql`
  query ProgramDetail($programName: String!, $product: String!) {
    program(name: $programName) {
      workoutsV2(filter: { product: { eq: $product }}) {
        items {
          programName
          workoutName
          product
          imageWide
          tiers
          vimeoWebPageUrl
          categories
          instructorTeam
          subCategory
          publishDate
          equipment
          completedTimes
          assets {
            items {
              ... on Video {
                duration
              }
            }
          }
        }
      }
    }
  }
`;

    /**
 * __useProgramDetailQuery__
 *
 * To run a query within a React component, call `useProgramDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramDetailQuery({
 *   variables: {
 *      programName: // value for 'programName'
 *   },
 * });
 */
export function useProgramDetailQuery(baseOptions?: Apollo.QueryHookOptions<ProgramDetailQuery, ProgramDetailQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ProgramDetailQuery, ProgramDetailQueryVariables>(ProgramDetailDocument, options);
}
export function useProgramDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramDetailQuery, ProgramDetailQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<ProgramDetailQuery, ProgramDetailQueryVariables>(ProgramDetailDocument, options);
  }
export type ProgramDetailQueryHookResult = ReturnType<typeof useProgramDetailQuery>;
export type ProgramDetailLazyQueryHookResult = ReturnType<typeof useProgramDetailLazyQuery>;
export type ProgramDetailQueryResult = Apollo.QueryResult<ProgramDetailQuery, ProgramDetailQueryVariables>;
/**
 * __useRecommendedProgramsQuery__
 *
 * To run a query within a React component, call `useRecommendedProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedProgramsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useRecommendedProgramsQuery(baseOptions?: Apollo.QueryHookOptions<RecommendedProgramsQuery, RecommendedProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendedProgramsQuery, RecommendedProgramsQueryVariables>(RecommendedProgramsDocument, options);
      }
export function useRecommendedProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendedProgramsQuery, RecommendedProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendedProgramsQuery, RecommendedProgramsQueryVariables>(RecommendedProgramsDocument, options);
        }
export type RecommendedProgramsQueryHookResult = ReturnType<typeof useRecommendedProgramsQuery>;
export type RecommendedProgramsLazyQueryHookResult = ReturnType<typeof useRecommendedProgramsLazyQuery>;
export type RecommendedProgramsQueryResult = Apollo.QueryResult<RecommendedProgramsQuery, RecommendedProgramsQueryVariables>;
