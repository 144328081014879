import React from 'react';
import styled, { keyframes } from 'styled-components';

const animationSpin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimateLogo = styled.div`
  width: 73px;
  height: 73px;
  position: relative;
`;

const Logo = styled.span`
  svg {
    background-color: transparent;
    position: absolute;
    bottom: 4px;
    left: 2px;
    width: 62px;
    height: auto;
  }
`;

const AnimatedWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
`;

const Spinner = styled.span`
  svg {
    position: absolute;
    right: 6px;
    top: 4px;
    -webkit-animation: ${animationSpin} 1s infinite ease-in-out;
    -moz-animation: ${animationSpin} 1s infinite ease-in-out;
    -o-animation: ${animationSpin} 1s infinite ease-in-out;
    -ms-animation: ${animationSpin} 1s infinite ease-in-out;
    animation: ${animationSpin} 1s infinite ease-in-out;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 16px;
    height: 16px;
  }
`;

const SpinnerLogo = () => (
  <AnimatedWrapper>
    <AnimateLogo>
      <Logo id="spinLogo">
        <svg
          width="114"
          height="118"
          viewBox="0 0 114 118"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="114" height="118" />
          <path
            d="M32.9875 87.2154L45.9638 0H15.9269L0.0554812 103.397C-0.513203 108.515 3.26079 117.252 12.7216 117.252H109.295L113.379 91.2479H36.503C34.0731 91.2996 32.7807 88.9732 32.9875 87.2154Z"
            fill="#FFFFFF"
          />
        </svg>
      </Logo>
      <Spinner id="spin">
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="31" height="31" />
          <path
            d="M30.4293 11.0635H19.3141V0H11.3008V11.0635H0.237305V19.1285H11.3008V30.192H19.3141V19.1285H30.4293V11.0635Z"
            fill="#FFFFFF"
          />
        </svg>
      </Spinner>
    </AnimateLogo>
  </AnimatedWrapper>
);
export default SpinnerLogo;
