import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { PlanCard } from '@lesmills-international/components';
import { SlicePlaceholder } from '@components/atoms';
import { useGridSize } from '@hooks';
import { formatFitnessLevels } from '@services';
import { PlanCardsWrapper, SlicePlaceholderWrapper, PlanCardsGridWrapper, Subtitle } from './style';
import { useFetchActivePlans, useFetchPlans } from '../../../templates/plans/hooks';
import type { Plan, PlanRegistration } from '../../../templates/plans/types';

const PlansList: FC<{
  data: Plan[];
  loading: boolean;
  activePlans: PlanRegistration[];
  planNameToSlugMap: Map<string, string>;
}> = ({ data, loading, activePlans, planNameToSlugMap }) => {
  const { gridSize } = useGridSize(true);

  if (loading) {
    return (
      <SlicePlaceholderWrapper>
        <SlicePlaceholder height="160px" />
      </SlicePlaceholderWrapper>
    );
  }

  const sortedPlans = [...(data || [])].sort((a, b) => {
    if (!a?.position || !b?.position) return 0;
    return a.position - b.position;
  });

  return (
    <>
      {sortedPlans &&
        sortedPlans.map((plan, index) => {
          const normalizedPlanName = plan?.name.trim().toLowerCase();
          const slug = planNameToSlugMap.get(normalizedPlanName);
          return (
            <Grid item xs={gridSize} key={index} role="listitem">
              <Link
                to={`/plans/${slug}`}
                state={{ plan, activePlans }}
                style={{ textDecoration: 'none' }}
              >
                <PlanCard
                  key={index}
                  backgroundImg={plan.imageNoText ? plan.imageNoText : plan.image}
                  title={plan?.displayMainTitle}
                  equipmentIcons={plan?.equipment || []}
                  fitnessLevel={formatFitnessLevels(plan?.fitnessLevel)}
                  label={plan?.displayTagline}
                  length={Math.round(plan.length / 7)}
                  daysPerWeek={plan?.daysPerWeek}
                  minPerDay={plan?.minutesPerDay}
                  link={`/plans/${slug}`}
                />
              </Link>
            </Grid>
          );
        })}
    </>
  );
};

const PlansGrid: FC<{ planNameToSlugMap: Map<string, string> }> = ({ planNameToSlugMap }) => {
  const { plansData, loading: plansLoading } = useFetchPlans();
  const { activePlans, loading: activePlansLoading, refetch } = useFetchActivePlans();

  const loading = plansLoading || activePlansLoading;

  const filteredPlans = plansData?.filter((plan) =>
    activePlans?.some((item) => item?.planId === plan?.id)
  );

  const nonActivePlansData = plansData?.filter(
    (plan) => !activePlans?.some((item) => item?.planId === plan?.id)
  );

  useEffect(() => {
    refetch();
  }, [plansData, refetch]);

  return (
    <>
      {filteredPlans?.length > 0 && <Subtitle>Active Plans in My Schedule</Subtitle>}
      <Grid container spacing={2} role="list">
        <PlansList
          data={filteredPlans}
          loading={loading}
          activePlans={activePlans}
          planNameToSlugMap={planNameToSlugMap}
        />
      </Grid>
      <Subtitle>Explore all Plans</Subtitle>
      <Grid container spacing={2} role="list">
        <PlansList
          data={nonActivePlansData}
          loading={loading}
          activePlans={activePlans}
          planNameToSlugMap={planNameToSlugMap}
        />
      </Grid>
    </>
  );
};

const PlanCards: FC = () => {
  const prismicData = useStaticQuery(graphql`
    query {
      allPrismicPlanDetailsPage {
        nodes {
          uid
          data {
            plan_name
            slug
          }
        }
      }
    }
  `);

  const prismicPlans = prismicData.allPrismicPlanDetailsPage.nodes.map((node) => ({
    plan_name: node.data.plan_name,
    slug: node.data.slug,
  }));

  const planNameToSlugMap = new Map();
  prismicPlans.forEach((plan) => {
    const normalizedPlanName = plan.plan_name.trim().toLowerCase();
    planNameToSlugMap.set(normalizedPlanName, plan.slug);
  });

  return (
    <PlanCardsWrapper>
      <PlanCardsGridWrapper>
        <PlansGrid planNameToSlugMap={planNameToSlugMap} />
      </PlanCardsGridWrapper>
    </PlanCardsWrapper>
  );
};

export default PlanCards;
