import { useState, useEffect, useCallback } from 'react';
import { createEngagementClient } from '../../../../../src/services/client';
import {
  GET_ACTIVE_PLANS,
  GET_PLAN_BY_NAME,
  START_PLAN,
  END_PLAN,
  LIST_PLANS,
} from '../../engagementGql/plans';
import { addErrorInDatadogRum } from '../../utils/utilities';
import { Plan, PlanRegistration } from './types';

export const useFetchPlans = () => {
  const [plansData, setPlansData] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPlans = useCallback(async () => {
    setLoading(true);
    try {
      const res = await createEngagementClient.query({
        query: LIST_PLANS,
      });

      const items: Plan[] = res?.data?.listPlans.items || [];
      const filteredItems = items.filter((item) => item.isDisplay === true);
      setPlansData(filteredItems);
    } catch (error) {
      addErrorInDatadogRum(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return { plansData, loading, refetch: fetchPlans };
};

export const useStartPlan = () => {
  const startPlan = async (planName: string, startDate: Date | null) => {
    try {
      const effectiveStartDate = startDate || new Date();
      const res = await createEngagementClient.mutate({
        mutation: START_PLAN,
        variables: {
          planName,
          startDate: effectiveStartDate.toISOString(),
        },
      });

      return res.data?.startPlan.success;
    } catch (error) {
      addErrorInDatadogRum(error);
      return false;
    }
  };

  return { startPlan };
};
export const useEndPlan = () => {
  const endPlan = async (planRegistrationId: string) => {
    try {
      const res = await createEngagementClient.mutate({
        mutation: END_PLAN,
        variables: {
          planRegistrationId,
        },
      });

      return res.data?.endPlan;
    } catch (error) {
      addErrorInDatadogRum(error);
      return null;
    }
  };

  return { endPlan };
};

export const useFetchActivePlans = () => {
  const [activePlans, setActivePlans] = useState<PlanRegistration[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchActivePlans = useCallback(async () => {
    setLoading(true);
    try {
      const res = await createEngagementClient.query({
        query: GET_ACTIVE_PLANS,
        fetchPolicy: 'network-only',
      });
      const items: PlanRegistration[] = res?.data?.getPlanRegistrations.items || [];
      setActivePlans(items);
    } catch (error) {
      addErrorInDatadogRum(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchActivePlans();
  }, [fetchActivePlans]);

  return { activePlans, loading, refetch: fetchActivePlans };
};
export const useFetchPlanDetails = (name: string, initialPlanDetails: Plan | null) => {
  const [planDetails, setPlanDetails] = useState(initialPlanDetails || null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initialPlanDetails) {
      const fetchPlanDetails = async () => {
        setLoading(true);
        try {
          const res = await createEngagementClient.query({
            query: GET_PLAN_BY_NAME,
            variables: { name },
          });
          const planData = res?.data?.getPlanByName?.items?.[0] || null;
          setPlanDetails(planData);
        } catch (error) {
          addErrorInDatadogRum(error);
        } finally {
          setLoading(false);
        }
      };

      fetchPlanDetails();
    }
  }, [name, initialPlanDetails]);

  return { planDetails, loading };
};
