import React, { useContext, useRef, useState } from 'react';
import { TextField, Button } from '@lesmills-international/components';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import { checkHaveErrors } from '../../utils/validator';

import {
  PersonalProfileErrorMessages,
  PersonalProfileFormErrors,
  PersonalProfileSuccessMessage,
  NotificationHandle,
} from './types';
import { ButtonWrapper, Form } from './styles';

import {
  getErrorMessage,
  getPersonalInfoSuccessMessage,
  getPersonalInfoValidatorErrors,
  isFormValueChanged,
} from './utils';
import { PERSONAL_INFO_FIELD_NAME } from './constants';
import useUpdateUser from '../../hooks/mutation/useUpdateUser';
import { FormErrorName } from '../../types/DataTracking';
import { addErrorInDatadogRum } from '../../utils/utilities';

interface Props {
  firstNameLabel: string | null;
  lastNameLabel: string | null;
  emailLabel: string | null;
  buttonText: string | null;
  errorMessage: PersonalProfileErrorMessages;
  successMessage: PersonalProfileSuccessMessage;
  handleNotification: NotificationHandle;
  successCallback: () => void;
  handleOnFormStart: () => void;
  handleFormTrackingError: (formErrorName: FormErrorName) => void;
}

const UpdatePersonalProfile = ({
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  errorMessage,
  successMessage,
  buttonText,
  handleNotification,
  successCallback,
  handleOnFormStart,
  handleFormTrackingError,
}: Props) => {
  const {
    givenName: currentFirstName,
    familyName: currentLastName,
    email: originalEmail,
    refetchUser,
  } = useContext(userContext);
  const currentEmail = originalEmail?.toLowerCase();

  const firstNameInput = useRef<HTMLInputElement>();
  const lastNameInput = useRef<HTMLInputElement>();
  const emailInput = useRef<HTMLInputElement>();

  const [formErrors, setFormErrors] = useState<PersonalProfileFormErrors | null>(null);

  const [handleUpdate, { loading }] = useUpdateUser({
    onError: (err) => {
      const messasge = getErrorMessage(err, errorMessage);
      handleFormTrackingError(FormErrorName.SERVER_ERROR);
      handleNotification(messasge, 'error');
      addErrorInDatadogRum(err);
    },
    onCompleted: (data) => {
      refetchUser && refetchUser();
      const { updateAxiiUser } = data;

      const { givenName, familyName, email } = updateAxiiUser;

      const newInfo = {
        firstName: givenName,
        lastName: familyName,
        email,
      };

      const currentInfo = {
        firstName: currentFirstName,
        lastName: currentLastName,
        email: currentEmail,
      };

      successCallback && successCallback();
      const messaage = getPersonalInfoSuccessMessage({ newInfo, currentInfo, successMessage });
      handleNotification(messaage, 'success');
    },
  });

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const firstName = firstNameInput.current?.value?.trim() || null;
    const lastName = lastNameInput.current?.value?.trim() || null;
    const email = emailInput.current?.value?.trim()?.toLowerCase() || null;

    const newInfo = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
    };

    const currentInfo = {
      firstName: currentFirstName || '',
      lastName: currentLastName || '',
      email: currentEmail || '',
    };

    const validatorErrors = getPersonalInfoValidatorErrors(newInfo, errorMessage);
    setFormErrors({ ...formErrors, ...validatorErrors });

    if (checkHaveErrors(validatorErrors)) {
      handleFormTrackingError(FormErrorName.INCOMPELTE_FIELDS);
      return;
    }

    const formValueChanged = isFormValueChanged(
      [
        PERSONAL_INFO_FIELD_NAME.FIRST_NAME,
        PERSONAL_INFO_FIELD_NAME.LAST_NAME,
        PERSONAL_INFO_FIELD_NAME.EMAIL,
      ],
      newInfo,
      currentInfo
    );

    if (formValueChanged) {
      handleUpdate({
        variables: {
          input: {
            givenName: firstName,
            familyName: lastName,
            email,
          },
        },
      });
    }
  };

  return (
    <Form id="edit-profile-form" component="form">
      <TextField
        label={firstNameLabel}
        autoComplete="given-name"
        variant="filled"
        name="first_name"
        id="first-name-field"
        defaultValue={currentFirstName}
        error={!!formErrors?.firstName.errorMsg}
        helperText={formErrors?.firstName.errorMsg}
        inputRef={firstNameInput}
        onFocus={handleOnFormStart}
      />
      <TextField
        label={lastNameLabel}
        autoComplete="family-name"
        variant="filled"
        name="last_name"
        id="last-name-field"
        defaultValue={currentLastName}
        error={!!formErrors?.lastName.errorMsg}
        helperText={formErrors?.lastName.errorMsg}
        inputRef={lastNameInput}
      />
      <TextField
        className="email-text-field"
        label={emailLabel}
        autoComplete="email"
        variant="filled"
        name="email"
        id="email-field"
        defaultValue={currentEmail}
        error={!!formErrors?.email.errorMsg}
        helperText={formErrors?.email.errorMsg}
        inputRef={emailInput}
      />

      <ButtonWrapper>
        <Button
          id="personal-profile-submit-button"
          onClick={handleSubmit}
          ctaButton
          style={{ width: '87px' }}
          type="submit"
          loading={loading}
        >
          <>{buttonText}</>
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

export default UpdatePersonalProfile;
