import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import { withPrivateRoute, Layout } from '@components/molecules';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  margin: 96px;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.lightishGrey};
  font-family: 'Avenir heavy';
  margin-top: 60px;
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.lightishGrey};
  margin-top: 12px;
`;

const Content = styled.div`
  margin-top: 120px;
  color: ${(props) => props.theme.colors.lightishGrey};
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MyWorkoutsTemplate: FC = ({ data }) => {
  const { prismicLegalInformation } = data || {};

  return (
    <Layout>
      <Wrapper>
        <Title>{prismicLegalInformation?.data?.title}</Title>
        <SubTitle>{prismicLegalInformation?.data?.version}</SubTitle>
        <Content>
          <RichText
            render={prismicLegalInformation?.data?.content?.richText}
            key={prismicLegalInformation?.data?.content?.text}
          />
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(MyWorkoutsTemplate));

export const query = graphql`
  query GetLegalInfoData($lang: String!) {
    prismicLegalInformation(lang: { eq: $lang }) {
      data {
        title
        version
        content {
          richText
          text
        }
      }
    }
  }
`;
