import React, { FC } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@lesmills-international/components';
import styled from 'styled-components';
import { COLORS } from '@themes';
import Checkbox from '@mui/material/Checkbox';
import qrCodeImagePROD from '../../../assets/qr-code-PROD.png';
import qrCodeImageUAT from '../../../assets/qr-code-UAT.png';
import workoutPlannerGif from '../../../assets/workout-planner.gif';

interface QrDialogProps {
  open: boolean;
  onClose: () => void;
  planName: string | undefined;
  subComms?: boolean | null;
  handleOptIn?: () => void;
  headingText: string;
  subHeadingText: string;
  checkboxText: string;
  doneButtonText: string;
}

const DarkDialog = styled((props) => <Dialog {...props} />)`
  & .MuiPaper-root {
    background-color: ${COLORS.neoBlack};
  }

  & .MuiIconButton-root {
    color: ${COLORS.whiteOpacity07};
  }
`;

const DialogWrapper = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 25px;
  font-size: 25px;
  letter-spacing: 0.15px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  flex: 1;
  color: white;

  h4 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  img {
    margin-bottom: 10px;
    display: flex;
    align-self: center;
    padding: 10px;
    width: 200px;
    height: 200px;
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    span {
      margin-left: 10px;
      font-size: 14px;
    }
  }
`;

const RightColumn = styled.div<{ subComms?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  img {
    width: ${({ subComms }) => (subComms ? '250px' : '180px')};
  }
`;

const PostStartPlanDialog: FC<QrDialogProps> = ({
  open,
  onClose,
  planName,
  subComms,
  handleOptIn,
  headingText,
  subHeadingText,
  checkboxText,
  doneButtonText,
}) => {
  const isUAT =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('lmplus-uat');
  const qrCodeImage = isUAT ? qrCodeImageUAT : qrCodeImagePROD;

  return (
    <DarkDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <DialogContent>
        <DialogWrapper>
          <h1>
            {headingText} {planName}!
          </h1>
        </DialogWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <LeftColumn>
            <h4>{subHeadingText}</h4>
            <img src={qrCodeImage} alt="QR Code" />
            {!subComms && (
              <div className="checkbox-container">
                <Checkbox
                  onClick={handleOptIn}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 28,
                    },
                  }}
                />
                <span>{checkboxText}</span>
              </div>
            )}
          </LeftColumn>
          <RightColumn subComms={subComms}>
            <img src={workoutPlannerGif} alt="Lesmills+ App" />
          </RightColumn>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            style={{ backgroundColor: COLORS.neoBlack, color: COLORS.green }}
            onClick={onClose}
          >
            {doneButtonText}
          </Button>
        </div>
      </DialogActions>
    </DarkDialog>
  );
};

export default PostStartPlanDialog;
