import React, { Suspense, useEffect } from 'react';
import { SpinnerLogo } from '@components/atoms';
import AppStoreLogo from './appStoreLogo.svg';
import GoogleStoreLogo from './googleStoreLogo.svg';

const MobileLink: React.FC = () => {
  useEffect(() => {
    const { userAgent } = navigator;

    const redirectTimeout = setTimeout(() => {
      if (/android/i.test(userAgent)) {
        window.location.href = 'market://details?id=com.lesmills.fitnessplus';
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href = 'itms-apps://itunes.apple.com/app/apple-store/id1483001711?mt=8';
      }
    }, 3000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h2>Loading, please wait...</h2>
        <div style={styles.logo}>
          <Suspense>
            <SpinnerLogo />
          </Suspense>
        </div>
        <div>
          <p>If the page does not properly redirect, you may download the app below:</p>
        </div>
        <div style={styles.buttons}>
          <a href="https://apps.apple.com/nz/app/les-mills-home-workout-app/id1483001711">
            <img src={AppStoreLogo} alt="App Store" style={styles.storeBtn} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.lesmills.fitnessplus">
            <img src={GoogleStoreLogo} alt="Google Play Store" style={styles.storeBtn} />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#222222',
    flexDirection: 'column',
    color: 'white',
    textAlign: 'center',
    paddingTop: '52px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  buttons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  storeBtn: {
    width: '150px',
  },
  loader: {
    color: '#fff',
    fontSize: '18px',
  },
};

export default MobileLink;
