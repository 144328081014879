import React, { FC } from 'react';
import {
  ShopProductSlice,
  ShopHeroSlice,
  ShopReviewSlice,
  LearnVideoSlice,
  LearnRichTextSlice,
  LearnImageSlice,
  HomeArticleSlice,
  HeroSlice,
  PersonalizedHeroSlice,
  HomeProgramsSlice,
  HomeWorkoutsSlice,
  ImageSlice,
  ContrastArticlesSectionSlice,
  ArticlesSectionSlice,
  QuoteSlice,
  VideoCardGridTabs,
  ProgramHero,
} from '@components/molecules';

// Migrate slices to a new folder to make the difference between slices and molecules clearer
import {
  heroCarouselSlice,
  exploreProgramsWithCategoryFilterSlice,
  exploreAlbumSlice,
  videoCarouselSlice,
  albumsCarouselSlice,
  programCarouselSlice,
  VideoSlim,
  AlbumAndWorkoutCarousel,
  HomepageFooter,
  PlaylistGrid,
  WorkoutSpotlightRibbons,
  FavoriteContent,
  MyScheduledWorkouts,
  planDetailHeroSlice,
  planCardsSlice,
} from '@components/slices';

import type { Program } from '@backend';

interface Props {
  slices:
    | readonly (
        | {
            slice_type?: string;
            id: string;
            primary: {
              title?: string;
            };
          }
        | undefined
      )[]
    | undefined;
  listPrograms: Program[];
  pageName: string | null;
  programName?: string | null;
}

const SliceZone: FC<Props> = ({ slices, listPrograms, pageName, programName }) => {
  // Setup the relation between the react component and the prismic slice_type
  const sliceComponents = {
    program_hero_carousel: ProgramHero,
    workout_video_carousel_tab: VideoCardGridTabs,
    explore_hero_carousel: heroCarouselSlice,
    collection_hero_carousel: heroCarouselSlice,
    programs_with_category_filter: exploreProgramsWithCategoryFilterSlice,
    album: exploreAlbumSlice,
    homepage_hero_carousel: AlbumAndWorkoutCarousel,
    workout_video_carousel: videoCarouselSlice,
    albums_carousel: albumsCarouselSlice,
    learn_video_slice: LearnVideoSlice,
    learn_rich_text_slice: LearnRichTextSlice,
    learn_image_slice: LearnImageSlice,
    product_slice: ShopProductSlice,
    hero_slice: ShopHeroSlice,
    review_slice: ShopReviewSlice,
    article_slice: HomeArticleSlice,
    programs_section: HomeProgramsSlice,
    programs: programCarouselSlice,
    new_workouts: HomeWorkoutsSlice,
    hero_section: HeroSlice,
    personalized_hero_section: PersonalizedHeroSlice,
    image_spot: ImageSlice,
    contrast_articles_section: ContrastArticlesSectionSlice,
    articles_section: ArticlesSectionSlice,
    quote: QuoteSlice,
    video_slim: VideoSlim,
    homepage_footer: HomepageFooter,
    spotlight_ribbon: WorkoutSpotlightRibbons,
    playlist_or_workout_grid: PlaylistGrid,
    favorite_content: FavoriteContent,
    my_scheduled_workouts: MyScheduledWorkouts,
    plan_details_hero: planDetailHeroSlice,
    plan_cards: planCardsSlice,
  };
  let sliceRowCount = 0;
  return (
    <div className="slice--container">
      {slices?.map((slice, index) => {
        if (!slice?.slice_type) return null;
        sliceRowCount += 1;
        const SliceComponent = sliceComponents[
          slice.slice_type as keyof typeof sliceComponents
        ] as any;

        if (SliceComponent) {
          const sliceProps = {
            analytics: {
              contentListTitle: slice?.primary?.title,
              contentListIndex: index + 1,
              contentLocation: `${pageName} - ${slice?.primary?.title}`,
            },
            content: slice,
            previousSliceType: sliceRowCount > 0 ? slices?.[sliceRowCount - 1]?.slice_type : null,
            key: slice.id,
            pageName,
            programName,
          };
          if (
            slice.slice_type === 'programs_with_category_filter' ||
            slice.slice_type === 'programs'
          ) {
            return <SliceComponent {...sliceProps} listPrograms={listPrograms} />;
          }
          if (slice.slice_type === 'workout_video_carousel_tab') {
            const spotlightRibbonSlice = slices.filter(
              (sliceItem) => sliceItem?.slice_type === 'spotlight_ribbon'
            )[0];
            const displaySpotlightRibbon =
              spotlightRibbonSlice && spotlightRibbonSlice?.primary?.display_spotlight_ribbon;
            return (
              <SliceComponent {...sliceProps} displaySpotlightRibbon={displaySpotlightRibbon} />
            );
          }
          if (slice.slice_type === 'spotlight_ribbon') {
            const displaySpotlightRibbon = slice?.primary?.display_spotlight_ribbon;
            if (!displaySpotlightRibbon) {
              return null;
            }
            return (
              <SliceComponent {...sliceProps} displaySpotlightRibbon={displaySpotlightRibbon} />
            );
          }

          return <SliceComponent {...sliceProps} />;
        }

        return null;
      })}
    </div>
  );
};

export default SliceZone;
