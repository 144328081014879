import { startOfDay } from 'date-fns';

export const isBrowser = typeof window !== 'undefined';

export const setLocalStorage = (key, data) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isBrowser && window.localStorage && window.localStorage.setItem(key, data);
};

export const deleteLocalStorage = (key) =>
  isBrowser && window.localStorage && window.localStorage.removeItem(key);

export const addErrorInDatadogRum = (error) => {
  if (isBrowser && window.datadogRum) {
    window.datadogRum.addError(error);
  }
};

export const getPromotionInfoFromUrl = (location) => {
  const urlParams = new URLSearchParams(location.search);
  const promotionCode = urlParams.get('promo');
  const referrerId = urlParams.get('grsf');
  const affiliateId = urlParams.get('aff_id');
  const offerId = urlParams.get('offer_id');
  const transactionId = urlParams.get('transaction_id');
  let discountInfoFormatted;
  if (promotionCode) {
    discountInfoFormatted = {
      type: 'promotionCode',
      data: {
        promotionCode,
      },
    };
  } else if (referrerId) {
    discountInfoFormatted = {
      type: 'refer',
      data: {
        referrerId,
      },
    };
  } else if (affiliateId) {
    discountInfoFormatted = {
      type: 'affiliate',
      data: {
        affiliateId,
        offerId,
        transactionId,
      },
    };
  }

  const localStorageKey = 'discountInfo';
  if (discountInfoFormatted) {
    setLocalStorage(localStorageKey, JSON.stringify(discountInfoFormatted));
  } else {
    // clear discountInfo from BAU landing
    deleteLocalStorage(localStorageKey);
  }
  return discountInfoFormatted;
};

export const parseJwt = (token) => {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const localeInNavLink = (link) => {
  // link is a string, like for "/" and "/shop"
  // if the client has a locale cookie, and if the link is one below, add the locale to the url
  const locale = readCookie('locale');
  if (typeof locale !== 'string' || !new RegExp('^[a-z]{2}-[a-z]{2}$').test(locale)) {
    return link; // no locale cookie (or value invalid), so do nothing with the given link
  }

  const linksToAddLocale = {
    '^/fit-planet/?$': `/${locale}/fit-planet/`,
    '^/shop/?$': `/shop/${locale}`,
    '^/$': `/${locale}`,
  };

  Object.keys(linksToAddLocale).forEach((key) => {
    const regularExpression = new RegExp(key);
    if (regularExpression.test(link)) {
      return link.replace(regularExpression, linksToAddLocale[key]);
    }
  });

  return link;
};

export const readCookie = (name) => {
  // returns value of a client-side cookie

  if (typeof document.cookie !== 'string') {
    return null;
  }
  const cookieArr = document.cookie.split(';');

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    // remove whitespace at the beginning of the cookie name and compare with given name
    if (trimString(name) === trimString(cookiePair[0])) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const trimString = (str) =>
  // removes whitespaces at the beginning and end of a string, better browser compatibility than .trim()
  str.replace(new RegExp('^\\s+'), '').replace(new RegExp('\\s+$'), '');

export const isInExplorePage = (url) => {
  const exploreRegex = /\/explore\/?$/;
  return exploreRegex.test(url);
};

export const getMobileOperatingSystem = () => {
  if (isBrowser) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }
  return 'unknown';
};

export const convertToRelativeTime = (timestamp) => {
  const now = new Date();
  const parsedTimestamp = new Date(timestamp);

  const timeDifference = now.getTime() - parsedTimestamp.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  }
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  return 'just now';
};

export function findUniqueItems(arrays) {
  // Use flat() to flatten the array of arrays into a single array
  const flatArray = arrays?.flat();

  // Use Set to store unique items
  const uniqueItems = new Set(flatArray);
  const uniqueArray = [...uniqueItems].filter((item) => item !== null && item !== undefined);
  return uniqueArray;
}

export function haveCommonItems(arr1, arr2) {
  return arr1.some((item) => arr2?.includes(item));
}

export function getFiveMonthsLaterDate() {
  const now = new Date();
  const todayStart = startOfDay(now);
  todayStart.setMonth(todayStart.getMonth() + 5);
  const isoString = todayStart.toISOString();
  return isoString;
}
