import React, { FC } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@lesmills-international/components';
import styled from 'styled-components';
import { COLORS } from '@themes';

interface EndPlanDialogProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onEndPlan: () => void;
  headingText: string;
  bodyText: string;
  cancelText: string;
  acceptText: string;
  loading: boolean;
  errorMessage: string | null;
  setErrorMessage: (errorMessage: string | null) => void;
}

const DarkDialog = styled((props) => <Dialog {...props} />)`
  & .MuiPaper-root {
    background-color: ${COLORS.neoBlack};
  }

  & .MuiIconButton-root {
    color: ${COLORS.whiteOpacity07};
  }
`;

const Title = styled.h3`
  font-family: 'Avenir heavy';
  font-size: 24px;
  font-weight: 800;
  color: ${COLORS.whiteOpacity09};
  margin-bottom: 10px;
`;

const Content = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${COLORS.whiteOpacity07};
`;

const EndPlanDialog: FC<EndPlanDialogProps> = ({
  open,
  onClose,
  onEndPlan,
  onCancel,
  headingText,
  bodyText,
  cancelText,
  acceptText,
  loading,
  errorMessage,
  setErrorMessage,
}) => {
  const handleClose = () => {
    if (!loading) {
      setErrorMessage(null);
      onClose();
    }
  };

  return (
    <DarkDialog
      open={open}
      onClose={handleClose}
      onCancel={onCancel}
      fullWidth
      maxWidth="sm"
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '150px',
          }}
        >
          <Title>{headingText}</Title>
          <Content style={{ padding: '20px, 24px, 24px, 24px' }}>{bodyText}</Content>
          <div
            style={{
              color: COLORS.errorText,
              display: errorMessage ? 'block' : '',
            }}
          >
            {errorMessage}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant="text"
            style={{ color: COLORS.accentGreen, backgroundColor: COLORS.neoBlack }}
            disabled={loading}
            onClick={handleClose}
          >
            {cancelText}
          </Button>
          <Button
            variant="text"
            style={{
              color: loading || !!errorMessage ? COLORS.white : COLORS.errorText,
              backgroundColor: COLORS.neoBlack,
              padding: '20px',
            }}
            onClick={onEndPlan}
            disabled={loading || !!errorMessage}
          >
            {acceptText}
          </Button>
        </div>
      </DialogActions>
    </DarkDialog>
  );
};

export default EndPlanDialog;
