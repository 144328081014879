import React, { useState, useContext, useEffect } from 'react';
import { EquipmentIcon, Chip } from '@lesmills-international/components';
import { ReactComponent as CloseIcon } from '@assets/icons/close-nocolor.svg';
import styled, { css } from 'styled-components';
import { DURATIONS, SORTING, TIERS } from '@constants';
import { userContext } from '@context';
import { getVimeoProgramPageUrl } from '@services';
import { contentListViewTracking } from '@src/utils';
import { breakpoint } from '@themes';
import {
  VideoGridTabWrapper,
  TitleWrapper,
  Title,
  Link,
  FixedBannerWrapper,
  BannerText,
} from './styles';
import VideoCardGrid from '../videoCardGrid';
import { findUniqueItems, haveCommonItems } from '../../../utils/utilities';
import { getInstructorsWithAvatar } from '../../../pages/workout/[name]';
import MultiSelectDropdown from '../dropdown/MultiSelect';
import SingleSelectDropdown from '../dropdown/SingleSelect';

const WorkoutTitle = styled.p`
  font-size: 24px;
  line-height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 18px;

  ${breakpoint('sm')`
      margin-left: 32px;
      margin-right: 32px;
    `}

  ${breakpoint('lg')`
      margin-left: 80px;
      margin-right: 80px;
    `}
`;

const Wrapper = styled.div<{ isFilterActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ isFilterActive }) =>
    isFilterActive &&
    css`
      margin-left: 38px;
    `}
`;

const LeftFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlignWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 54px;
  width: 100%;
`;

const ClearWrapper = styled.div`
  width: 38px;
  position: absolute;
  top: 8px;
  left: -4px;
`;

const ChipWrapper = styled.div`
  padding: 8px 16px 8px 0;
  white-space: nowrap;
`;

const ClearIcon = styled.div`
  transform: scale(0.7);
`;

const VideoCardGridTab = ({ content: { slices }, programName, displaySpotlightRibbon }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [activeTabInx, setActiveTabInx] = useState(0);
  const allInstructors = slices?.[0]?.items?.map((item) => item?.instructorTeam);
  const allEquipment = slices?.[0]?.items?.map((item) => item?.equipmentIcons);
  const allTypes = slices?.[0]?.items?.map((item) => item?.subCategory);
  const allLanguages = slices?.[0]?.items?.map((item) => item?.language);
  const durations = [DURATIONS.SHORT, DURATIONS.MIN_30, DURATIONS.MIN_45, DURATIONS.MIN_55];
  const types = findUniqueItems(allTypes);
  const sorting = [SORTING.DEF, SORTING.NEW, SORTING.OLD];
  const instructors = getInstructorsWithAvatar(findUniqueItems(allInstructors), true);
  const equipment = findUniqueItems(allEquipment);
  const languages = findUniqueItems(allLanguages);
  const [selectedDurations, setSelectedDurations] = useState(urlParams.getAll('selectedDurations'));
  const [selectedTypes, setSelectedTypes] = useState(urlParams.getAll('selectedTypes'));

  addOrUpdateURLParam('selectedLanguage', 'English');
  const [selectedLanguage, setSelectedLanguage] = useState(urlParams.getAll('selectedLanguage'));

  const [selectedSorting, setSelectedSorting] = useState(SORTING.DEF);
  const [selectedInstructors, setSelectedInstructors] = useState(
    urlParams.getAll('selectedInstructors')
  );
  const [selectedEquipment, setSelectedEquipment] = useState(urlParams.getAll('selectedEquipment'));
  const [showCompleted, setShowCompleted] = useState(urlParams.get('showCompleted') === 'true');
  const [showNotCompleted, setShowNotCompleted] = useState(
    urlParams.get('showNotCompleted') === 'true'
  );
  const [showSaved, setShowSaved] = useState(urlParams.get('showSaved') === 'true');

  const { tier, userEntitlements, workoutFavorites } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;
  const savedWorkoutNames = workoutFavorites?.map((item) => item?.workoutName);
  const [durationsDropdownOpen, setDurationsDropdownOpen] = useState(false);
  const [typesDropdownOpen, setTypesDropdownOpen] = useState(false);
  const [InstructorDropdownOpen, setInstructorDropdownOpen] = useState(false);
  const [equipmentDropdownOpen, setEquipmentDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  function convertWorkoutDuration(duration: string): string {
    const [minutes] = duration.split(':').map(Number);

    if (minutes <= 25) {
      return DURATIONS.SHORT;
    }
    if (minutes <= 35) {
      return DURATIONS.MIN_30;
    }
    if (minutes <= 50) {
      return DURATIONS.MIN_45;
    }
    if (minutes <= 60) {
      return DURATIONS.MIN_55;
    }

    // Default to "Short" for unrecognized durations
    return DURATIONS.SHORT;
  }

  function removeURLParam(paramName: string): void {
    const url = new URL(window.location.href);

    // Remove the specified parameter
    urlParams.delete(paramName);

    // Replace the current URL without triggering a page reload
    window.history.replaceState({}, '', `${url.pathname}?${urlParams.toString()}`);
  }

  function addOrUpdateURLParam(paramName: string, paramValue: string | string[]): void {
    const url = new URL(window.location.href);

    // Clear existing parameter to update it with new values
    urlParams.delete(paramName);

    if (Array.isArray(paramValue)) {
      // If the value is an array, append each value to the parameter
      paramValue.forEach((value) => {
        urlParams.append(paramName, value);
      });
    } else {
      // If the value is not an array, just set the single value
      urlParams.set(paramName, paramValue);
    }

    // Replace the current URL without triggering a page reload
    window.history.replaceState({}, '', `${url.pathname}?${urlParams.toString()}`);
  }

  function filterWorkoutDone(items: string[]): string[] {
    let filteredWorkouts = items;

    if (showCompleted && showNotCompleted) {
      return items;
    }

    if (showCompleted) {
      addOrUpdateURLParam('showCompleted', showCompleted.toString());
      filteredWorkouts = filteredWorkouts.filter((workout) => workout.completedTimes > 0);
    }

    if (showNotCompleted) {
      addOrUpdateURLParam('showNotCompleted', showNotCompleted.toString());
      filteredWorkouts = filteredWorkouts.filter((workout) => workout.completedTimes === 0);
    }

    if (!showCompleted) {
      removeURLParam('showCompleted');
    }

    if (!showNotCompleted) {
      removeURLParam('showNotCompleted');
    }
    return filteredWorkouts;
  }

  function filterWorkoutSaved(items: string[]): string[] {
    if (!showSaved) {
      removeURLParam('showSaved');
      return items;
    }
    addOrUpdateURLParam('showSaved', showSaved.toString());

    const filteredWorkouts = items.filter((workout) => savedWorkoutNames?.includes(workout?.name));

    return filteredWorkouts;
  }

  function filterWorkoutDurations(items: string[], selectedItems?: string[]): string[] {
    if (!selectedItems || selectedItems.length === 0) {
      removeURLParam('selectedDurations');
      return items;
    }
    addOrUpdateURLParam('selectedDurations', selectedDurations);

    return items.filter((item) => selectedItems.includes(convertWorkoutDuration(item?.duration)));
  }

  function filterWorkoutTypes(items: string[], selectedItems?: string[]): string[] {
    if (!selectedItems || selectedItems.length === 0) {
      removeURLParam('selectedTypes');
      return items;
    }
    addOrUpdateURLParam('selectedTypes', selectedTypes);
    return items.filter(
      (item) => item?.subCategory && selectedItems.includes(item?.subCategory[0])
    );
  }

  function filterInstructors(items: string[], selectedItems?: string[]): string[] {
    if (!selectedItems || selectedItems.length === 0) {
      removeURLParam('selectedInstructors');
      return items;
    }
    addOrUpdateURLParam('selectedInstructors', selectedInstructors);

    return items.filter((item) => haveCommonItems(selectedItems, item?.instructorTeam));
  }

  function filterLanguage(items: string[], selectedItems?: string[]): string[] {
    if (!selectedItems || selectedItems.length === 0) {
      removeURLParam('selectedLanguage');
      return items;
    }
    addOrUpdateURLParam('selectedLanguage', selectedLanguage);

    return items.filter((item) => haveCommonItems(selectedItems, item?.language));
  }

  function filterEquipment(items: string[], selectedItems?: string[]): string[] {
    if (!selectedItems || selectedItems.length === 0) {
      removeURLParam('selectedEquipment');
      return items;
    }
    addOrUpdateURLParam('selectedEquipment', selectedEquipment);

    return items.filter((item) => haveCommonItems(selectedItems, item?.equipmentIcons));
  }
  const filteredWorkouts = slices[0].items.filter(
    (workout) => !workout?.name?.includes('Keep on Moving')
  );
  const filteredDone = filterWorkoutDone(filteredWorkouts);
  const filteredSaved = filterWorkoutSaved(filteredDone);
  const filteredDurations = filterWorkoutDurations(filteredSaved, selectedDurations);
  const filteredTypes = filterWorkoutTypes(filteredDurations, selectedTypes);
  const filteredInstructors = filterInstructors(filteredTypes, selectedInstructors);
  const filteredEquipment = filterEquipment(filteredInstructors, selectedEquipment);
  const filteredSlices = filterLanguage(filteredEquipment, selectedLanguage);

  const getDuration = (duration) => {
    const [minutes] = duration.split(':').map(Number);
    return minutes;
  };

  function getMainName(name) {
    // Regular expression to match the pattern with a number
    const regex = /#\d+/;

    // Search for the pattern in the input name
    const match = name.match(regex);

    // If a match is found, return the part of the name up to and including the match
    if (match) {
      const index = name.indexOf(match[0]) + match[0].length;
      return name.slice(0, index).trim();
    }

    // If no match is found, return the input name (or handle as desired)
    return name;
  }

  // eslint-disable-next-line array-callback-return
  filteredSlices.sort((a, b) => {
    const moveToBack = (subCategory) => subCategory === 'Remix' || subCategory === 'Learn';

    if (moveToBack(a?.subCategory[0]) && !moveToBack(b?.subCategory[0])) {
      return 1;
    }
    if (!moveToBack(a?.subCategory[0]) && moveToBack(b?.subCategory[0])) {
      return -1;
    }
    // If subCategory condition is not applicable, sort by name and duration
    const nameA = getMainName(a?.name);
    const nameB = getMainName(b?.name);

    if (nameA === nameB) {
      // Sort by duration if names are the same
      const durationA = getDuration(a?.duration);
      const durationB = getDuration(b?.duration);
      return durationB - durationA;
    }
  });

  const completedWorkoutsCount = filteredWorkouts?.filter(
    (workout) => workout.completedTimes > 0
  ).length;

  const savedWorkoutsCount = filteredWorkouts?.filter((workout) =>
    savedWorkoutNames?.includes(workout?.name)
  ).length;

  const notCompletedWorkoutsCount = filteredWorkouts.length - completedWorkoutsCount;

  function sortByPublishDate(items: string[], ascending: boolean = true): string[] {
    const compareFunction = (a, b) => {
      if (ascending) {
        return new Date(a.publishDate) - new Date(b.publishDate);
      }
      return new Date(b.publishDate) - new Date(a.publishDate);
    };

    return items?.slice().sort(compareFunction);
  }

  function clearAllURLParams() {
    const url = new URL(window.location.href);

    // Clear all existing parameters
    urlParams.forEach((_, key) => {
      urlParams.delete(key);
    });

    // Replace the current URL without triggering a page reload
    window.history.replaceState({}, '', `${url.pathname}`);
  }

  const clearFilters = () => {
    clearAllURLParams();
    setShowCompleted(false);
    setShowNotCompleted(false);
    setShowSaved(false);
    setSelectedDurations([]);
    setSelectedTypes([]);
    setSelectedInstructors([]);
    setSelectedEquipment([]);
    setSelectedLanguage([]);
  };

  const isFilterActive =
    showCompleted ||
    showNotCompleted ||
    showSaved ||
    selectedDurations.length > 0 ||
    selectedTypes.length > 0 ||
    selectedInstructors.length > 0 ||
    selectedEquipment.length > 0 ||
    (selectedLanguage.length > 0 &&
      !(selectedLanguage.length === 1 && selectedLanguage[0] === 'English'));
  const sortedSlices =
    !selectedSorting || selectedSorting === SORTING.DEF
      ? filteredSlices
      : sortByPublishDate(filteredSlices, selectedSorting === SORTING.OLD);

  const filterOutDraftWorkouts = sortedSlices?.filter((workout) => workout?.name);

  const workoutNames = filterOutDraftWorkouts?.map((workout) => workout?.name);

  useEffect(() => {
    if (workoutNames?.length > 0) {
      contentListViewTracking(workoutNames, 'videos', programName, `Program Page - ${programName}`);
    }
  }, [workoutNames, programName]);

  return (
    <VideoGridTabWrapper displaySpotlightRibbon={displaySpotlightRibbon}>
      {displaySpotlightRibbon && <WorkoutTitle>Workouts</WorkoutTitle>}
      <TitleWrapper>
        {slices.map(({ id }, inx) => (
          <Title
            key={id || inx}
            onClick={() => setActiveTabInx(inx)}
            isSelectable={slices.length > 1}
            isActive={inx === activeTabInx}
          >
            <AlignWrapper>
              {isFilterActive && (
                <ClearWrapper>
                  <Chip
                    theme="dark"
                    label={
                      <ClearIcon>
                        <CloseIcon />
                      </ClearIcon>
                    }
                    onClick={clearFilters}
                  />
                </ClearWrapper>
              )}
              <Wrapper isFilterActive={isFilterActive}>
                <LeftFilterWrapper>
                  {tier === TIERS.PREMIUM && (
                    <>
                      <ChipWrapper>
                        <Chip
                          theme="dark"
                          active={showSaved}
                          label={`Saved ${savedWorkoutsCount}`}
                          onClick={() => setShowSaved(!showSaved)}
                        />
                      </ChipWrapper>

                      <ChipWrapper>
                        <Chip
                          theme="dark"
                          active={showCompleted}
                          label={`Done ${completedWorkoutsCount}`}
                          onClick={() => setShowCompleted(!showCompleted)}
                        />
                      </ChipWrapper>
                      <ChipWrapper>
                        <Chip
                          theme="dark"
                          active={showNotCompleted}
                          label={`Not done ${notCompletedWorkoutsCount}`}
                          onClick={() => setShowNotCompleted(!showNotCompleted)}
                        />
                      </ChipWrapper>
                    </>
                  )}
                  <MultiSelectDropdown
                    label="Duration"
                    options={durations}
                    selectedValues={selectedDurations}
                    open={durationsDropdownOpen}
                    handleOpenMultiSelectDropdown={(isOpen) => setDurationsDropdownOpen(isOpen)}
                    onChange={(items) => setSelectedDurations(items)}
                  />
                  <MultiSelectDropdown
                    label="Type"
                    options={types}
                    selectedValues={selectedTypes}
                    open={typesDropdownOpen}
                    handleOpenMultiSelectDropdown={(isOpen) => setTypesDropdownOpen(isOpen)}
                    onChange={(items) => setSelectedTypes(items)}
                  />
                  <MultiSelectDropdown
                    label="Instructor"
                    options={instructors}
                    selectedValues={selectedInstructors}
                    wide
                    open={InstructorDropdownOpen}
                    handleOpenMultiSelectDropdown={(isOpen) => setInstructorDropdownOpen(isOpen)}
                    onChange={(items) => setSelectedInstructors(items)}
                  />
                  {equipment.length !== 0 && (
                    <MultiSelectDropdown
                      label="Equipment"
                      options={equipment.map((item: string) => (
                        <>
                          <EquipmentIcon
                            equipmentIconName={item === 'Bench' ? 'Bench/Step' : item}
                          />
                          {item}
                        </>
                      ))}
                      wide
                      selectedValues={selectedEquipment}
                      open={equipmentDropdownOpen}
                      handleOpenMultiSelectDropdown={(isOpen) => setEquipmentDropdownOpen(isOpen)}
                      onChange={(items) => setSelectedEquipment(items)}
                    />
                  )}
                  <MultiSelectDropdown
                    label="Language"
                    options={languages}
                    selectedValues={selectedLanguage}
                    open={languageDropdownOpen}
                    handleOpenMultiSelectDropdown={(isOpen) => setLanguageDropdownOpen(isOpen)}
                    onChange={(items) => setSelectedLanguage(items)}
                  />
                </LeftFilterWrapper>

                <SingleSelectDropdown
                  label="Sort"
                  options={sorting}
                  selectedValue={selectedSorting}
                  onChange={setSelectedSorting}
                />
              </Wrapper>
            </AlignWrapper>
            {tier === TIERS.PREMIUM && (
              <FixedBannerWrapper>
                <BannerText>Missing something?</BannerText>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getVimeoProgramPageUrl(programName, vimeoTicket)}
                >
                  View the original page
                </Link>
              </FixedBannerWrapper>
            )}
          </Title>
        ))}
      </TitleWrapper>
      <VideoCardGrid
        content={filterOutDraftWorkouts}
        isFilterActive={isFilterActive}
        displayResultsAmount={
          filterOutDraftWorkouts?.length > 0 ||
          (filterOutDraftWorkouts?.length === 0 && isFilterActive)
        }
      />
    </VideoGridTabWrapper>
  );
};

export default VideoCardGridTab;
