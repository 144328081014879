/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { startOfDay, isToday } from 'date-fns';
import { Link } from 'gatsby';
import { StatsCard, VideoCard } from '@lesmills-international/components';
import { useWindowSize } from '@hooks';
import { useWorkoutByNameQuery } from '@backend';
import {
  getCollectionPlaylistWorkoutSlideAmount,
  getSlideSpace,
  getWorkoutForVideoCard,
} from '@services';
import { UserContext, userContext } from '@context';
import { Carousel, SlicePlaceholder } from '@components/atoms';
import { ReactComponent as RecoveryIcon } from '@assets/icons/recovery.svg';
import GET_MY_SCHEDULED_WORKOUTS from '../../../engagementGql/myWorkoutPlanner';
import { createEngagementClient } from '../../../../../../src/services/client';
import { addErrorInDatadogRum, getFiveMonthsLaterDate } from '../../../utils/utilities';
import {
  MyScheduledWorkoutsWrapper,
  TitleWrapper,
  Title,
  Description,
  ContentContainer,
  EmptyScheduledWorkoutsWrapper,
  CarouselWrapper,
} from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyMyScheduledWorkouts;
}

interface ScheduledWorkout {
  status: string;
  workout: { id: string };
  plan: { name: string };
}

interface WorkOutComponentProps {
  videoId: string;
  userInfo: UserContext;
  isDone: boolean;
  planName: string;
}

const WorkOutComponent: FC<WorkOutComponentProps> = ({ videoId, userInfo, isDone, planName }) => {
  const { data: workoutData, loading } = useWorkoutByNameQuery({
    variables: {
      product: 'LMOD',
      workoutName: videoId,
    },
  });

  if (loading) return null;

  if (workoutData?.workoutByName?.items?.length === 0) return null;

  const item = workoutData?.workoutByName?.items?.[0];

  return (
    <VideoCard
      {...getWorkoutForVideoCard({
        item,
        userInfo,
        hideEquipment: true,
      })}
      isDone={isDone}
      label={planName}
    />
  );
};

const MyScheduledWorkouts: FC<Props> = ({ content }) => {
  const {
    title,
    download_app_description,
    choose_a_plan_cta_label,
    plans_page,
    empty_plan_title,
    recovery_day_title,
  } = content?.primary || {};
  const [loading, setLoading] = useState(false);
  const [myScheduledWorkouts, setMyScheduledWorkouts] = useState([]);
  const [todayScheduledWorkouts, setTodayScheduledWorkouts] = useState([]);
  const [videoCardItems, setVideoCardItems] = useState<JSX.Element[]>([]);
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState<number>(4);
  const [carouselSlideSpaceBetween, setCarouselSlideSpaceBetween] = useState<number>(8);

  const windowInnerWidth = useWindowSize();
  const userInfo = useContext(userContext);
  const carouselRef = useRef<HTMLDivElement>(null);
  const WORKOUT_COMPLETED_STATUS = 'COMPLETED';

  useEffect(() => {
    const handleGetMyScheduledWorkouts = async () => {
      try {
        setLoading(true);
        const now = new Date();
        const todayStart = startOfDay(now);
        const todayTimestamp = todayStart.toISOString();
        const data = await createEngagementClient.query({
          query: GET_MY_SCHEDULED_WORKOUTS,
          variables: {
            from: todayTimestamp,
            to: getFiveMonthsLaterDate(),
          },
        });
        if (data?.data?.myWorkoutPlanner) {
          setLoading(false);
          setMyScheduledWorkouts(data?.data?.myWorkoutPlanner?.plannedWorkouts);
        }
      } catch (error) {
        setLoading(false);
        addErrorInDatadogRum(error);
      }
    };
    handleGetMyScheduledWorkouts();
  }, []);

  useEffect(() => {
    if (myScheduledWorkouts.length > 0) {
      const todayWorkouts = myScheduledWorkouts.filter(
        (workout: { scheduledDate: string | number | Date }) =>
          isToday(new Date(workout?.scheduledDate))
      );
      setTodayScheduledWorkouts(todayWorkouts);
    }
  }, [myScheduledWorkouts]);

  useEffect(() => {
    if (carouselRef && carouselRef.current && todayScheduledWorkouts.length) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getCollectionPlaylistWorkoutSlideAmount(carouselWrapperWidth);

      const sortedWorkouts = [...todayScheduledWorkouts].sort((a, b) => {
        if (a.status === WORKOUT_COMPLETED_STATUS && b.status !== WORKOUT_COMPLETED_STATUS) {
          return 1;
        }
        if (a.status !== WORKOUT_COMPLETED_STATUS && b.status === WORKOUT_COMPLETED_STATUS) {
          return -1;
        }
        return 0;
      });

      const workoutVideoItems = sortedWorkouts
        ? sortedWorkouts?.map((item: ScheduledWorkout, index: number) => (
            <WorkOutComponent
              key={index}
              videoId={item?.workout?.id?.replace(/^.*\|/, '') || ''}
              userInfo={userInfo}
              isDone={item?.status === WORKOUT_COMPLETED_STATUS}
              planName={item?.plan?.name || 'MY SCHEDULE'}
            />
          ))
        : [];
      if (workoutVideoItems?.length < slidesPerView) {
        for (let i = 0; i < slidesPerView - workoutVideoItems.length; i += 1) {
          workoutVideoItems.push(<div key={`placeholder-${i}`} />);
        }
      }

      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlideSpaceBetween(getSlideSpace(carouselWrapperWidth));
      setVideoCardItems(workoutVideoItems.filter(Boolean));
    }
  }, [todayScheduledWorkouts, windowInnerWidth, userInfo]);

  if (loading) {
    return (
      <MyScheduledWorkoutsWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <SlicePlaceholder height="160px" />
      </MyScheduledWorkoutsWrapper>
    );
  }

  return (
    <MyScheduledWorkoutsWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        {myScheduledWorkouts?.length === 0 && <Description>{download_app_description}</Description>}
      </TitleWrapper>
      <ContentContainer>
        {myScheduledWorkouts?.length === 0 && (
          <EmptyScheduledWorkoutsWrapper>
            <Link to={plans_page?.url} className="choose-plan-button">
              {choose_a_plan_cta_label}
            </Link>
          </EmptyScheduledWorkoutsWrapper>
        )}
        {myScheduledWorkouts?.length !== 0 && todayScheduledWorkouts.length === 0 && (
          <StatsCard
            label={empty_plan_title}
            title={recovery_day_title}
            thumbnailIcon={<RecoveryIcon />}
            hasBackground={windowInnerWidth >= 600}
          />
        )}
        {todayScheduledWorkouts.length > 0 && (
          <CarouselWrapper ref={carouselRef}>
            <Carousel
              items={videoCardItems}
              slidesPerView={carouselSlidesPerView}
              slidesPerGroup={carouselSlidesPerView}
              spaceBetween={carouselSlideSpaceBetween}
            />
          </CarouselWrapper>
        )}
      </ContentContainer>
    </MyScheduledWorkoutsWrapper>
  );
};

export default MyScheduledWorkouts;
