/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useContext, useState, useEffect } from 'react';
import { PageProps, navigate, graphql } from 'gatsby';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { TIERS } from '@constants';
import { userContext } from '@context';
import { formatFitnessLevels } from '@services';
import {
  withPrivateRoute,
  Layout,
  HomeHeroLoading,
  EndPlanDialog,
  QrDialog,
} from '@components/molecules';
import { DatePicker } from '@components/atoms';
import { BackButton } from '@lesmills-international/components';
import styled from 'styled-components';
import { COLORS, breakpoint } from '@themes';
import SliceZone from '@components/sliceZone';
import useUpdateSubcomms from '../../../../gatsby-theme-acquisition/src/hooks/mutation/useUpdateSubcomms';
import { addErrorInDatadogRum } from '../../utils/utilities';
import type { Plan, State } from './types';
import { useFetchActivePlans, useFetchPlanDetails, useStartPlan, useEndPlan } from './hooks';

interface PageContextProps {
  data: {
    body: any[];
    plan_name: string;
  };
}

interface PrismicPlanData {
  end_plan_dialog_heading: string;
  end_plan_dialog_body: string;
  end_plan_dialog_cancel: string;
  end_plan_dialog_accept: string;
  qr_dialog_heading: string;
  qr_dialog_body: string;
  qr_dialog_comms: string;
  qr_dialog_accept: string;
}

interface PageContextProps {
  data: {
    body: any[];
    plan_name: string;
  };
}

interface PrismicDataProps {
  prismicPlans: {
    data: PrismicPlanData;
  };
}

const Wrapper = styled.div`
  background-color: ${COLORS.black};
`;

const BackBtnWrapper = styled.div`
  position: absolute;
  top: 36px;
  z-index: 3;

  ${breakpoint('xs')`
    left: 16px;
  `}

  ${breakpoint('sm')`
    left: 32px;
  `}

  ${breakpoint('lg')`
    left: 80px;
  `}
`;

const PlanDetailsPageTemplate: FC<PageProps<PrismicDataProps, PageContextProps>> = ({
  location,
  pageContext,
  data: prismicData,
}) => {
  const { state } = location as { state: State };
  const { data } = pageContext;

  const [planDetails, setPlanDetails] = useState<Plan | null>(state?.plan || null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isAdded, setIsAdded] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isEndPlanDialogOpen, setIsEndPlanDialogOpen] = useState(false);
  const [isQrDialogOpen, setIsQrDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startPlanErrorMessage, setStartPlanErrorMessage] = useState<string | null>(null);
  const [endPlanErrorMessage, setEndPlanErrorMessage] = useState<string | null>(null);

  const { rtPcb } = useFlags();
  const { tier, subComms } = useContext(userContext);

  const [handleUpdateSubcomms] = useUpdateSubcomms({
    onError: (err) => addErrorInDatadogRum(err),
  });

  if (!rtPcb || tier === TIERS.BASE) {
    navigate('/explore');
    return null;
  }

  const {
    activePlans: fetchedActivePlans,
    refetch,
    loading: activePlansLoading,
  } = useFetchActivePlans();
  const { planDetails: fetchedPlanDetails, loading: planDetailsLoading } = useFetchPlanDetails(
    data.plan_name,
    planDetails
  );

  const { startPlan } = useStartPlan();
  const { endPlan } = useEndPlan();

  useEffect(() => {
    if (fetchedActivePlans.length > 0) {
      setIsLoading(true);
      const isPlanAdded = fetchedActivePlans.some((plan) => plan.planId === fetchedPlanDetails?.id);
      setIsAdded(isPlanAdded);
      setIsLoading(false);
    }

    if (!planDetails && fetchedPlanDetails) {
      const resFetchedPlanDetails = fetchedPlanDetails;
      if (!resFetchedPlanDetails.isDisplay) {
        navigate('/plans');
      }
      setPlanDetails(fetchedPlanDetails);
    }
  }, [fetchedPlanDetails, fetchedActivePlans, planDetails]);

  if (planDetailsLoading) {
    return <HomeHeroLoading />;
  }

  const handleCtaClick = () => {
    if (isAdded) {
      setIsEndPlanDialogOpen(true);
    } else {
      setIsDatePickerOpen(true);
    }
  };

  const handleDateAccept = async () => {
    setIsLoading(true);
    setStartPlanErrorMessage(null);

    if (planDetails) {
      try {
        const res = await startPlan(planDetails.name, selectedDate);
        if (!res) {
          throw new Error();
        }

        setIsAdded(true);
        setIsDatePickerOpen(false);
        setIsQrDialogOpen(true);
        await refetch();
      } catch (error) {
        setStartPlanErrorMessage('Failed to start the plan. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEndPlan = async () => {
    setIsLoading(true);
    setEndPlanErrorMessage(null);
    if (planDetails) {
      const activePlan = fetchedActivePlans.find((plan) => plan.planId === planDetails.id);

      if (activePlan) {
        try {
          const res = await endPlan(activePlan.id);
          if (!res) {
            throw new Error();
          }
          await refetch();
          setIsEndPlanDialogOpen(false);
          setIsAdded(false);
        } catch (error) {
          setEndPlanErrorMessage('Failed to end the plan. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleOptIn = () => {
    handleUpdateSubcomms({
      variables: {
        input: {
          subComms: true,
        },
      },
    });
  };
  const slicesData = [
    {
      slice_type: 'plan_details_hero',
      primary: {
        tag_line: planDetails?.displayTagline,
        main_text: planDetails?.displayMainTitle,
        desc_text: planDetails?.description,
        background_img: planDetails?.imageNoText,
        length: planDetails ? Math.round(planDetails.length / 7) : 0,
        fitness_level: formatFitnessLevels(planDetails?.fitnessLevel),
        days_per_week: planDetails?.daysPerWeek,
        min_per_day: planDetails?.minutesPerDay,
        button_primary_text: 'ADD TO MY SCHEDULE',
        button_secondary_text: 'REMOVE FROM MY SCHEDULE',
        equipment_icons: planDetails?.equipment || [],
        is_added: isAdded,
        button_loading: activePlansLoading,
        on_add: handleCtaClick,
      },
    },
  ];

  const combinedSlices = [...slicesData, ...data.body];

  return (
    <Layout metaData={{ title: 'Plan Details' }}>
      <Wrapper>
        <BackBtnWrapper>
          <BackButton
            onClick={() => {
              navigate('/plans', { state: { refetchPlans: true } });
            }}
            theme="dark"
          />
        </BackBtnWrapper>
        <SliceZone slices={combinedSlices} />
        <DatePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          open={isDatePickerOpen}
          setOpen={setIsDatePickerOpen}
          onAccept={handleDateAccept}
          disabled={isLoading}
          loading={isLoading}
          errorMessage={startPlanErrorMessage}
          setStartPlanErrorMessage={setStartPlanErrorMessage}
        />
        <EndPlanDialog
          open={isEndPlanDialogOpen}
          onCancel={() => setIsEndPlanDialogOpen(false)}
          onClose={() => setIsEndPlanDialogOpen(false)}
          onEndPlan={handleEndPlan}
          loading={isLoading}
          headingText={prismicData.prismicPlans.data.end_plan_dialog_heading}
          bodyText={prismicData.prismicPlans.data.end_plan_dialog_body}
          cancelText={prismicData.prismicPlans.data.end_plan_dialog_cancel}
          acceptText={prismicData.prismicPlans.data.end_plan_dialog_accept}
          errorMessage={endPlanErrorMessage}
          setErrorMessage={setEndPlanErrorMessage}
        />

        <QrDialog
          open={isQrDialogOpen}
          onClose={() => setIsQrDialogOpen(false)}
          planName={planDetails?.name}
          subComms={subComms}
          handleOptIn={handleOptIn}
          headingText={prismicData.prismicPlans.data.qr_dialog_heading}
          subHeadingText={prismicData.prismicPlans.data.qr_dialog_body}
          checkboxText={prismicData.prismicPlans.data.qr_dialog_comms}
          doneButtonText={prismicData.prismicPlans.data.qr_dialog_accept}
        />
      </Wrapper>
    </Layout>
  );
};

export default withPrivateRoute(PlanDetailsPageTemplate);

export const query = graphql`
  query GetAllPlansData {
    prismicPlans {
      data {
        plans_title
        plans_description
        active_plans
        other_plans
        end_plan_dialog_heading
        end_plan_dialog_body
        end_plan_dialog_cancel
        end_plan_dialog_accept
        qr_dialog_heading
        qr_dialog_body
        qr_dialog_comms
        qr_dialog_accept
      }
    }
  }
`;
