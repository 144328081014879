import styled from 'styled-components';
import { breakpoint } from '@themes';

export const MyScheduledWorkoutsWrapper = styled.div`
  width: auto;
  padding-top: 24px;
  padding-bottom: 24px;

  ${breakpoint('lg')`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const Title = styled.h4`
  color: ${(props: { theme: { colors: { lightishGrey: string } } }) =>
    props.theme.colors.lightishGrey};
  font-size: 18px;
  line-height: 25px;
  font-family: 'Avenir heavy';

  ${breakpoint('lg')`
    font-size: 24px;
    line-height: 33px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const EmptyScheduledWorkoutsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  & .choose-plan-button {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
    padding: 12px 24px;
    border-radius: 100px;
    font-family: 'Avenir Medium';
    background-color: ${(props: { theme: { colors: { lightGrey06: string } } }) =>
      props.theme.colors.lightGrey06};
    color: ${(props: { theme: { colors: { white: string } } }) => props.theme.colors.white};

    &:hover {
      background-color: ${(props: { theme: { colors: { lightGrey04: string } } }) =>
        props.theme.colors.lightGrey04};
    }
  }
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: ${(props: { theme: { colors: { whiteOpacity07: string } } }) =>
    props.theme.colors.whiteOpacity07};
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;
  & .swiper--wrapper {
    height: 100%;
  }

  & .swiper-slide {
    height: auto;
  }

  & .videoCard {
    height: 100%;
  }
`;
