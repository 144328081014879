import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

const GRID_BREAKPOINTS = [
  {
    width: 319,
    gridSize: 12,
    padding: '16px',
  },
  {
    width: 599,
    gridSize: 6,
    padding: '32px',
  },
  {
    width: 799,
    gridSize: 4,
    padding: '32px',
  },
  {
    width: 1023,
    gridSize: 3,
    padding: '80px',
  },
  {
    width: 1439,
    gridSize: 2.4,
    padding: '80px',
  },
];

const PLANS_GRID_BREAKPOINTS = [
  {
    width: 319,
    gridSize: 12,
    padding: '16px',
  },
  {
    width: 599,
    gridSize: 6,
    padding: '32px',
  },
  {
    width: 799,
    gridSize: 4,
    padding: '32px',
  },
  {
    width: 1023,
    gridSize: 4,
    padding: '80px',
  },
  {
    width: 1439,
    gridSize: 3,
    padding: '80px',
  },
];

const useGridSize = (forPlans?: boolean) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeInterval = 100;
    const handleResize = debounce(() => setCurrentWidth(window.innerWidth), resizeInterval);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const BREAKPOINTS = forPlans ? PLANS_GRID_BREAKPOINTS : GRID_BREAKPOINTS;

  const getGridInfo = useCallback(() => {
    let breakPointIndex = 0;
    BREAKPOINTS.forEach((breakPoint, index) => {
      if (breakPoint.width < currentWidth) breakPointIndex = index;
    });
    return BREAKPOINTS[breakPointIndex];
  }, [currentWidth, BREAKPOINTS]);

  const { gridSize } = getGridInfo();

  return { gridSize };
};

export default useGridSize;
