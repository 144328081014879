/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import { COLORS } from '@themes';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DialogActions, Button } from '@lesmills-international/components';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: COLORS.neoBlack,
    },
    primary: {
      main: COLORS.accentGreen,
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          backgroundColor: COLORS.accentGreen,
          color: COLORS.darkBlack,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.green,
            color: COLORS.darkBlack,
          },
        },
      },
    },
  },
});

const Wrapper = styled.div`
  padding: 10px;
  position: relative;
  background-color: ${COLORS.neoBlack};
  & .MuiPickersLayout-root {
    display: block;
    background-color: ${COLORS.neoBlack};
  }
`;

const ErrorMessageContainer = styled.div`
  color: ${COLORS.errorText};
  padding: 0 25px;
  position: absolute;
  font-size: 14px;
  left: 8%;
  right: 0;
  top: 90%;
  max-width: calc(100% - 30px);
  word-wrap: break-word;
`;

interface DarkThemeDatePickerProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onAccept: () => void;
  disabled?: boolean;
  loading: boolean;
  errorMessage?: string | null;
  setStartPlanErrorMessage: (errorMessage: string | null) => void;
}

const DarkThemeDatePicker: React.FC<DarkThemeDatePickerProps> = ({
  selectedDate,
  setSelectedDate,
  open,
  setOpen,
  onAccept,
  disabled = false,
  loading,
  errorMessage,
  setStartPlanErrorMessage,
}) => {
  const handleClose = () => {
    if (!loading) {
      setOpen(false);
      setStartPlanErrorMessage(null);
    }
  };

  const handleOk = () => {
    if (!loading) {
      onAccept();
    }
  };

  const handleChange = (newValue: Date | null) => {
    if (!disabled && !loading) {
      setSelectedDate(newValue);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dialog open={open} onClose={handleClose}>
          <Wrapper>
            <StaticDatePicker
              displayStaticWrapperAs="mobile"
              openTo="day"
              disabled={disabled || loading}
              value={selectedDate}
              defaultValue={new Date()}
              onChange={(newValue) => handleChange(newValue)}
              slots={{ actionBar: () => null }}
              orientation="portrait"
              disablePast
            />
            {errorMessage && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}
          </Wrapper>
          <div style={{ backgroundColor: COLORS.neoBlack }}>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{ color: COLORS.accentGreen, backgroundColor: COLORS.neoBlack }}
                disabled={disabled}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOk}
                style={{
                  color: disabled || !!errorMessage ? COLORS.white : COLORS.accentGreen,
                  backgroundColor: COLORS.neoBlack,
                }}
                disabled={disabled || !!errorMessage}
              >
                OK
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DarkThemeDatePicker;
