import React, { FC } from 'react';
import { HeroPlanDetail } from '@lesmills-international/components';

interface Props {
  content: any;
}

const PlanDetailHero: FC<Props> = ({ content }) => {
  const {
    tag_line: tagLine,
    main_text: mainText,
    desc_text: descText,
    background_img: backgroundImg,
    length,
    fitness_level: fitnessLevel,
    days_per_week: daysPerWeek,
    min_per_day: minPerDay,
    button_primary_text: buttonPrimaryText,
    button_secondary_text: buttonSecondaryText,
    equipment_icons: equipmentIcons,
    is_added: isAdded,
    on_add: onAdd,
    button_loading: buttonLoading,
  } = content.primary;

  return (
    <HeroPlanDetail
      tagLine={tagLine}
      mainText={mainText}
      descText={descText}
      backgroundImg={backgroundImg}
      length={length}
      fitnessLevel={fitnessLevel}
      daysPerWeek={daysPerWeek}
      minPerDay={minPerDay}
      buttonPrimaryText={buttonPrimaryText}
      buttonSecondaryText={buttonSecondaryText}
      equipmentIcons={equipmentIcons}
      isAdded={isAdded}
      onAdd={onAdd}
      buttonLoading={buttonLoading}
    />
  );
};

export default PlanDetailHero;
