import { gql } from '@apollo/client';

// Query mover's scheduled workouts
const GET_MY_SCHEDULED_WORKOUTS = gql`
  query GetMyScheduledWorkouts($from: AWSDateTime!, $to: AWSDateTime!) {
    myWorkoutPlanner(from: $from, to: $to) {
      plannedWorkouts {
        id
        scheduledDate
        plan {
          id
          name
          description
        }
        workout {
          id
        }
        status
      }
    }
  }
`;

export default GET_MY_SCHEDULED_WORKOUTS;
