import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import AccountOverview from '../components/accountOverview/AccountOverview';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';

const AccountOverviewTemplate = ({
  data,
  location,
}: PageProps<Queries.GetAccountOverviewQuery>) => (
  <AccountOverview pageData={data} location={location} />
);

export default withPrismicPreview(withPrivateRoute(AccountOverviewTemplate, true));

export const query = graphql`
  query GetAccountOverview($lang: String!) {
    prismicAccountOverviewPage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        subscription_on_hold {
          richText
          text
        }
        subscription_on_hold_reseller {
          richText
          text
        }
        subscription_failed {
          richText
          text
        }
        no_active_subscription_winback {
          richText
          text
        }
        no_active_subscription_trialist {
          richText
          text
        }
        subscription_failed {
          richText
          text
        }
        restart_subscription_success
        restart_subscription_error
        subscription
        current_billing_detail
        includes_tax
        payment_details
        what_happens_next
        next_billing_detail
        confirm_button_text
        month
        year
        three_month
        restart_subscription_pending_cancellation
        restart_subscription_cancellation
        account_details_description
        update_payment_details_title
        update_payment_details_description
        title
        subscription_status_title
        monthly_subscription
        trimonthly_subscription
        annual_subscription
        trialing
        subscription_status_reseller_recurring_active
        subscription_status_pending_cancellation
        subscription_status_ended
        subscription_status_on_hold
        subscription_status_active
        subscription_section_title
        restart_subscription
        cancel_subscription
        keep_subscription
        redeem_offer_button_text
        loading_process_text
        personalize_your_workouts_title
        personalize_your_workouts_description
        payment_section_title
        email_preferences_title
        email_preferences_section_title
        email_preferences_description
        email_preferences_error_message
        email_sent_message
        double_opt_in_message {
          richText
          text
        }
        billing_history
        account_section_title
        account_details_title
        iap_subscriptions {
          signup_channel
          iap_subscription_title
          iap_subscription_description {
            richText
            text
          }
        }
        cancellation_success_popup_title
        cancellation_success_popup_description
        restart_subscription_description
        close_button_label
        cancel_subscription_generic_error {
          richText
          text
        }
      }
    }
    prismicSignupChannels {
      data {
        signup_channel {
          name
        }
      }
    }
    prismicMemberPromoPopup(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        first_offer {
          full_member_promo_journey_country_list
          banner_image {
            alt
            gatsbyImageData
            thumbnails {
              mobile {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
          }
          subscription_type
          promo_code
          label
          title
          description
          terms_and_conditions
          reject_button_text
          redeem_button_text
          show_second_offer
        }
        second_offer {
          banner_image {
            alt
            gatsbyImageData
            thumbnails {
              mobile {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
          }
          subscription_type
          promo_code
          label
          title
          description
          terms_and_conditions
          reject_button_text
          redeem_button_text
        }
      }
    }
  }
`;
