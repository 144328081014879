import { gql } from '@apollo/client';

export const LIST_PLANS = gql`
  query listPlans($nextToken: String) {
    listPlans(nextToken: $nextToken) {
      items {
        daysPerWeek
        displayMainTitle
        displayTagline
        equipment
        fitnessLevel
        image
        imageNoText
        minutesPerDay
        length
        name
        id
        description
        position
        isDisplay
      }
    }
  }
`;

export const GET_ACTIVE_PLANS = gql`
  query getPlanRegistrations($nextToken: String) {
    getPlanRegistrations(nextToken: $nextToken) {
      items {
        planId
        status
        id
      }
    }
  }
`;

export const START_PLAN = gql`
  mutation StartPlan($planName: String!, $startDate: AWSDateTime) {
    startPlan(planName: $planName, startDate: $startDate) {
      success
    }
  }
`;

export const GET_PLAN_BY_NAME = gql`
  query GetPlanByName($name: String!) {
    getPlanByName(name: $name) {
      items {
        id
        name
        description
        displayMainTitle
        displayTagline
        fitnessLevel
        image
        imageNoText
        length
        daysPerWeek
        minutesPerDay
        equipment
        isDisplay
      }
    }
  }
`;

export const END_PLAN = gql`
  mutation endPlan($planRegistrationId: ID!) {
    endPlan(planRegistrationId: $planRegistrationId)
  }
`;

export const END_PLAN_LEGACY = gql`
  mutation EndPlan {
    endPlan
  }
`;

export const MIGRATE_PLAN = gql`
  mutation MigratePlan {
    migratePlan
  }
`;
