import styled from 'styled-components';

export const PlanCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;

  .videoCard {
    height: 100%;
  }
`;

export const SlicePlaceholderWrapper = styled.div`
  padding-top: 12px;
  width: 100%;
`;

export const PlanCardsGridWrapper = styled.div`
  width: 100%;
`;

export const Subtitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.lightishGrey};
  margin: 50px 0 30px;
`;
